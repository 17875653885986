import React, { useState } from "react";
import { IconDiv } from "../styledComponents";
import CloseIcon from "../../../assets/icons/CloseIcon";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import { message, TimePicker } from "antd";

const BreakTimePickers = ({
  day,
  handleTimeChange,
  toggleBreak,
  isTimeBetween,
  availability,
}) => {
  const dayData = availability[day];
  const { startTime, endTime, breakStartTime, breakEndTime } = dayData;
  const [tempBreakStart, setTempBreakStart] = useState(null);
  const [tempBreakEnd, setTempBreakEnd] = useState(null);
  const [isBreakStartOpen, setIsBreakStartOpen] = useState(false);
  const [isBreakEndOpen, setIsBreakEndOpen] = useState(false);

  const handleConfirm = (type, time) => {
    if (!isTimeBetween(time, startTime, endTime)) {
      message.error(
        `${
          type === "breakStartTime" ? "Break start time" : "Break end time"
        } must be between consultation start and end times.`,
        3
      );
      return;
    }
    handleTimeChange(day, type, time);
    if (type === "breakStartTime") {
      setIsBreakStartOpen(false);
    } else {
      setIsBreakEndOpen(false);
    }
  };

  const handleBreakStartCancel = () => {
    setTempBreakStart(null);
    setIsBreakStartOpen(false);
  };

  const handleBreakEndCancel = () => {
    setTempBreakEnd(null);
    setIsBreakEndOpen(false);
  };

  return (
    <>
      <TimePicker
        format="hh:mm A"
        minuteStep={5}
        use12Hours
        inputReadOnly
        value={breakStartTime}
        placeholder="Start Time"
        onSelect={(time) => setTempBreakStart(time)}
        onChange={null}
        showNow={false}
        open={isBreakStartOpen}
        onOpenChange={setIsBreakStartOpen}
        popupClassName="custom-time-picker-dropdown"
        panelRender={(originPanel) => (
          <div style={{ width: "100%" }}>
            {originPanel}
            <SaveCancelButtons
              className={"SaveCancelButtons"}
              saveButtonTitle={"Confirm"}
              onCancelClick={handleBreakStartCancel}
              onSaveClick={() => {
                if (tempBreakStart)
                  handleConfirm("breakStartTime", tempBreakStart);
              }}
              isDisabled={!breakStartTime && !tempBreakStart}
            />
          </div>
        )}
      />
      <span style={{ color: "#CCCCCC" }}>-</span>
      <div className="Break-EndTime-Close-Wrapper">
        <TimePicker
          format="hh:mm A"
          minuteStep={5}
          use12Hours
          inputReadOnly
          value={breakEndTime}
          placeholder="End Time"
          onSelect={(time) => setTempBreakEnd(time)}
          onChange={null}
          showNow={false}
          disabled={!breakStartTime}
          open={isBreakEndOpen}
          onOpenChange={setIsBreakEndOpen}
          popupClassName="custom-time-picker-dropdown"
          panelRender={(originPanel) => (
            <div style={{ width: "100%" }}>
              {originPanel}
              <SaveCancelButtons
                className={"SaveCancelButtons"}
                saveButtonTitle={"Confirm"}
                onCancelClick={handleBreakEndCancel}
                onSaveClick={() => {
                  if (tempBreakEnd) handleConfirm("breakEndTime", tempBreakEnd);
                }}
                isDisabled={!breakEndTime && !tempBreakEnd}
              />
            </div>
          )}
        />
        <IconDiv bgColor="#C24646" onClick={() => toggleBreak(day)}>
          <CloseIcon width={"12.5px"} height={"12.5px"} />
        </IconDiv>
      </div>
    </>
  );
};

export default BreakTimePickers;
