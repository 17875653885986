import React, { useRef, useState } from "react";
import { message } from "antd";
import DefaultAvatar from "../../assets/backgrounds/DefaultAvatar.svg";
import { CommonFlex, CommonIcon } from "../../utils/styledUtils";
import CustomArticleButton from "../CustomArticleButton";

const AvatarUpload = ({ image, onUpload }) => {
  const [avatar, setAvatar] = useState("");

  const fileInputRef = useRef(null);

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    const isSmallEnough = file.size / 1024 / 1024 < 2;

    if (!isImage) {
      message.error("You can only upload image files!");
      return false;
    }
    if (!isSmallEnough) {
      message.error("Image must be smaller than 2MB!");
      return false;
    }

    var img = document.createElement("img");
    img.src = URL.createObjectURL(file);
    img.onload = function () {
      var width = img.width;
      var height = img.height;
      if (width > 150 && height > 150) {
        setAvatar(file);
        if (typeof onUpload === "function") {
          onUpload(file);
        }
      } else {
        message.error("Minimum image size is 150px by 150px");
      }
    };

    return false;
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <CommonFlex overflow="unset" flexDirection="column">
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          const file = e.target.files[0];
          if (file) {
            beforeUpload(file);
          }
        }}
        accept="image/*"
      />
      <CommonIcon
        width={"116px"}
        height={"116px"}
        borderRadius={"100px"}
        src={!avatar ? image : URL.createObjectURL(avatar)}
        alt="No img found"
        onError={(e) => {
          if (e.target.src !== DefaultAvatar) {
            e.target.src = DefaultAvatar;
          }
        }}
      />

      <CustomArticleButton
        buttonText="Upload your Avatar"
        style={{ width: "254px", height: "40px", marginTop: "10px" }}
        onClick={handleButtonClick}
      />
    </CommonFlex>
  );
};

export default AvatarUpload;
