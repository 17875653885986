import { Button } from "antd";
import React from "react";
import styled from "styled-components";

export const ReadNowButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: ${(props) => props.fontSize || "14px"};
  font-weight: 800;
  color: ${(props) =>
    props.textColor
      ? props.textColor
      : props.closeBtn
      ? "rgba(66, 88, 137, 1)"
      : "rgba(255, 255, 255, 1)"};
  background: ${(props) =>
    props.bgColor
      ? props.bgColor
      : props.closeBtn
      ? "rgba(244, 247, 252, 1)"
      : "rgba(66, 88, 137, 1)"};
  border: 1px solid
    ${(props) =>
      props.borderColor
        ? props.borderColor
        : props.closeBtn
        ? "rgba(244, 247, 252, 1)"
        : "rgba(66, 88, 137, 1)"};
  cursor: pointer;
  letter-spacing: 0.5px;
  border-radius: 4px;
  font-family: "Schibsted Grotesk";
  padding: 9px 40px;
  &.ant-btn:active,
  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    color: ${(props) =>
      props.textColor
        ? props.textColor
        : props.closeBtn
        ? "rgba(66, 88, 137, 1)"
        : "rgba(255, 255, 255, 1)"};
    background: ${(props) =>
      props.bgColor
        ? props.bgColor
        : props.closeBtn
        ? "rgba(244, 247, 252, 1)"
        : "rgba(66, 88, 137, 1)"};
    border-color: ${(props) =>
      props.borderColor
        ? props.borderColor
        : props.closeBtn
        ? "rgba(244, 247, 252, 1)"
        : "rgba(66, 88, 137, 1)"};
  }
`;
const CustomArticleButton = ({
  type,
  htmlType,
  disabled,
  buttonText,
  onClick,
  className,
  closeBtn,
  textColor,
  bgColor,
  borderColor,
  style,
  fontSize,
}) => {
  return (
    <ReadNowButton
      htmlType={htmlType}
      onClick={onClick}
      className={className}
      closeBtn={closeBtn}
      type={type}
      disabled={disabled}
      textColor={textColor}
      bgColor={bgColor}
      borderColor={borderColor}
      style={style}
      fontSize={fontSize}
    >
      {buttonText}
    </ReadNowButton>
  );
};

export default CustomArticleButton;
