import React from "react";
import { DatePickerWrapper } from "./styledComponent";
import { CustomStyledFormItem } from "./styledComponent";
import { DatePicker } from "antd";

const CustomUserDatepicker = ({
  title,
  name,
  type2,
  rules,
  disabled,
  placeholder,
  suffixIcon,
  disabledDate,
  inputReadOnly,
  format,
  style,
  className,
}) => {
  return (
    <DatePickerWrapper className={className}>
      <label>{title}</label>
      <CustomStyledFormItem name={name} rules={rules} type2={type2}>
        <DatePicker
          style={style}
          format={format}
          placeholder={placeholder}
          inputReadOnly={inputReadOnly}
          disabled={disabled}
          disabledDate={disabledDate}
          suffixIcon={suffixIcon}
        />
      </CustomStyledFormItem>
    </DatePickerWrapper>
  );
};

export default CustomUserDatepicker;
