import styled from "styled-components";
import { Upload, Spin, Input } from "antd";
import AvatarBg from "../../assets/backgrounds/avatar.svg";
import { LoginSignupContainer } from "../../utils/styledUtils";
import TextArea from "antd/lib/input/TextArea";
import { ListWrapper } from "../UpdateProfileDoctor/styledComponents";

export const StyledLoginSignupContainer = styled(LoginSignupContainer)`
  .ant-input:hover,
  .ant-input:focus,
  .ant-input-focused {
    border-color: rgba(66, 88, 137, 1);
    box-shadow: none;
  }
  @media only screen and (max-width: 900px) {
    .TitleWrapper {
      .PageTitle {
        font-size: 22px;
      }
    }
    .Update-Content-Wrapper {
      .Title-Style,
      .Sub-Title-Style {
        display: none;
      }

      .UpdateCardContent {
        padding: 40px 20px;
        .Progress-Wrapper {
          padding: 0px;
        }
      }
    }
  }
  @media (min-width: 901px) and (max-width: 1200px) {
    .TitleWrapper {
      .PageTitle {
        font-size: 24px;
      }
    }
    .Update-Content-Wrapper {
      .Title-Style {
        font-size: 30px;
      }
    }
  }
`;
export const Main = styled.div`
  height: 100vh;
  overflow-y: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PhotoWrapper = styled.div`
  height: 130px;
  width: 100%;
  margin: 10px 0;
`;

export const PhotoContainer = styled.div`
  height: 100%;
  width: 130px;
  border-radius: 50%;
  margin: 0 auto;
`;

export const StyledUpload = styled(Upload)`
  height: 100%;
  width: 100%;

  & .ant-upload-select-picture-card {
    height: 100%;
    width: 100%;
    border: none;
    background: none;
  }

  & .ant-upload {
    padding: 0 !important;
    & img {
      height: 130px;
      width: 130px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

export const UploadDiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(${AvatarBg});
  background-size: contain;
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 3%;
  top: 73%;
  height: 30px;
  width: 30px;

  & svg {
    height: 100%;
    width: 100%;
  }
`;

export const StyledSpin = styled(Spin)`
  position: absolute;
  top: 45%;
  left: 45%;
`;

export const UploadDivWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
export const UpdateContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  height: calc(100% - 43px);
  overflow: auto;
  &.Update-Long-Content-Style {
    justify-content: start;
  }
  &.Updated-Wrapper-Style {
    gap: 12px;
    .Title-Style {
      line-height: 34px;
    }
    .Sub-Title-Style {
      margin-bottom: 56px;
    }
    .Dashboard-Redirect-Btn-Style {
      width: 100%;
      height: 48px;
      max-width: 370px;
    }
  }
`;
export const UpdateCardContent = styled.div`
  max-width: 470px;
  width: 100%;
  background-color: rgba(244, 247, 252, 1);
  padding: 40px;
  border-radius: 16px;
  margin-top: 16px;
  .Back-Button-Style {
    height: 32px;
    width: 32px;
    svg {
      height: 15px;
      width: 8px;
      margin-bottom: 1px;
    }
  }
`;
export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 36px 0;
  gap: 36px;
  .Password-Field-Wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .Page-Content-Wrapper {
      gap: 0px !important;
    }
  }
  .Page-Content-Wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    .ant-picker {
      input {
        &::placeholder {
          font: 500 18px Dm Sans;
        }
      }
    }
    .ant-picker:hover,
    .ant-picker-focused {
      border-right-width: 2px !important;
      box-shadow: none;
    }
    input {
      color: rgba(55, 63, 106, 1);
      font: 500 18px DM Sans;
      &:focus-visible {
        outline: none !important;
      }
    }
    .ant-picker-suffix {
      display: none;
    }
    .Password-Style {
      width: 100%;
    }
    .Change-Location-Button-Style {
      justify-content: flex-start;
      padding: 0px;
      text-decoration: underline;
      height: auto !important;
      margin-top: 19px;
    }
  }
`;
export const StyledInput = styled(Input)`
  width: 100%;
  height: 64px;
  border-radius: 8px;
  border: 2px solid rgba(66, 88, 137, 1);
  padding: 16px;
  background-color: #fbfbfb;
  text-align: ${(props) => props.textAlign || "center"};
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  border-radius: 8px;
  border: 2px solid rgba(66, 88, 137, 1);
  padding: 16px;
  background-color: #fbfbfb;
  text-align: ${(props) => props.textAlign || "center"};
`;

export const ConfirmedImageWrapper = styled.div`
  width: 156px;
  height: 156px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  img {
    width: 200px;
    height: auto;
  }
`;

export const StyledListWrapper = styled(ListWrapper)`
  .Health-List-Button-Style {
    background-color: #ffffff;
  }
`;
