import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import { withRouter, useParams } from "react-router-dom";
import { compose } from "redux";
import { formatPhoneNumber } from "../../utils/commonFunctions";
import { useLoginApi } from "../../hooks/useLoginApi";
import TeleHealingLogo from "../../assets/icons/Logo/TeleHealingLogo";
import { useLocation } from "react-router-dom";
import LoginSignUpContent from "../../components/LoginSignUpContent";
import { LoginSignupContainer } from "../../utils/styledUtils";
import { useHistory } from "react-router-dom";
import BackButtonWithTitle from "../../components/BackButtonWithTitle";
import AppleIconSignup from "./AppleIconSignup";
import ReCAPTCHA from "react-google-recaptcha";
import ApiCalls from "../../apis/ApiCalls";
import { IconWrapper } from "./styledComponents";
import WhatsAppIcon from "../../assets/icons/WhatsAppIcon";
import SignInWithGoogle from "../signup/Buttons/SignInWithGoogle";
import patientSignupBg from "../../assets/backgrounds/SignupBg.svg";
import patientLoginIllustration from "../../assets/images/login-illustration.png";
import docSignupBg from "../../assets/backgrounds/therapist-signup.svg";

const SignUpV2 = (props) => {
  const { step, channel, userType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [formData, setFormData] = useState(() => {
    const savedData = sessionStorage.getItem("userLoginSignUpData");
    return savedData ? JSON.parse(savedData) : {};
  });
  const { sendSignUpOtp, loading, verifySignUpOtp } = useLoginApi();
  const [otp, setOtp] = useState("");
  const [isOtpInvalid, setIsOtpInvalid] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const recaptchaRef = useRef();
  const recaptchSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const handleCaptcha = async () => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      const response = await ApiCalls.verifyCaptcha({ token: token });

      if (response.data.status === 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      message.error(
        error.response
          ? error?.response?.data?.data?.message
          : "Captcha Verification Failed",
        5
      );
      return false;
    }
  };
  const onContinue = async () => {
    if (!formData?.phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }
    if (!formData?.agreementSigned === false) {
      return message.error("Please Agree to Terms & Conditions");
    }
    try {
      const captchaVerified = await handleCaptcha();
      if (captchaVerified) {
        message.success("Captcha Completed");
        onResendOtp("sms", () => {
          setFormData((prev) => ({
            ...prev,
            channel: "sms",
          }));
          history.push(`/user/${userType}/signup/get-started/sms`);
        });
      } else {
        message.error("Captcha verification failed");
      }
    } catch (error) {
      message.error("An error occurred during captcha verification");
    }
  };

  const onResendOtp = (channel, postSuccessFunction, postErrorFunction) => {
    if (!formData?.phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }
    if (!formData?.agreementSigned) {
      return message.error("Please agree to terms");
    }

    let formattedPhoneNumber = formatPhoneNumber(
      formData?.phoneNumber,
      formData?.phoneNumberFormat
    );

    let data = {
      phoneNumber: formattedPhoneNumber,
      channel: channel,
      userType: userType,
    };

    sendSignUpOtp(data, postSuccessFunction, postErrorFunction);
  };
  const handleOtpSubmitClick = () => {
    if (otp.length < 6) {
      message.error("6 digit otp required", 5);
      return;
    }
    let formattedPhoneNumber = formatPhoneNumber(
      formData?.phoneNumber,
      formData?.phoneNumberFormat
    );
    let data = {
      phoneNumber: formattedPhoneNumber,
      code: otp,
    };
    verifySignUpOtp(data, userType, (res) => {
      setIsOtpInvalid(res);
    });
  };
  useEffect(() => {
    if (location.pathname.startsWith(`/user/${userType}/signup/get-started`)) {
      sessionStorage.setItem("userLoginSignUpData", JSON.stringify(formData));
    } else {
      sessionStorage.removeItem("userLoginSignUpData");
      setFormData({});
    }
  }, [formData, location.pathname]);
  useEffect(() => {
    if (userType === "patient") {
      if (otp.length === 6) {
        let formattedPhoneNumber = formatPhoneNumber(
          formData?.phoneNumber,
          formData?.phoneNumberFormat
        );
        let data = {
          phoneNumber: formattedPhoneNumber,
          code: otp,
        };
        verifySignUpOtp(data, userType, (res) => {
          setIsOtpInvalid(res);
        });
      }
    }
  }, [otp]);
  return (
    <>
      <LoginSignupContainer>
        {step || channel ? (
          <BackButtonWithTitle
            isButtonRequired
            onClick={() => {
              window.history.back();
            }}
          />
        ) : (
          <TeleHealingLogo />
        )}

        <LoginSignUpContent
          bgImage={
            userType === "patient"
              ? step
                ? patientSignupBg
                : patientLoginIllustration
              : docSignupBg
          }
          userType={userType}
          isOtpInvalid={isOtpInvalid}
          alreadyExists={alreadyExists}
          onResendOtp={onResendOtp}
          formData={formData}
          setFormData={setFormData}
          otp={otp}
          setOtp={setOtp}
          action={"signup"}
          title={
            step === "get-started"
              ? channel
                ? "Enter OTP Code 🔐"
                : "Let’s Get Started! ✨"
              : userType === "patient"
              ? "Welcome to TeleHealings"
              : "Welcome, Therapists!"
          }
          subTitle={
            step === "get-started"
              ? channel
                ? `Check your messages for a message from Telehealings. Enter the One-Time-Password (OTP) you received below.`
                : "Your mental healing starts here"
              : userType === "patient"
              ? "Let’s begin your path to better mental health."
              : "Sign in to connect with clients and grow your practice."
          }
          cancelButtonTitle={
            step === "get-started" ? (
              <>
                <WhatsAppIcon />
                <span>Send Codes to Whatsapp</span>
              </>
            ) : (
              "ALREADY HAVE AN ACCOUNT?"
            )
          }
          saveButtonTitle={
            step === "get-started" ? "Send OTP via SMS" : "GET STARTED"
          }
          onCancelClick={() => {
            if (step === "get-started") {
              //   onContinue();
              onResendOtp(
                "whatsapp",
                () => {
                  setFormData((prev) => ({
                    ...prev,
                    channel: "whatsapp",
                  }));
                  history.push(`/user/${userType}/signup/get-started/whatsapp`);
                },
                (res) => {
                  setAlreadyExists(res.email);
                }
              );
            } else {
              history.push(`/user/${userType}/login`);
            }
          }}
          onSaveClick={() => {
            if (step === "get-started") {
              //   onContinue();
              onResendOtp(
                "sms",
                () => {
                  setFormData((prev) => ({
                    ...prev,
                    channel: "sms",
                  }));
                  history.push(`/user/${userType}/signup/get-started/sms`);
                },
                (res) => {
                  setAlreadyExists(res.email);
                }
              );
            } else {
              history.push(`/user/${userType}/signup/get-started`);
            }
          }}
          cancelBgColor={step ? "rgba(7, 94, 84, 1)" : "rgba(255, 255, 255, 1)"}
          buttonFlexDirection={!step ? "column-reverse !important" : ""}
          cancelTextColor={
            step ? "rgba(255, 255, 255, 1)" : "rgba(66, 88, 137, 1)"
          }
          buttonOutsideCard={
            channel ? (
              ""
            ) : step ? (
              <>
                <IconWrapper>
                  <SignInWithGoogle props={props} />
                  <AppleIconSignup props={props} />
                </IconWrapper>
              </>
            ) : userType === "patient" ? (
              "THERAPIST LOGIN?"
            ) : (
              "PATIENT LOGIN?"
            )
          }
          onButtonOutsideClick={() => {
            if (!step && !channel) {
              if (userType === "patient") {
                history.push(`/user/doctor/signup`);
              } else {
                history.push(`/user/patient/signup`);
              }
            } else {
              return;
            }
          }}
          onOtpSubmitClick={() => {
            handleOtpSubmitClick();
          }}
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={recaptchSiteKey}
        />
      </LoginSignupContainer>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    cities: store.lookups.cities,
    credentials: store.lookups.credentials,
  };
};

export default compose(connect(mapStateToProps), withRouter)(SignUpV2);
