import styled from "styled-components";
import { LoginSignupContainer } from "../../utils/styledUtils";

export const StyledLoginSignupContainer = styled(LoginSignupContainer)`
  .Password-Style-Wrapper {
    .Login-Signup-Button-Wrapper {
      .Cancel-Button-Style {
        background-color: transparent !important;
        color: rgba(66, 88, 137, 1);
      }
    }
  }
  .Reset-Password-Style {
    .Password-Style:nth-child(1) {
      margin: 0px;
    }
  }
`;
