import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import {
  FailedMessage,
  DoneButtonContainer,
  Note,
} from "../BookAppointment/styledComponents";
import PrimaryButton from "../../components/PrimaryButton/index";
import PaymentFailed from "../../assets/images/CoporatePatientModule/PaymentFailed.png";
import { useHistory } from "react-router-dom";
import ApiCalls from "../../apis/ApiCalls";

const FailedPayment = ({ setShowConfirmationModal }) => {
  const history = useHistory();

  const { id } = useParams();
  const [scheduleDetails, setScheduleDetails] = useState([])

  useEffect(() => {
    getScheduleInfo()
  }, [])

  const getScheduleInfo = () => {
    ApiCalls.getScheduleDetails(id)
      .then((response) => {
        if (response.data) {
          setScheduleDetails(response.data.data)
        }
      })
      .catch((error) => {
        console.log("error....", error)
      });
  };

  const handleNavigation = () => {
    const path = id 
      ? "/user/base/home/view-doctors" 
      : `/user/base/home/doctor-profile/${scheduleDetails.providerId}`;
      
    history.push(path);
  };

  return (
    <>
      <FailedMessage>
        <span style={{ color: " #FF5353" }}>Payment Failed!</span>
      </FailedMessage>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "20px",
        }}
      >
        <img
          src={PaymentFailed}
          height="200px"
          weight="200px"
          alt=""
        />
      </div>
      <FailedMessage type2={true}>
         Please try again. <br />
      </FailedMessage>
      <Note>
        Your booking was unsuccessful due to payment failure. Please complete the payment for successful booking
      </Note>
      <DoneButtonContainer>
        <PrimaryButton
          label="Proceed"
          onClick={() => { handleNavigation()}}
        />
      </DoneButtonContainer>
    </>
  );
};

export default FailedPayment;
