import styled from "styled-components";

export const StyledIconButtonWrapper = styled.button`
  border-radius: 100px;
  background-color: rgba(246, 246, 246, 1);
  border: none;
  padding: 8px 8px 4px 8px;
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
export const CustomStyledButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 5px;
  background-color: rgb(255, 255, 255);
  height: 40px;
  border: 1px solid #dadce0;
  &:hover {
    border-color: rgb(210, 227, 252);
    background: rgba(66, 133, 244, 0.04);
  }
  p {
    flex-grow: 1;
    font-family: "Google Sans", arial, sans-serif;
    font-weight: 500;
    margin: 0;
  }
`;
