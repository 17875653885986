import { useCallback } from "react";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

const useLocationAndTimezone = () => {
  const fetchLocationAndTimezone = useCallback(
    async (lat, lng, postSuccess) => {
      try {
        const timestamp = Math.floor(Date.now() / 1000);

        const [geoResponse, timezoneResponse] = await Promise.all([
          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`
          ),
          fetch(
            `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${GOOGLE_MAPS_API_KEY}`
          ),
        ]);

        const [geoData, timezoneData] = await Promise.all([
          geoResponse.json(),
          timezoneResponse.json(),
        ]);

        let formattedLocation = "Unknown Location";
        let formattedTimezone = "Unknown Timezone";

        if (geoData.status === "OK") {
          const addressComponents =
            geoData.results[0]?.address_components || [];

          const cityComponent = addressComponents.find((comp) =>
            comp.types.includes("locality")
          );
          const stateComponent = addressComponents.find((comp) =>
            comp.types.includes("administrative_area_level_1")
          );
          const countryComponent = addressComponents.find((comp) =>
            comp.types.includes("country")
          );

          const city = cityComponent?.long_name || null;
          const state = stateComponent?.long_name || null;
          const country = countryComponent?.long_name || "Unknown Country";

          formattedLocation = [city, state, country].filter(Boolean).join(", ");
        }

        if (timezoneData.status === "OK") {
          const globalTimeZone = timezoneData.timeZoneId;
          const totalOffsetMinutes =
            (timezoneData.rawOffset + timezoneData.dstOffset) / 60;
          const offsetHours = Math.floor(totalOffsetMinutes / 60);
          const offsetMinutes = Math.abs(totalOffsetMinutes % 60);
          const gmtOffset = `GMT${
            offsetHours >= 0 ? `+${offsetHours}` : offsetHours
          }:${offsetMinutes === 0 ? "00" : offsetMinutes}`;

          formattedTimezone = `${formattedLocation
            .split(", ")
            .pop()} (${gmtOffset})`;
          postSuccess({
            location: formattedLocation,
            timezone: formattedTimezone,
            globalTimeZone: globalTimeZone,
          });
        }
      } catch (error) {
        postSuccess({
          location: "Unknown Location",
          timezone: "Unknown Timezone",
          globalTimeZone: "Unknown Timezone",
        });
      }
    },
    []
  );

  return { fetchLocationAndTimezone };
};

export default useLocationAndTimezone;
