import React, { useEffect, useState } from "react";
import {
  CommonDMFontTextRegular,
  CommonSchibstedFontTextBold,
} from "../../../utils/styledUtils";
import MaleIcon from "../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../assets/icons/FemaleIcon";
import moment from "moment";
import { DatePicker } from "antd";
import { NameWrapper, StyledInput } from "../styledComponents";
import CustomUserInputField from "../../../components/CustomUserInputField";
import CustomArticleButton from "../../../components/CustomArticleButton";
import HealthListCard from "./HealthListCard";

const Name = ({
  page,
  payload,
  setPayload,
  setIsMapOpen,
  wellnessProfile,
  setWellnessProfile,
  prebookingQuestions,
}) => {
  const [oauthData, setOauthData] = useState(null);

  const genderInfo = [
    {
      name: "Male",
      value: "male",
    },
    {
      name: "Female",
      value: "female",
    },
    { name: "Prefer not to say", value: "other" },
  ];
  const onChange = (date, dateString) => {
    if (date) {
      setPayload((prevData) => ({
        ...prevData,
        dob: date,
      }));
    }
  };
  useEffect(() => {
    const storedData = localStorage.getItem("oauthData");
    if (storedData) {
      setOauthData(JSON.parse(storedData));
    }
  }, []);
  return (
    <NameWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <CommonSchibstedFontTextBold
          fontSize={"23px"}
          style={{ textAlign: "center" }}
        >
          {page === 1
            ? "What should we call you?"
            : page === 2
            ? "What is your Gender?"
            : page === 3
            ? "How old are you?"
            : page === 4
            ? "Which country are you from?"
            : page === 5
            ? "What is your email?"
            : page === 6
            ? "Let’s Create a Password"
            : page >= 7 &&
              Array.isArray(prebookingQuestions) &&
              prebookingQuestions.length > 0
            ? prebookingQuestions.map((data, index) =>
                page === 7 + index ? data.question : ""
              )
            : ""}
        </CommonSchibstedFontTextBold>
        <CommonDMFontTextRegular
          textColor="rgba(102, 102, 102, 1)"
          style={{ textAlign: "center" }}
        >
          {page === 1
            ? "Let's have a brief introduction"
            : page === 2
            ? "It helps us understand you better"
            : page === 3
            ? "Help us reach out to you with better recommendations"
            : page === 4
            ? "Choosing accurate timezone helps us display available slots according to your local time. Confirm your location to avoid time conflicts."
            : page === 5
            ? "Helps us better tailor recommendations for you"
            : page === 6
            ? "Provide an extra safety for your app and information"
            : ""}
        </CommonDMFontTextRegular>
      </div>
      <div>
        {page === 1 || page === 5 ? (
          <div className="Page-Content-Wrapper">
            <CommonSchibstedFontTextBold
              fontSize="16px"
              textColor="rgba(153, 153, 153, 1)"
            >
              {page === 1 ? "Full Name" : "E-mail"}
            </CommonSchibstedFontTextBold>
            <StyledInput
              textAlign={"left"}
              value={page === 1 ? payload.name : payload.email}
              onChange={(e) => {
                if (page === 1) {
                  setPayload((prevData) => ({
                    ...prevData,
                    name: e.target.value,
                  }));
                } else {
                  setPayload((prevData) => ({
                    ...prevData,
                    email: e.target.value,
                  }));
                }
              }}
              disabled={page === 5 ? oauthData?.email : false}
            />
          </div>
        ) : page === 2 ? (
          <div className="Page-Content-Wrapper">
            {genderInfo.map((item, index) => {
              const isSelected = payload.gender === item.value;
              const iconColor = isSelected
                ? "rgba(255, 255, 255, 1)"
                : "rgba(66, 88, 137, 1)";
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    minHeight: "56px",
                    borderRadius: "8px",
                    border: "none",
                    padding: "16px",
                    backgroundColor: isSelected
                      ? "rgba(66, 88, 137, 1)"
                      : "rgba(255, 255, 255, 1)",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                  onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                  onClick={() => {
                    setPayload((prevData) => ({
                      ...prevData,
                      gender: item.value,
                    }));
                  }}
                >
                  <span
                    style={{
                      color: iconColor,
                      font: "600 16px DM Sans",
                    }}
                  >
                    {item.name}
                  </span>
                  {item.value === "male" ? (
                    <MaleIcon fill={iconColor} />
                  ) : item.value === "female" ? (
                    <FemaleIcon fill={iconColor} />
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
        ) : page === 3 ? (
          <div className="Page-Content-Wrapper">
            <CommonSchibstedFontTextBold
              fontSize="16px"
              textColor="rgba(153, 153, 153, 1)"
            >
              Date of birth
            </CommonSchibstedFontTextBold>
            <DatePicker
              style={{
                border: "2px solid rgba(66, 88, 137, 1)",
                outline: "none",
                backgroundColor: "rgba(255, 255, 255, 1)",
                width: "100%",
                height: "64px",
                borderRadius: "8px",
              }}
              format="MMMM D, YYYY"
              placeholder="Select Birth Date"
              disabledDate={function disabledDate(current) {
                return (
                  (current && current.valueOf() > moment().endOf("day")) ||
                  current.valueOf() < moment().subtract(120, "years")
                );
              }}
              onChange={onChange}
            />
          </div>
        ) : page === 4 ? (
          <div className="Page-Content-Wrapper">
            <CommonSchibstedFontTextBold
              fontSize="16px"
              textColor="rgba(153, 153, 153, 1)"
            >
              Timezone
            </CommonSchibstedFontTextBold>
            <StyledInput readOnly textAlign={"left"} value={payload.timeZone} />
            <CustomArticleButton
              closeBtn
              buttonText={"change location"}
              onClick={() => {
                setIsMapOpen(true);
              }}
              style={{ width: "100%", height: "48px" }}
              className={"Change-Location-Button-Style"}
            />
          </div>
        ) : page === 6 ? (
          <div className="Password-Field-Wrapper">
            {["Enter a Password", "Confirm Password"].map((label, index) => (
              <div key={index} className="Page-Content-Wrapper PasswordWrapper">
                <CommonSchibstedFontTextBold
                  fontSize="16px"
                  textColor="rgba(153, 153, 153, 1)"
                >
                  {label}
                </CommonSchibstedFontTextBold>
                <CustomUserInputField
                  className={"Password-Style"}
                  placeholder={index === 0 ? "Password" : "Confirm Password"}
                  isPasswordField
                  value={
                    index === 0 ? payload.password : payload.confirmPassword
                  }
                  onChange={(e) => {
                    if (index === 0) {
                      setPayload((prevData) => ({
                        ...prevData,
                        password: e.target.value,
                      }));
                    } else {
                      setPayload((prevData) => ({
                        ...prevData,
                        confirmPassword: e.target.value,
                      }));
                    }
                  }}
                  hideIconColor={"rgba(153, 153, 153, 1)"}
                />
              </div>
            ))}
          </div>
        ) : (
          page >= 7 &&
          Array.isArray(prebookingQuestions) &&
          prebookingQuestions.length > 0 &&
          prebookingQuestions.map((data, index) =>
            page === 7 + index ? (
              <HealthListCard
                key={index}
                question={data.question}
                questionIndex={index}
                data={data.choices}
                selectedValues={wellnessProfile}
                setSelectedValues={setWellnessProfile}
              />
            ) : null
          )
        )}
      </div>
    </NameWrapper>
  );
};

export default Name;
