import styled from "styled-components";
export const CommonWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 50px 112px 30px 80px;
  .TitleWrapper {
    gap: 15px;
  }
  @media only screen and (max-width: 600px) {
    margin: 0px;
    padding: 20px 15px 20px 20px;
    .PageTitle {
      font-size: 22px;
    }
  }
  @media (min-width: 601px) and (max-width: 1200px) {
    margin: 0px;
    padding: 20px 40px;
    .PageTitle {
      font-size: 24px;
    }
  }
  @media (min-width: 1201px) and (max-width: 1365px) {
    margin: 0px;
    padding: 30px 60px 30px 40px;
    .PageTitle {
      font-size: 26px;
    }
  }
`;

export const Separator = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => (props.isComma ? "0" : "0px 5px")};
  font-size: 11px;
  color: rgba(204, 204, 204, 1);
`;
export const TextsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
export const Text = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;
export const EmptyResultMessage = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Schibsted Grotesk";
  font-weight: 500;
  font-size: 24px;
  color: #c4c4c4;
`;
export const CommonSchibstedFontTextRegular = styled.p`
  color: ${(props) => props.textColor || "rgba(0, 0, 0, 1)"};
  font-family: "Schibsted Grotesk";
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || 400};
  margin: 0;
`;
export const CommonSchibstedFontTextBold = styled.p`
  color: ${(props) => props.textColor || "rgba(0, 0, 0, 1)"};
  font-family: "Schibsted Grotesk";
  font-size: ${(props) => props.fontSize || "24px"};
  font-weight: ${(props) => props.fontWeight || 700};
  margin: 0;
`;
export const CommonDMFontTextSmall = styled.p`
  color: ${(props) => props.textColor || "rgba(153, 153, 153, 1)"};
  font-family: "DM Sans";
  font-size: ${(props) => props.fontSize || "14px"};
  font-weight: ${(props) => props.fontWeight || 300};
  line-height: 19.6px;
  margin: 0;
`;
export const CommonDMFontTextRegular = styled.p`
  color: ${(props) => props.textColor || "rgba(153, 153, 153, 1)"};
  font-family: "DM Sans";
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: 22.4px;
  margin: 0;
`;
export const CommonDMFontTextBold = styled.p`
  color: ${(props) => props.textColor || "rgba(153, 153, 153, 1)"};
  font-family: "DM Sans";
  font-size: ${(props) => props.fontSize || "24px"};
  font-weight: ${(props) => props.fontWeight || 700};
  line-height: 22.4px;
  margin: 0;
`;

export const CommonIcon = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: ${(props) => props.width || "24px"};
  height: ${(props) => props.height || "24px"};
  border-radius: ${(props) => props.borderRadius || "0"};
`;
export const Divider = styled.div`
  min-height: 1px;
  margin: ${(props) => props.margin || "20px 0"};
  width: 100%;
  border: 1px solid ${(props) => props.border || "#f0f0f0"};
`;
export const VerticalDivider = styled.div`
  min-width: 1px;
  margin: ${(props) => props.margin || "0 20px"};
  height: ${(props) => props.height || "100%"};
  border: 1px solid ${(props) => props.border || "#f0f0f0"};
`;

export const CommonButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const LoginSignupContainer = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100vw;
  padding: 32px 80px;
  @media only screen and (max-width: 600px) {
    padding: 20px;
  }
  @media (min-width: 601px) and (max-width: 900px) {
    padding: 32px 40px;
  }
  @media (min-width: 901px) and (max-width: 1599px) {
    padding: 32px 50px;
  }
`;
export const TransparentButton = styled.p`
  font: 800 12px "Schibsted Grotesk";
  color: #425889;
  text-transform: uppercase;
  margin: 0px;
`;
export const CommonCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => props.minMaxWidth || "365px"}, 1fr)
  );
  gap: 24px 16px;
  padding: 0;
  height: auto;
`;
export const CommonFlex = styled.div`
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow: ${(props) => props.overflow || "auto"};
  flex-direction: ${(props) => props.flexDirection || "row"};
`;
