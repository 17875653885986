import React, { useEffect, useRef, useCallback } from "react";
import useLocationAndTimezone from "../../hooks/useLocationAndTimezone";
import { TimeZoneWrapper } from "./styledComponents.";
import CloseIcon from "../../assets/icons/CloseIcon";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
const GOOGLE_MAPS_SCRIPT_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;

const TimeZoneFetcher = ({
  setSavedTimezoneLocation,
  location,
  setLocation,
}) => {
  const { fetchLocationAndTimezone } = useLocationAndTimezone();
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const mapInstance = useRef(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const existingScript = document.querySelector(
      `script[src="${GOOGLE_MAPS_SCRIPT_URL}"]`
    );

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = GOOGLE_MAPS_SCRIPT_URL;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        console.log("Google Maps script loaded");
        initMap();
      };
    } else {
      initMap();
    }
  }, []);

  const initMap = useCallback(() => {
    if (!window.google || !mapRef.current) return;

    const map = new window.google.maps.Map(mapRef.current, {
      center: location,
      zoom: 12,
    });
    mapInstance.current = map;

    const marker = new window.google.maps.Marker({
      position: location,
      map,
      draggable: true,
    });

    marker.addListener("dragend", (event) => {
      updateLocation(event.latLng.lat(), event.latLng.lng(), marker, map);
    });

    map.addListener("click", (event) => {
      updateLocation(event.latLng.lat(), event.latLng.lng(), marker, map);
    });

    markerRef.current = marker;

    if (autocompleteRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        { types: ["geocode"] }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          updateLocation(
            place.geometry.location.lat(),
            place.geometry.location.lng(),
            marker,
            map
          );
        }
      });
    }
  }, []);

  const updateLocation = (lat, lng, marker, map) => {
    setLocation({ lat, lng });
    marker.setPosition({ lat, lng });
    map.setCenter({ lat, lng });

    fetchLocationAndTimezone(lat, lng, (data) => {
      setSavedTimezoneLocation({
        timeZone: data.timezone,
        location: data.location,
        globalTimeZone: data.globalTimeZone,
        lat,
        lng,
      });
      if (autocompleteRef.current) {
        autocompleteRef.current.value = data.location;
      }
    });
  };

  return (
    <TimeZoneWrapper>
      <div className="Page-Content-Wrapper">
        <input
          ref={autocompleteRef}
          type="text"
          placeholder="Search location..."
          className="location-input"
        />
        {autocompleteRef?.current?.value && (
          <CloseIcon
            stroke="rgba(0, 0, 0, 1)"
            onClick={() => {
              setSavedTimezoneLocation({
                location: "",
                timeZone: "",
                globalTimeZone: "",
              });
              if (autocompleteRef.current) {
                autocompleteRef.current.value = "";
              }
            }}
          />
        )}
      </div>
      <div
        ref={mapRef}
        className="map-container"
        style={{ height: "400px", width: "100%" }}
      />
    </TimeZoneWrapper>
  );
};

export default TimeZoneFetcher;
