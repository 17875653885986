import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: self-start;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
`;
export const DetailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
  > div {
    width: calc(100% - 65px);
    p {
      width: 100;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
