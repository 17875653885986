import React from "react";
import BackButton from "../BackButton";
import { PageTitle, TitleWrapper } from "./styledComponent";

const BackButtonWithTitle = ({
  onClick,
  title,
  isButtonRequired,
  fontSize,
  fontWeight,
  backgroundColor,
  stroke,
}) => {
  return (
    <TitleWrapper className="TitleWrapper">
      {isButtonRequired ? (
        <BackButton
          onClick={onClick}
          className="Back-Button-Style"
          backgroundColor={backgroundColor}
          stroke={stroke}
        />
      ) : (
        ""
      )}{" "}
      {title && (
        <PageTitle
          className="PageTitle"
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          {title}
        </PageTitle>
      )}
    </TitleWrapper>
  );
};

export default BackButtonWithTitle;
