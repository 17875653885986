import React from "react";
import { Input, Select } from "antd";
import styled from "styled-components";

const CustomGroupedInputDropdown = ({
  options,
  inputValue,
  onTextChange,
  dropdownValue,
  onDropdownChange,
  dropdownDisabled,
  className,
  inputPlaceholder,
}) => {
  return (
    <Wrapper className={className}>
      <Input.Group compact>
        <Input
          labelInValue
          style={{ width: "70%" }}
          placeholder={inputPlaceholder ? inputPlaceholder : "Price"}
          value={inputValue}
          onChange={onTextChange}
        />
        <Select
          dropdownClassName="Custom-Grouped-Dropdown-Style"
          placeholder={""}
          value={dropdownValue || undefined}
          onChange={(value) => {
            onDropdownChange(value);
          }}
          style={{ width: "30%" }}
          dropdownStyle={{ minWidth: "300px" }}
          optionLabelProp="value"
          disabled={dropdownDisabled}
        >
          {options.map((option, index) => (
            <Select.Option key={option.code} value={option.code}>
              {option.name} ({option.code})
            </Select.Option>
          ))}
        </Select>
      </Input.Group>
    </Wrapper>
  );
};

export default CustomGroupedInputDropdown;
const Wrapper = styled.div`
  width: 100%;

  .ant-input-group {
    border-radius: 4px !important;
  }
  .ant-select-selection-placeholder {
    color: #999999;
    font: 500 16px "Dm Sans";
  }
  input,
  .ant-select-selector {
    background: #fbfbfb;
    border: none !important;
  }
  input {
    height: 54px;
    color: #999999;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    font: 500 16px "Dm Sans";
    ::placeholder {
      color: #999999;
      font: 500 16px "Dm Sans";
    }
  }
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none;
  }
  .ant-select {
    height: 54px;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    .ant-select-selector {
      padding: 0px;
      height: 100%;
      outline: none !important;
      box-shadow: none !important;
      .ant-select-selection-search {
        right: 0px;
      }
      .ant-select-selection-placeholder {
        display: flex;
        justify-content: end;
        align-items: center;
      }
      .ant-select-selection-item {
        line-height: 54px !important;
        text-align: end;
        font: 500 16px "Dm Sans";
        color: #999999;
        background-color: #fbfbfb;
      }
    }
    .ant-select-arrow {
      display: none;
    }
  }
`;
