import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Spin, Upload } from "antd";
import { StyledRow, StyledColumn } from "../BasicProfile/styledComponents";
import { StyledFormItem } from "../../signup/styledComponents";
import {
  FormContainer,
  ButtonContainer,
  StyledButton,
  SpinContainer,
} from "./styledComponents";
import ApiCalls from "../../../apis/ApiCalls";
import UploadFile from "../BasicProfile/UploadFile";
import "./index.css";
const AddEducation = ({
  setAddNew,
  fetchWorkProfile,
  setEditItem,
  selectedItem,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [certificationFile, setCertificationFile] = useState(null);
  const [uploadCertificateItem, setUploadCertificateItem] = useState(null);
  const [saveCertificateFile, setSaveCertificateFile] = useState(false);
  const [fileForm, setFileForm] = useState();
  const [img, setImg] = useState();

  useEffect(() => {
    selectedItem && form.setFieldsValue(selectedItem);
    selectedItem && setImg(selectedItem?.url);
    // eslint-disable-next-line
  }, []);

  const onFinish = (val) => {
    const data = {
      educationalDetails: { ...val },
    };
    if (selectedItem) data.educationalDetails._id = selectedItem._id;
    setLoading(true);
    if (certificationFile || img) {
      ApiCalls.updateDoctorExtraInfo(data)
        .then((response) => {
          if (response.data.status) {
            message.success(
              `${selectedItem ? "Edited succesfully" : "Added successfully"}`
            );
            fetchWorkProfile(setLoading, setAddNew, setEditItem);
            setSaveCertificateFile(true);
          } else message.error(response.data.data.message);
        })
        .catch((error) => {
          setLoading(false);
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    } else {
      setLoading(false);
      message.error(
        "Please upload educational document",
        5
      );
    }
  };
  return (
    <>
      <FormContainer>
        {loading ? (
          <SpinContainer>
            <Spin spinning={true} />
          </SpinContainer>
        ) : (
          <div
            style={{ display: "flex", gap: "12px", flexDirection: "column" }}
          >
            <UploadFile
              file={certificationFile}
              setFile={setCertificationFile}
              title="Upload Certification Image"
              info="Please upload Certification image that must be readable"
              itemId={uploadCertificateItem}
              type="educationalDetails"
              fetchWorkProfile={fetchWorkProfile}
              setSelectedItem={setUploadCertificateItem}
              triggerUpload={saveCertificateFile}
              fileForm={fileForm}
              setFileForm={setFileForm}
              image={img}
            />
            <Form form={form} onFinish={onFinish}>
              <StyledRow>
                <StyledColumn>
                  <StyledFormItem
                    name="qualifiction"
                    label="Qualification"
                    rules={[
                      {
                        required: true,
                        message: "Qualification is required",
                      },
                    ]}
                    type2={true}
                  >
                    <Input
                      placeholder="eg degree in counseling, psychology"
                      autoFocus={true}
                    />
                  </StyledFormItem>
                </StyledColumn>
                <StyledColumn>
                  <StyledFormItem
                    name="instituteName"
                    label="Institute Name"
                    rules={[
                      {
                        required: true,
                        message: "Institute name is required",
                      },
                    ]}
                    type2={true}
                  >
                    <Input placeholder="Enter institute name" />
                  </StyledFormItem>
                </StyledColumn>
              </StyledRow>
              <StyledRow>
                <StyledColumn>
                  <StyledFormItem
                    name="instituteAddress"
                    label="Institute address"
                    rules={[
                      {
                        required: true,
                        message: "Institute address is required",
                      },
                    ]}
                    type2={true}
                  >
                    <Input placeholder="Enter institute address" />
                  </StyledFormItem>
                </StyledColumn>
              </StyledRow>
              <StyledRow submit={true}>
                <ButtonContainer>
                  <StyledButton
                    htmlType="button"
                    onClick={() =>
                      selectedItem ? setEditItem(null) : setAddNew(false)
                    }
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton htmlType="submit" type="primary">
                    {selectedItem ? "Done" : "Add"}
                  </StyledButton>
                </ButtonContainer>
              </StyledRow>
            </Form>
          </div>
        )}
      </FormContainer>
    </>
  );
};

export default AddEducation;
