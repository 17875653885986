import { LoginSignupContainer } from "../../utils/styledUtils";
import BackButtonWithTitle from "../../components/BackButtonWithTitle";
import LoginSignUpContent from "../../components/LoginSignUpContent";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  formatPhoneNumber,
  validatePassword,
} from "../../utils/commonFunctions";
import { useLoginApi } from "../../hooks/useLoginApi";
import WhatsAppIcon from "../../assets/icons/WhatsAppIcon";
import { message } from "antd";
import { StyledLoginSignupContainer } from "./styledComponents";
import patientLoginIllustration from "../../assets/images/login-illustration.png";
import docLoginBg from "../../assets/backgrounds/therapist-login.svg";

const ForgotPasswordV2 = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    verifyForgotPasswordOtpCode,
    sendForgotPasswordVerification,
    resetPasswordSubmit,
  } = useLoginApi();
  const [formData, setFormData] = useState(() => {
    const savedData = sessionStorage.getItem("userLoginSignUpData");
    return savedData ? JSON.parse(savedData) : {};
  });
  const { userType, channel } = useParams();
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const sendVerificationOtp = (channel, postSuccessFunction) => {
    if (!formData?.phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }
    let formattedPhoneNumber = formatPhoneNumber(
      formData?.phoneNumber,
      formData?.phoneNumberFormat
    );
    let data = {
      verificationContact: formattedPhoneNumber,
      channel: channel,
    };
    sendForgotPasswordVerification(data, postSuccessFunction);
  };
  const onPasswordResetClick = async () => {
    const errorMessage = validatePassword(password, confirmPassword);
    if (errorMessage) {
      message.error(errorMessage);
      return;
    }
    const data = {
      password: password,
    };

    await resetPasswordSubmit(userType, data);
  };
  const handleOtpSubmitClick = () => {
    if (otp.length === 6) {
      let formattedPhoneNumber = formatPhoneNumber(
        formData?.phoneNumber,
        formData?.phoneNumberFormat
      );
      let data = {
        verificationContact: formattedPhoneNumber,
        code: otp,
        userType: userType,
      };
      verifyForgotPasswordOtpCode(userType, data);
    }
  };
  useEffect(() => {
    if (location.pathname.startsWith(`/user/${userType}/forgot-password`)) {
      sessionStorage.setItem("userLoginSignUpData", JSON.stringify(formData));
    } else {
      sessionStorage.removeItem("userLoginSignUpData");
      setFormData({});
    }
  }, [formData, location.pathname]);
  useEffect(() => {
    if (userType === "patient") {
      if (otp.length === 6) {
        let formattedPhoneNumber = formatPhoneNumber(
          formData?.phoneNumber,
          formData?.phoneNumberFormat
        );
        let data = {
          verificationContact: formattedPhoneNumber,
          code: otp,
          userType: userType,
        };
        verifyForgotPasswordOtpCode(userType, data);
      }
    }
  }, [otp]);
  return (
    <StyledLoginSignupContainer>
      <BackButtonWithTitle
        isButtonRequired
        onClick={() => {
          window.history.back();
        }}
      />
      <LoginSignUpContent
        bgImage={userType === "patient" ? patientLoginIllustration : docLoginBg}
        userType={userType}
        onResendOtp={sendForgotPasswordVerification}
        formData={formData}
        setFormData={setFormData}
        otp={otp}
        setOtp={setOtp}
        action={"forgot-password"}
        title={channel === "reset" ? "Reset Password" : "Account Recovery"}
        subTitle={
          channel === "reset"
            ? "Enter your new password below. Make sure it’s strong and secure."
            : "Enter your mobile number below to receive a One-Time Password (OTP) via SMS."
        }
        cancelButtonTitle={
          <>
            <WhatsAppIcon />
            <span>Send Codes to Whatsapp</span>
          </>
        }
        saveButtonTitle={channel === "reset" ? "continue" : "Send OTP via SMS"}
        cancelBgColor={"rgba(7, 94, 84, 1)"}
        cancelTextColor={"rgba(255, 255, 255, 1)"}
        onCancelClick={() => {
          sendVerificationOtp("whatsapp", () => {
            setFormData((prev) => ({
              ...prev,
              channel: "whatsapp",
            }));
            history.push(`/user/${userType}/forgot-password/whatsapp`);
          });
        }}
        onSaveClick={() => {
          if (channel === "reset") {
            onPasswordResetClick();
          } else {
            sendVerificationOtp("sms", () => {
              setFormData((prev) => ({
                ...prev,
                channel: "sms",
              }));
              history.push(`/user/${userType}/forgot-password/otp/sms`);
            });
          }
        }}
        password={password}
        setPassword={setPassword}
        className={"Reset-Password-Style"}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        onOtpSubmitClick={() => {
          handleOtpSubmitClick();
        }}
      />
    </StyledLoginSignupContainer>
  );
};

export default ForgotPasswordV2;
