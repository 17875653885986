import * as React from "react";

const AppleIcon = ({ fill, width, height }) => {
  return (
    <svg
      width={width ? width : "26"}
      height={height ? height : "27"}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7332 24.54C18.4266 25.8067 16.9999 25.6067 15.6266 25.0067C14.1732 24.3933 12.8399 24.3667 11.3066 25.0067C9.38656 25.8333 8.37323 25.5933 7.22656 24.54C0.719893 17.8333 1.67989 7.62 9.06656 7.24667C10.8666 7.34 12.1199 8.23333 13.1732 8.31333C14.7466 7.99333 16.2532 7.07333 17.9332 7.19333C19.9466 7.35333 21.4666 8.15333 22.4666 9.59333C18.3066 12.0867 19.2932 17.5667 23.1066 19.1C22.3466 21.1 21.3599 23.0867 19.7199 24.5533L19.7332 24.54ZM13.0399 7.16667C12.8399 4.19333 15.2532 1.74 18.0266 1.5C18.4132 4.94 14.9066 7.5 13.0399 7.16667Z"
        fill={fill ? fill : "black"}
      />
    </svg>
  );
};

export default AppleIcon;
