import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  position: relative;
  padding: 16px;
  border-radius: 8px;
  button {
    width: 100%;
  }
`;
export const IconDiv = styled.div`
  width: 26px;
  height: 26px;
  background-color: ${(props) => props.bgColor || "#417FC2"};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.Close-Icon-Style {
    svg {
      margin-left: 2px;
    }
  }
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  top: -13px;
  right: 0px;
`;
export const ContentWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;
