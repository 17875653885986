import styled from "styled-components";
export const Wrapper = styled.div`
  .Invalid-Text-Style {
    margin: 40px 0;
    text-align: center;
  }
  .otp-container {
    display: flex;
    justify-content: center;
    input {
      width: 62px;
      height: 62px;
      margin: 0 5px;
      text-align: center;
      font-size: 16px;
      border-radius: 12px;
      border: none;
      outline: none;
      color: rgba(0, 0, 0, 1);
      background-color: rgba(248, 248, 250, 1);
      font-weight: 800;
      &:focus {
        border: 2px solid rgba(66, 88, 137, 1);
      }
    }
  }
`;
export const TextWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
  p {
    font-size: 12px;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-family: "Dm Sans";
    margin: 0px;
    span {
      color: rgb(66, 88, 137);
    }
  }
`;
