import * as React from "react";

const FemaleIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width ? width : "14"}
      height={height ? height : "20"}
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6978 11.3349C14.2882 8.74533 14.2882 4.53178 11.6978 1.94221C9.10744 -0.647402 4.8926 -0.647402 2.30217 1.94221C-0.288223 4.53178 -0.288223 8.74533 2.30217 11.3349C3.39865 12.431 4.78619 13.063 6.21834 13.2312V15.4548H4.65514C4.22346 15.4548 3.87354 15.8046 3.87354 16.2362C3.87354 16.6677 4.22346 17.0175 4.65514 17.0175H6.21834V19.2186C6.21838 19.6501 6.5683 20 7.00002 20C7.4317 20 7.78162 19.6501 7.78162 19.2186V17.0175H9.34486C9.77654 17.0175 10.1265 16.6677 10.1265 16.2362C10.1265 15.8046 9.77654 15.4548 9.34486 15.4548H7.78162V13.2313C9.21381 13.063 10.6013 12.431 11.6978 11.3349ZM3.40756 10.2299C1.42666 8.24959 1.42666 5.02748 3.40756 3.04721C5.38842 1.06701 8.6115 1.06689 10.5925 3.04721C12.5734 5.02748 12.5734 8.24959 10.5925 10.2299C8.61158 12.2101 5.38846 12.2101 3.40756 10.2299Z"
        fill={fill ? fill : "#425889"}
      />
    </svg>
  );
};

export default FemaleIcon;
