import { createGlobalStyle } from "styled-components";
import GilroyRegularWoff from "./assets/fonts/Gilroy-Regular.woff";
import GilroyMediumWoff from "./assets/fonts/Gilroy-Medium.woff";
import GilroySemiBoldWoff from "./assets/fonts/Gilroy-SemiBold.woff";
import GilroyBoldWoff from "./assets/fonts/Gilroy-Bold.woff";
import MetropolisBlack from "./assets/fonts/./FontNew/Fonts/ChrisSimpson-Metropolis/Metropolis-Black.otf";
import MetropolisBold from "./assets/fonts/./FontNew/Fonts/ChrisSimpson-Metropolis/Metropolis-Bold.otf";
import MetropolisRegular from "./assets/fonts/./FontNew/Fonts/ChrisSimpson-Metropolis/Metropolis-Regular.otf";
import MetropolisExtraBold from "./assets/fonts/./FontNew/Fonts/ChrisSimpson-Metropolis/Metropolis-ExtraBold.otf";
import "@fontsource/schibsted-grotesk"; // Defaults to weight 400
import "@fontsource/schibsted-grotesk/400.css"; // Specify weight
import "@fontsource/schibsted-grotesk/400-italic.css";
import "@fontsource/schibsted-grotesk/500.css";
import "@fontsource/schibsted-grotesk/600.css";
import "@fontsource/schibsted-grotesk/700.css";
import "@fontsource/schibsted-grotesk/900.css";
import "@fontsource/dm-sans"; // Defaults to weight 400
import "@fontsource/dm-sans/300.css";
import "@fontsource/dm-sans/400.css"; // Specify weight
import "@fontsource/dm-sans/500.css";
import "@fontsource/dm-sans/600.css";
import "@fontsource/dm-sans/700.css";
import "@fontsource/dm-sans/900.css";
import "@fontsource/dm-sans/400-italic.css"; // Specify weight and style
import "@fontsource/manrope"; // Defaults to weight 400
import "@fontsource/manrope/400.css"; // Specify weight

const GlobalStyle = createGlobalStyle`
  input[type=text]:focus {
    outline: none !important;
    outline-offset: 2px !important; 
  }

  input[type=text]{
    outline: none !important;
  }

  @font-face {
    font-family: 'Metropolis ExtraBold';
    src: local('Metropolis ExtraBold'), url(${MetropolisExtraBold}) format('opentype');   
  }

  // Metropolis
  @font-face {
    font-family: 'Metropolis Regular';
    src: local('Metropolis Regular'), url(${MetropolisRegular}) format('opentype');   
  }


  // Nexa Bold
  @font-face {
    font-family: 'Metropolis Bold';
    src: local('Metropolis Bold'), url(${MetropolisBold}) format('opentype');   
  }

  // NexaBlack
  @font-face {
    font-family: 'Metropolis Black';
    src: local('Metropolis Black'), url(${MetropolisBlack}) format('opentype');   
  }

  @font-face {
      font-family: 'Gilroy Regular';
      src: local('Gilroy Regular'), url(${GilroyRegularWoff}) format('woff');   
    }

  @font-face {
      font-family: 'Gilroy Medium';
      src: local('Gilroy Medium'), url(${GilroyMediumWoff}) format('woff');     
    }
  @font-face {
      font-family: 'Gilroy SemiBold';
      src: local('Gilroy SemiBold'), url(${GilroySemiBoldWoff}) format('woff');    
    }
  
  @font-face {
      font-family: 'Gilroy Bold';
      src: local('Gilroy Bold'), url(${GilroyBoldWoff}) format('woff');
    }

  .ant-picker-footer {
    display: none ;
  }
  .Add-Btn-Popup-Style {
    .ant-modal-close-icon {
      svg {
        font-size: 19px;
        color: rgba(0, 0, 0, 1);
      }
    }
    .ant-modal-footer {
      padding: 48px 30px 30px;
      .ant-btn-primary {
        background-color: rgba(66, 88, 137, 1) !important;
        font-family: "Schibsted Grotesk";
        font-size: 14px;
        font-weight: 800;
      }
    }
  }
  .Account-Deletion-Modal-Style{
    width: 100% !important;
    max-width: 370px;
    .ant-modal-content{
      display: flex;
      flex-direction: column;
    }
    .ant-modal-header{
      padding: 26px 24px 34px;
      .ant-modal-title {
      font-size: 16px;
      font-family: "Schibsted Grotesk";
      color: rgba(0, 0, 0, 1);
      font-weight: 700;
    }
    }
    .ant-modal-close{
      top: 10px;
      right: 2px;
      svg{
        fill:rgba(0, 0, 0, 1);
      }
    }
    .ant-modal-body{
      padding: 0 24px;
    }
    .ant-modal-footer{
      padding: 16px 24px 24px;
     .ant-btn{
      border:2px solid rgba(194, 70, 70, 1);
      color: rgba(194, 70, 70, 1);
      font-size: 14px;
      font-weight: 800;
      font-family: "Schibsted Grotesk";
      background-color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      text-transform: uppercase;
      height: 48px;
      &:hover, &:active, &:focus{
        opacity: 1;
      }

     }
    }
  &.Deletion-Confirmed-Style{
    .ant-btn {
      background-color: rgba(66, 88, 137, 1);
      color: rgba(255, 255, 255, 1);
      border: 2px solid rgba(66, 88, 137, 1);
    }
  }

  }
  .Logout-Modal-Style{
    width: 100% !important;
    max-width: 370px;
    .ant-modal-content{
      display: flex;
      flex-direction: column;
      .ant-modal-body{
        p{
          text-align: left;
          color: rgba(153, 153, 153, 1);
          font-size: 16px;
          font-weight: 500;
          font-family: "DM Sans";
          margin: 0px;
        }
 
      }
    }
    .ant-modal-footer{
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 20px;
      >div{
        width: 100%;
        height: 48px;
        .ant-btn{
          width: 100%;
          font-size: 14px;
          font-weight: 800;
          border-radius: 4px;
          text-transform: uppercase;
          font-family: "Schibsted Grotesk";
        }
        &:nth-child(1){
          .ant-btn{
            background-color: rgba(244, 247, 252, 1);
            color: rgba(66, 88, 137, 1);
            border: 1px solid rgba(244, 247, 252, 1);
          }
        }
        &:nth-child(2){
          .ant-btn{
            background-color: rgba(255, 255, 255, 1);
            color: rgba(194, 70, 70, 1);
            border: 2px solid rgba(194, 70, 70, 1);
          }
        }
      }
    }
    .ant-modal-header{
      padding: 26px 24px 34px;
      .ant-modal-title {
      font-size: 16px;
      font-family: "Schibsted Grotesk";
      color: rgba(0, 0, 0, 1);
      font-weight: 700;
    }
    }
    .ant-modal-close{
      top: 10px;
      right: 2px;
      svg{
        fill:rgba(0, 0, 0, 1);
      }
    }
  }
  .ant-picker-time-panel-column::after{
    height: 0;
  }

  .ant-dropdown-menu{
    position: absolute;
    right: -21px;
    max-height: 250px;
    overflow: auto;
    font-family: "Gilroy Medium"   ;
    & > li > a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  div > .anticon-loading, main > .anticon-loading{
    color: #4385C6;
  }

  .ant-popover-inner{
    border-radius: 10px !important;
  }

  .ant-popover-inner-content{
    padding: 12px 0 !important;
  }

  .app-action-dropdown{
    top: 52px !important;
    left: calc(100vw - 290px)  !important;
  }
  .Custom-Upcoming-Appointment-Drawer {
    @media only screen and (max-width: 429px) {
      .ant-drawer-content-wrapper{
       max-width: 320px;
       .DetailsWrapper {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
       }
      }
    }
    .ant-drawer-content-wrapper{
      .ant-drawer-body {
        padding: 32px 0 0  !important;
      }
    }
  }
  .Subscription-View-Drawer-Style {
    @media only screen and (max-width: 429px) {
      .ant-drawer-content-wrapper{
        width: 320px !important;
        .SubscriptionTitleWrapper,.DetailsWrapper{
          flex-direction: column;
          align-items: flex-start;
        }
      .DetailsWrapper{
        margin-top: 15px;
        gap: 10px;
      }
      }
    }
    .ant-drawer-content{
      padding: 30px 20px;
      .ant-drawer-body{
        padding: 0;
      }
      .ant-drawer-header-no-title{
        display: none;
      }
    }
  }
  .Subscription-Settings-Popup-Style {
    .ant-modal-header {
      padding: 26.5px 24px !important;
      .ant-modal-title{
        font-size: 16px;
        font-weight: 700;
        font-family: "Schibsted Grotesk";
        color: rgba(0, 0, 0, 1);
      }
    }
    .ant-modal-close {
      top: 15px;
      right: 8px;
    }
    .ant-modal-footer{
      padding: 32px 24px 24px;
      .ant-btn {
        border-radius: 4px;
        background-color: rgba(66, 88, 137, 1);
        font-size: 14px;
        font-weight: 800;
        font-family: "Schibsted Grotesk";
        text-transform: uppercase;
        height: 48px;
      }
    }
    .ant-modal-body{
      padding: 0 24px;
    }
  }
  .Confirm-Subscription-Popup-Style{
    .ant-modal-body{
      padding: 0 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: "Schibsted Grotesk";
    }
    .ant-modal-header{
      padding:26.5px 24px 24px 24px;
      .ant-modal-title{
        color: rgba(0, 0, 0, 1);
        font-size: 16px;
        font-weight: 700;
      }
    }
    .ant-modal-footer{
       padding:32px 24px 24px 24px;
      .ant-btn {
        border-radius: 4px;
        background-color: rgba(66, 88, 137, 1);
        font-size: 14px;
        font-weight: 800;
        font-family: "Schibsted Grotesk";
        text-transform: uppercase;
        height: 48px;
      }
    }
    .ant-modal-close {
      top: 15px;
      right: 8px;
    }
  }
  .Therapist-Sort-Dropdown-Style{
    .ant-dropdown-menu{
      min-width: 222px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 7px 15px;
      right: unset;
      max-height: 235px;
      .ant-dropdown-menu-item{
        font-size: 14px;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
        padding: 8px 12px;
        &:hover{
          border-radius: 3px;
        }
        a{
          line-height: 19.8px;
        }
      }
    }
  }
  .Therapist-Filter-Popup-Style {
    height: calc(100% - 20px);
    max-height: 900px;
    margin: 10px 0;
    .ant-modal-header{
      padding: 33px 20px 25px;
      .ant-modal-title{
        font: 700 18px "Schibsted Grotesk";
        color: rgba(0, 0, 0, 1);
      }
    }
    .ant-modal-content {
      height: 100%;
      .ant-modal-close {
        top: 23px;
        right: 8px;
      }
    }
    .ant-modal-body{
      padding: 0 20px;
      height: calc(100% - 144px);

    }
    .ant-modal-footer{
      width: 100%;
      padding: 0 20px 16px 20px;
      .ant-btn {
        background-color: rgba(66, 88, 137, 1);
        border-radius: 4px;
        text-transform:uppercase;
        font: 800 14px "Schibsted Grotesk";
        color:rgba(255, 255, 255, 1);
        height: 48px;
      }
    }
    .Reset-Button-Style {
        width: 100%;
        border-radius: 6px;
        text-transform: uppercase;
        padding: 6px 16px;
        display: flex;
        justify-content: center;
        margin: 15px 0 5px;
        .IconTextWrapper {
          flex-direction: row-reverse;
          width: fit-content;
          max-width: 100%;
          justify-content: center;
          align-items: center;
          gap: 13px;
          width:fit-content;
          max-width: 100%;
          .MenuText {
            font: 700 14px "Schibsted Grotesk";
            color: rgba(66, 88, 137, 1);
          }
        }
    }
  }
  .Popover-Menu-Style {
    padding: 0px;
    z-index: 1000 !important;
    .ant-popover-inner{
      border-radius: 4px !important;
      .ant-popover-inner-content {
        padding: 16px !important;
        min-width: 215px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .MenuItemWithIcon{
          padding: 0px;
          .IconTextWrapper{
            width: 100%;
          }
        }
      }
    }
    
    .ant-popover-arrow {
      display: none;
    }
  }
  .Therapist-Speciality-Popup-Style{
    max-height: 450px;
    height: 100%;
    .ant-modal-content{
      height: 100%;
    
    .ant-modal-body{
      height: calc(100% - 177px);
      overflow: auto;
    }
   }
  }
  .Therapist-Rating-Popup-Style{
    max-height: 730px;
    max-width: 474px;
    height: 90%;
    &.Raise-Issue-Style{
      max-height: 360px;
      .Rating-Title{
        text-align: left;
      }
    }
    .ant-modal-body{
      height: calc(100% - 185px);
      overflow: auto;
    }
    .ant-modal-header{
    padding:26.5px 24px 34px 24px;
    .ant-modal-title{
        color: rgba(0, 0, 0, 1);
        font-size: 16px;
        font-weight: 700;    
        font-family: "Schibsted Grotesk";
      }
    }
    .ant-modal-close {
    top: 16px;
    right: 5px;
    }
    .ant-btn{
      font-weight: 800;
      height: 48px;
      border-radius: 4px;
      font-family: "Schibsted Grotesk";
      &.Add-Button-Style{
        background-color: rgba(66, 88, 137, 1);
        color: rgba(255, 255, 255, 1);
      }
      &.Cancel-Button-Style {
      border-color: rgba(194, 70, 70, 1);
      color: rgba(194, 70, 70, 1);
      }
    }
    .ant-modal-footer{
      padding-bottom: 24px;
    }
  }
  .ant-modal-wrap:has(.Therapist-Rating-Popup-Style), 
  .ant-modal-wrap:has(.AddEditEducationExperiencePopup-Style) {
    @media only screen and (max-width: 600px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }
  }
  .Styled-Location-Tracker-Popup{
    width: 100% !important;
    height: 100%;
    max-width: 562px;
    max-height: 846px;
    @media only screen and (max-width: 900px) {}
    .ant-modal-content{
      height: 100%;
      @media only screen and (max-width: 900px) {
        height: calc(100% - 30px);
        margin: 10px;
      }
      .ant-modal-body{
        height: calc(100% - 187px);

        .map-container {
          height: calc(100% - 65px) !important;
          width: 100%  !important;
          border-radius: 8px;
        }
      }
    }
    .ant-modal-footer{
      button{
        padding: 20px 30px 30px;
        text-transform: uppercase;
        background-color: rgba(66, 88, 137, 1);
        font:800 14px "Schibsted Grotesk";
        border-radius: 4px;
      }
      .ant-btn-primary[disabled]{
        color:""
      } 
    }

  }
  .Show-Reason-Popup{
    .ant-modal-header{
    padding:26.5px 24px 34px 24px;
    .ant-modal-title{
        color: rgba(0, 0, 0, 1);
        font-size: 16px;
        font-weight: 700;    
        font-family: "Schibsted Grotesk";
      }
    }
    .ant-btn{
      font-weight: 800;
      height: 48px;
      border-radius: 4px;
      font-family: "Schibsted Grotesk";
      &.Add-Button-Style{
        background-color: rgba(66, 88, 137, 1);
        color: rgba(255, 255, 255, 1);
      }
    }
    .ant-modal-footer{
      padding: 24px;
    }
    .ant-modal-close {
    top: 12px;
    right: 5px;
    } 
      .ant-modal-body{
       padding: 0 24px;
       input{
        height: 45px;
       }
     }
  }
  .AddSpecLangPopup-Style{
    max-height: 736px;
    height: calc(100% - 40px) !important;
    .Search-Input-Style{
      margin-bottom: 32px;
      border-radius: 8px;
      border: 1px solid  #F0F0F0;
      input{
        font: 500 16px "Dm Sans";
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }
      .ant-input:focus, .ant-input-focused, .ant-input-search-button:after, .ant-input-search-button :after{
        box-shadow: none !important;
        outline: unset !important;
      }
      .ant-input-group-addon{
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        background-color:#ffffff
      }
      &.ant-input-affix-wrapper:focus{
        box-shadow: none !important;
      }
      .ant-input-search-icon::before{
        border: none !important;
      }
      button{
        background: #ffffff;
        border: none;
        box-shadow: none;
        text-shadow: none;
        opacity: 1;
      }
    }
    .ListWrapper-Spec-Style{
      height: calc(100% - 85px);    
    }
    .no-data-style{  
      height: calc(100% - 85px);    
      justify-content: center;
      align-items: center;
    }
  }
  .AddEditEducationExperiencePopup-Style {
    width: 100% !important;
    height: auto;
    max-width: 498px;
    margin: 20px;
    .ant-modal-content {
      width: 100%;
      height: 100%;
    }
    .ant-modal-header{
    padding:26px 24px 34px;
    .ant-modal-title{
        color: rgba(0, 0, 0, 1);
        font-size: 16px;
        font-weight: 700;    
        font-family: "Schibsted Grotesk";
      }
    }
    .ant-btn{
      font-weight: 800;
      height: 48px;
      border-radius: 4px;
      font-family: "Schibsted Grotesk";
      text-transform: uppercase;
      &.Upload-Button{
        height: 40px !important;    
        padding: 9px 34px;
      }
      &.Add-Button-Style{
        background-color: rgba(66, 88, 137, 1);
        color: rgba(255, 255, 255, 1);
      }
      &.Cancel-Button-Style{
        border-color:  #C24646;
        border-width: 2px;
        color: #C24646;
      }
    }
    .ant-modal-footer{
      padding: 32px 24px 24px;
    }
    .ant-modal-close {
    top: 12px;
    right: 5px;
    } 
      .ant-modal-body{    
        height: calc(100% - 186px);
        padding: 0 24px;
        .ant-form {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-bottom: 16px;
          .selected-flag{
            display: none;
          }
        }
       
       input{
        height: 54px;   
        border-radius: 4px;
        color: #999999;    
        border: none;
        &::placeholder
        {  
          color: #999999;    
        }
       }
     }
     @media only screen and (max-width: 600px) {
      .ant-modal-body{  
        padding: 0 15px;
        }
        .ant-modal-header{
          padding: 26px 15px;
        }
        .ant-modal-footer{
         padding: 24px 15px;
        }
     }
  }
  .custom-time-picker-dropdown{
    .ant-picker-panel-container {
    border-radius: 12px;
    .ant-picker-panel{
      border-bottom: none;
      width: 100%;
    }
    .SaveCancelButtons{
      width: 100%;
      padding: 0 24px 24px;
      .Cancel-Button-Style, .Save-Button-Style{
        padding: 22px 10px;
     } 
     .Cancel-Button-Style{
      border: 2px solid #C24646;
      color: #C24646;
      background: #ffffff;
     }
     .Save-Button-Style{
      border: 2px solid #425889;
     }
    }
    
    .ant-picker-time-panel .ant-picker-content{
      height: 100%;
      max-height: 180px;
      width: 100%;
      padding: 24px;
      gap: 8px;
      .ant-picker-time-panel-column:not(:first-child){
          border-left: unset !important;
        }
      .ant-picker-time-panel-cell{
        height: 44px;
        
        .ant-picker-time-panel-cell-inner{
          border-radius: 8px;
          height: 100%;
          line-height: 44px !important;
          color:#CCCCCC;
          font: 700 18px "Schibsted Grotesk";
        }
      }
      .ant-picker-time-panel-cell-selected{
        .ant-picker-time-panel-cell-inner{
          border-radius: 8px;
          color:#4E6DB1;
        }
      }
      .ant-picker-time-panel-column::-webkit-scrollbar {
         width: 0px;
      }

      .ant-picker-time-panel-column {
         scrollbar-width: none;
         -ms-overflow-style: none;
      }
    }
  }
  }
  .AddEditSpecPopup-Style{
    max-width: 370px;
    .ant-modal-body{
      display: flex;
      flex-direction: column;
      gap: 16px;
      .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        background: #FBFBFB;
        cursor: default !important;
      }
      .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input{
        cursor: default !important;
      }
      .ant-input, .ant-select-selector{
        background-color: #FBFBFB;
        font: 500 16px "Dm Sans" !important;
      }
      .ant-input:focus, .ant-input-focused{
        box-shadow: unset;
      }
      .ant-input-group{
        width: 30%;
        min-width: 170px ;
      .ant-input{
        width: 50% !important;
      }
      .ant-select{
        width: 50% !important;
         .ant-select-selection-item,
         .ant-select-selection-placeholder{
          padding-right: 15px;
        }
      }
      }
    }
    @media only screen and (max-width: 600px) {
      .ant-modal-body .ant-input-group{
        width: 50% !important;
      }
    }
  }
  .AccountSpecializationPopupClassStyle{
    .ant-modal-body{
      .ant-input-group{
        width: 57% !important;
      .ant-input{
        width: 70% !important;
      }
      .ant-select{
        width: 28% !important;
         .ant-select-selection-item,
         .ant-select-selection-placeholder{
          padding-right: 15px;
        }
      }
      }
    }
  }
  .Custom-Grouped-Dropdown-Style{
    border-radius: 8px;
    .ant-select-item-option-content{
      color: #000000;
      font: 600 16px "Dm Sans";
    }
    
    .ant-select-item{
      padding: 12px 20px;
      border-bottom: 1px solid #F4F7FC;
    }
    .rc-virtual-list-scrollbar {
      background-color: #F4F7FC !important; 
      width: 6px !important;
      border-radius: 99px;
    }

    .rc-virtual-list-scrollbar-thumb {
     background: #425889 !important; 
     border-radius: 99px;
     cursor: pointer;
    }

    .rc-virtual-list-scrollbar-thumb:hover {
     background: #425889 !important; 
    }
  }
  .CustomCloseMessageStyle {
  .ant-message-notice-content {
    background-color: #C7D7FE;
    border-radius: 50px;
    padding: 16px 36px;
    margin-top: 20px;
    
    .ant-message-custom-content {
      .anticon {
        display: none;
      }

      span:nth-child(2) {
        color: #B3261E;
        font-family: "Metropolis Regular";
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
`;
export default GlobalStyle;
