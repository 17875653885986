import React, { useEffect } from "react";
import { Checkbox, Form } from "antd";
import {
  CommonDMFontTextRegular,
  CommonSchibstedFontTextBold,
} from "../../../utils/styledUtils";
import { StyledInput } from "../../UpdateProfilePatient/styledComponents";
import CustomUserInputField from "../../../components/CustomUserInputField";
import moment from "moment";
import {
  GenderItem,
  SelectGenderDiv,
  StyledForm,
  UpdateFieldWrapper,
} from "../styledComponents";
import MaleIcon from "../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../assets/icons/FemaleIcon";
import CustomUserDatepicker from "../../../components/CustomUserDatepicker";
import DatePickerIcon from "../../../assets/icons/SettingsIcons/DatepickerIcon";
import AvatarUpload from "../../../components/AvatarUpload";
import CustomLoader from "../../../components/CustomLoader";
import { useDocOnboardingApi } from "../../../hooks/useDocOnboardingApi";

const genderLookup = ["Male", "Female", "Others"];
const passwordLookup = [
  { name: "password", rule: "Password is required", label: "Enter Password" },
  {
    name: "confirm",
    rule: "Confirm Password is required",
    label: "Confirm Password",
  },
];

const BasicInformation = ({
  payload,
  setPayload,
  onFinish,
  form,
  oauthData,
  docProfileDetails,
  loading,
}) => {
  const { imageUpload } = useDocOnboardingApi();
  const onAvatarUpload = (file) => {
    const formData = new FormData();
    formData.append("type", "profile_pic");
    formData.append("uploadFile", file);
    imageUpload(formData);
  };
  useEffect(() => {
    if (docProfileDetails) {
      setPayload((prevData) => ({
        ...prevData,
        gender: docProfileDetails.gender,
      }));

      form.setFieldsValue({
        gender: docProfileDetails.gender,
        name: docProfileDetails.name,
        email: docProfileDetails.email,
        timeZone: docProfileDetails.timeZone,
        dob: docProfileDetails.dob ? moment(docProfileDetails.dob) : undefined,
        aboutYou: docProfileDetails.aboutYou,
      });
    }
  }, [docProfileDetails]);
  return (
    <StyledForm
      form={form}
      onFinish={onFinish}
      onValuesChange={(changedValues, allValues) => {
        if (!allValues.dob && payload.showDob) {
          setPayload((prevData) => ({ ...prevData, showDob: false }));
        }
      }}
    >
      {loading ? (
        <CustomLoader className={"CustomLoader"} />
      ) : (
        <>
          <AvatarUpload
            image={docProfileDetails.profilePic}
            onUpload={onAvatarUpload}
          />
          <CommonDMFontTextRegular
            textColor="#666666"
            style={{ textAlign: "center" }}
            fontSize="14px"
          >
            Your avatar should be a friendly and inviting headshot, clearly
            identifiable as you.
          </CommonDMFontTextRegular>
          <UpdateFieldWrapper gap="16px">
            <CommonSchibstedFontTextBold fontSize="16px" textColor="#848484">
              {" "}
              Select your gender*
            </CommonSchibstedFontTextBold>
            <SelectGenderDiv className="SelectGenderDiv">
              {genderLookup.map((item, index) => {
                const isSelected = payload.gender === item;
                const iconColor = isSelected ? "#FFFFFF" : "#666666";
                return (
                  <GenderItem
                    type="button"
                    key={index}
                    selected={isSelected}
                    onClick={() => {
                      setPayload((prev) => ({ ...prev, gender: item }));
                    }}
                  >
                    <span
                      style={{
                        color: iconColor,
                        font: "600 16px DM Sans",
                      }}
                    >
                      {item}
                    </span>
                    {item === "Male" ? (
                      <MaleIcon fill={iconColor} />
                    ) : item === "Female" ? (
                      <FemaleIcon fill={iconColor} />
                    ) : (
                      <></>
                    )}
                  </GenderItem>
                );
              })}
            </SelectGenderDiv>
          </UpdateFieldWrapper>
          <UpdateFieldWrapper>
            <CommonSchibstedFontTextBold fontSize="16px" textColor="#848484">
              Full Name*
            </CommonSchibstedFontTextBold>
            <CustomUserInputField
              className={"Password-Style"}
              name="name"
              rules={[{ required: true, message: "Full Name is required" }]}
              type2={true}
              placeholder="Provide a Name"
            />
          </UpdateFieldWrapper>
          <UpdateFieldWrapper>
            <CommonSchibstedFontTextBold fontSize="16px" textColor="#848484">
              Email*
            </CommonSchibstedFontTextBold>
            <CustomUserInputField
              className={"Password-Style"}
              name="email"
              rules={[{ required: true, message: "Email is required" }]}
              type2={true}
              placeholder="Enter your email"
            />
          </UpdateFieldWrapper>
          {!oauthData && (
            <UpdateFieldWrapper>
              {passwordLookup.map((item, index) => (
                <>
                  <CommonSchibstedFontTextBold
                    fontSize="16px"
                    textColor="#848484"
                  >
                    {item.label}
                  </CommonSchibstedFontTextBold>
                  <CustomUserInputField
                    name={item.name}
                    className={"Password-Style"}
                    placeholder={item.label}
                    isPasswordField
                    hideIconColor={"#848484"}
                  />
                </>
              ))}
            </UpdateFieldWrapper>
          )}
          <UpdateFieldWrapper>
            <CommonSchibstedFontTextBold fontSize="16px" textColor="#848484">
              Location
            </CommonSchibstedFontTextBold>
            <StyledInput readOnly textAlign={"left"} value={payload.timeZone} />
            <Checkbox
              checked={payload.showTimeZone}
              onChange={(e) =>
                setPayload((prevData) => {
                  const currentHiddenFields = prevData.hiddenFields || [];
                  let updatedHiddenFields;
                  if (!e.target.checked) {
                    updatedHiddenFields = currentHiddenFields.includes(
                      "timeZone"
                    )
                      ? currentHiddenFields
                      : [...currentHiddenFields, "timeZone"];
                  } else {
                    updatedHiddenFields = currentHiddenFields.filter(
                      (field) => field !== "timeZone"
                    );
                  }
                  return {
                    ...prevData,
                    showTimeZone: e.target.checked,
                    hiddenFields: updatedHiddenFields,
                  };
                })
              }
            >
              Show in profile
            </Checkbox>
          </UpdateFieldWrapper>
          <UpdateFieldWrapper>
            <CommonSchibstedFontTextBold fontSize="16px" textColor="#848484">
              Date of birth*
            </CommonSchibstedFontTextBold>
            <CustomUserDatepicker
              name="dob"
              className={"Password-Style Dob-Style"}
              rules={[
                {
                  required: true,
                  message: "DOB is required",
                },
              ]}
              type2={true}
              style={{ width: "100%" }}
              format="DD-MM-YYYY"
              placeholder="Select birth date"
              inputReadOnly={true}
              disabledDate={(current) =>
                current &&
                (current > moment().endOf("day") ||
                  current < moment().subtract(100, "years"))
              }
              suffixIcon={<DatePickerIcon fill="#999999" />}
            />
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) => prev.dob !== curr.dob}
            >
              {({ getFieldValue }) => {
                const dobValue = getFieldValue("dob");
                return (
                  <Checkbox
                    checked={dobValue ? payload.showDob : false}
                    disabled={!dobValue}
                    onChange={(e) =>
                      setPayload((prevData) => ({
                        ...prevData,
                        showDob: e.target.checked,
                      }))
                    }
                  >
                    Show in profile
                  </Checkbox>
                );
              }}
            </Form.Item>
          </UpdateFieldWrapper>

          <UpdateFieldWrapper>
            <CommonSchibstedFontTextBold fontSize="16px" textColor="#848484">
              {" "}
              About You
            </CommonSchibstedFontTextBold>
            <CustomUserInputField
              isTextArea
              name="aboutYou"
              className={"Password-Style"}
              type2={true}
            />
          </UpdateFieldWrapper>
        </>
      )}
    </StyledForm>
  );
};

export default BasicInformation;
