import React, { useState } from "react";
import {
  AvailabilityDateWrapper,
  ContentFlexWrapper,
  IconDiv,
  MappedAvailablityWrapper,
} from "../styledComponents";
import { DatePicker, TimePicker } from "antd";
import MenuItemWithIcon from "../../../components/MenuItemWithIcon";
import PlusCircleIcon from "../../../assets/icons/SettingsIcons/PlusCircle";
import moment from "moment";
import DatePickerIcon from "../../../assets/icons/SettingsIcons/DatepickerIcon";
import CloseIcon from "../../../assets/icons/CloseIcon";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
const AvailabilitySlot = ({
  item,
  idx,
  isSpecial,
  handleDateChange,
  handleTimeChange,
  handleAddTime,
  onDateRemoveClick,
  onTimeRemoveClick,
}) => {
  const [startTimeOpen, setStartTimeOpen] = useState({});
  const [endTimeOpen, setEndTimeOpen] = useState({});
  const [tempStartTime, setTempStartTime] = useState({});
  const [tempEndTime, setTempEndTime] = useState({});

  const handleStartTimeConfirm = (timeIdx) => {
    if (tempStartTime[timeIdx]) {
      handleTimeChange(
        isSpecial,
        idx,
        timeIdx,
        "startTime",
        tempStartTime[timeIdx]
      );
      setStartTimeOpen((prev) => ({ ...prev, [timeIdx]: false }));
    }
  };

  const handleEndTimeConfirm = (timeIdx) => {
    if (tempEndTime[timeIdx]) {
      handleTimeChange(
        isSpecial,
        idx,
        timeIdx,
        "endTime",
        tempEndTime[timeIdx]
      );
      setEndTimeOpen((prev) => ({ ...prev, [timeIdx]: false }));
    }
  };

  const handleStartTimeCancel = (timeIdx) => {
    setTempStartTime((prev) => ({ ...prev, [timeIdx]: null }));
    setStartTimeOpen((prev) => ({ ...prev, [timeIdx]: false }));
  };

  const handleEndTimeCancel = (timeIdx) => {
    setTempEndTime((prev) => ({ ...prev, [timeIdx]: null }));
    setEndTimeOpen((prev) => ({ ...prev, [timeIdx]: false }));
  };
  return (
    <MappedAvailablityWrapper
      key={`${isSpecial ? "special" : "offDay"}-${idx}`}
    >
      <AvailabilityDateWrapper className="DatePicker">
        <DatePicker
          placeholder={"Choose date"}
          suffixIcon={<DatePickerIcon fill="#999999" />}
          value={item?.date ? moment(item?.date) : null}
          onChange={(date) => handleDateChange(date, idx, isSpecial)}
          format="dddd, D MMMM YYYY"
          disabledDate={(current) =>
            current && current < moment().startOf("day")
          }
        />
        <IconDiv
          bgColor="#C24646"
          onClick={() => {
            onDateRemoveClick(idx, isSpecial);
          }}
          className="IconDiv"
        >
          <CloseIcon width={"15.5px"} height={"15.5px"} />
        </IconDiv>
      </AvailabilityDateWrapper>
      <ContentFlexWrapper className="Time-Wrapper">
        {item?.timeArray?.map((time, timeIdx) => (
          <div key={`time-${timeIdx}`} className="Time-Pair">
            <TimePicker
              format="hh:mm A"
              minuteStep={5}
              use12Hours
              inputReadOnly
              value={time?.startTime}
              placeholder="Start Time"
              onSelect={(time) =>
                setTempStartTime((prev) => ({ ...prev, [timeIdx]: time }))
              }
              open={startTimeOpen[timeIdx] || false}
              onOpenChange={(open) =>
                setStartTimeOpen((prev) => ({ ...prev, [timeIdx]: open }))
              }
              popupClassName="custom-time-picker-dropdown"
              panelRender={(originPanel) => (
                <div style={{ width: "100%" }}>
                  {originPanel}
                  <SaveCancelButtons
                    className={"SaveCancelButtons"}
                    saveButtonTitle={"Confirm"}
                    onCancelClick={() => handleStartTimeCancel(timeIdx)}
                    onSaveClick={() => handleStartTimeConfirm(timeIdx)}
                    isDisabled={!time?.startTime && !tempStartTime}
                  />
                </div>
              )}
            />
            <span style={{ color: "#CCCCCC" }}>-</span>
            <div className="Availalibity-EndTime-Close-Wrapper">
              <TimePicker
                format="hh:mm A"
                minuteStep={5}
                use12Hours
                inputReadOnly
                value={time?.endTime}
                placeholder="End Time"
                onSelect={(time) =>
                  setTempEndTime((prev) => ({ ...prev, [timeIdx]: time }))
                }
                disabled={!time?.startTime}
                popupClassName="custom-time-picker-dropdown"
                open={endTimeOpen[timeIdx] || false}
                onOpenChange={(open) =>
                  setEndTimeOpen((prev) => ({ ...prev, [timeIdx]: open }))
                }
                panelRender={(originPanel) => (
                  <div style={{ width: "100%" }}>
                    {originPanel}
                    <SaveCancelButtons
                      className={"SaveCancelButtons"}
                      saveButtonTitle={"Confirm"}
                      onCancelClick={() => handleEndTimeCancel(timeIdx)}
                      onSaveClick={() => handleEndTimeConfirm(timeIdx)}
                      isDisabled={!time?.endTime && !tempEndTime}
                    />
                  </div>
                )}
              />
              <IconDiv
                bgColor="#C24646"
                onClick={() => {
                  onTimeRemoveClick(isSpecial, idx, timeIdx);
                }}
              >
                <CloseIcon width={"12.5px"} height={"12.5px"} />
              </IconDiv>
            </div>
          </div>
        ))}
      </ContentFlexWrapper>
      <div className="FullWidth-Style">
        <MenuItemWithIcon
          className="CopyBreakWrapper"
          onMenuClick={() => handleAddTime(isSpecial, idx)}
          buttonText="Add Time"
          svg={<PlusCircleIcon />}
          textColor="#425889"
        />
      </div>
    </MappedAvailablityWrapper>
  );
};
export default AvailabilitySlot;
