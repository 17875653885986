import styled from "styled-components";
import MenuItemWithIcon from "../../components/MenuItemWithIcon";

export const MyAccountWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  padding: 30px 100px 30px 60px;
  gap: 64px;
  .Bottom-Cancel-Save-Button,
  .Back-Button-Style {
    display: none;
  }
  .Top-Cancel-Save-Button {
    width: 100%;
    max-width: 376px;
  }
  @media only screen and (max-width: 360px) {
    padding: 12px !important;
  }
  @media only screen and (max-width: 600px) {
    padding: 30px 18px 16px 20px;
    .Hide-Sidebar,
    .Hide-Content-Section,
    .Top-Cancel-Save-Button {
      display: none;
    }
    .Back-Button-Style {
      display: flex;
    }
    .Show-Sidebar,
    .Show-Content-Section {
      display: block;
      max-width: unset;
    }
    .MenuContainer {
      width: 100%;
      margin: 0px;
      max-width: unset;
      .TitleWrapper {
        margin-bottom: 25px;
        .PageTitle {
          font-size: 22px;
        }
      }
      .StyledMenuItemwithIcon {
        padding: 10px 16px;
      }
    }
    .AccountInfoWrapper {
      padding: 0;
      .TitleButtonWrapper {
        border-bottom: unset;
        margin-bottom: unset;
        padding-bottom: unset;
        margin-bottom: 50px;
      }
      .AccountDetailWrapper {
      }
      .Inner-Settings-Container {
        padding-bottom: 20px;
      }
      .Family-List-Container {
        max-width: unset;
      }
      .Bottom-Cancel-Save-Button {
        display: block;
        margin: 50px 0 0;
        width: 100%;
        button {
          max-width: unset;
          &:nth-child(1) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  @media (min-width: 601px) and (max-width: 1200px) {
    padding: 30px 20px 16px;
    .Hide-Sidebar,
    .Hide-Content-Section,
    .Top-Cancel-Save-Button {
      display: none;
    }
    .Show-Sidebar,
    .Show-Content-Section {
      display: block;
      max-width: unset;
    }
    .Bottom-Cancel-Save-Button {
      display: flex;
      margin: 60px 0 0;
      width: 100%;
      gap: 30px;
      button {
        max-width: unset;
      }
    }
    .Back-Button-Style {
      display: flex;
    }
    .MenuContainer {
      margin-bottom: 30px;
      width: 100%;
      max-width: unset;
    }
    .Notification-Container {
      .TitleButtonWrapper {
        margin-bottom: unset;
        padding: 0 0 40px;
      }
    }
    .AccountInfoWrapper {
      padding: 0;
      .TitleButtonWrapper {
        border-bottom: unset;
        margin-bottom: unset;
        padding-bottom: unset;
        margin-bottom: 50px;
      }
      .Family-List-Container {
        max-width: unset;
      }
    }
  }

  @media (min-width: 1201px) and (max-width: 1365px) {
    padding: 30px 40px 60px;
    gap: 50px;
    .Hide-Content-Section,
    .Top-Cancel-Save-Button {
      display: none;
    }
    .Show-Content-Section {
      display: block;
    }
    .Bottom-Cancel-Save-Button {
      display: flex;
      margin: 40px 0 30px;
      width: 100%;
      button {
        max-width: unset;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1599px) {
    gap: 45px;
    padding: 30px 55px 30px 40px;
  }
`;

export const PageName = styled.h3`
  font-family: Metropolis Black;
  font-weight: 400;
  font-size: 24px;
  color: #03183c;
`;

export const MenuDropDown = styled.div`
  display: none;
  @media only screen and (max-width: 1200px) {
    display: block;
    height: 50%;
    width: 50%;
  }
`;

export const MenuContainer = styled.div`
  height: auto;
  max-width: 374px;
  width: 100%;
  .TitleWrapper {
    margin-bottom: 50px;
  }
`;

export const SelectedPageContainer = styled.div`
  height: auto;
  width: calc(100% - 374px);
  display: flex;
  flex-direction: column;
  margin-top: 62px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin: 0px;
  }
`;

export const PageInfo = styled.p`
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
  font-size: 16x;
  color: #6f6f6f;
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: start;
  height: ${(props) => (props.ItemType ? "90px" : "20px")};
  width: ${(props) => (props.ItemType ? "230px" : "214px")};
  cursor: pointer;
  padding-top: ${(props) => (props.ItemType ? "15px" : "0")};

  @media only screen and (max-width: 1200px) {
    background-color: #fff;
    color: #4385c6;
    padding-top: 0px;
    height: ${(props) => (props.ItemType ? "35px" : "20px")};
    width: ${(props) => (props.ItemType ? "180px" : "120px")};
    border-bottom: ${(props) => (props.ItemType ? "none" : "0")};
  }

  @media only screen and (max-width: 600px) {
    width: ${(props) => (props.ItemType ? "140px" : "120px")};
  }
`;

export const MenuItemName = styled.span`
  font-family: Metropolis Regular;
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => (props.selected ? "#2C509F" : "#35364f")};
  text-transform: capitalize;
  margin-left: 20px;
  margin-top: 10px;

  @media only screen and (max-width: 1200px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const MenuItemIcon = styled.span`
  height: 20px;
  width: 20px;
  & svg {
    width: ${(props) => props.item.name == "Manage Account" && "28px"};
    height: ${(props) => props.item.name == "Manage Account" && "28px"};
    margin-left: ${(props) =>
      props.item.name == "Manage Account" ? "5px" : "0px"};
    color: ${(props) => props.item.name == "Manage Account" && "blue"};
  }
  & svg circle,
  path {
    stroke: ${(props) => (props.selected ? "#4385C6 !important" : null)};
  }
`;
export const StyledMenuItemwithIcon = styled(MenuItemWithIcon)`
  background: rgba(244, 247, 252, 1);
  padding: 24px;
  border-radius: 10px;
  &.Selected-Style {
    border: 1px solid rgba(66, 88, 137, 1);
  }
  .IconTextWrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    .MenuText {
      font-weight: 600;
      font-size: 17px;
    }
    .User-Img-Style {
      height: 64px;
      width: 64px;
      border-radius: 100px;
      object-fit: cover;
      object-position: center;
    }
    .Content-Wrapper {
      display: grid;
      gap: 10px;
      .User-Style {
        color: rgba(0, 0, 0, 1);
        font-weight: 700 !important;
        font-size: 18px;
      }
      .Number-Style {
        color: rgba(153, 153, 153, 1);
        font-size: 16px;
      }
    }
  }
`;

export const MenuItemsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 100%;
  border-radius: 10px;
  background: #e3e3e34d;
`;

export const AccountInfoWrapper = styled.div`
  height: auto;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  padding: 25px 0px 30px 0px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  @media only screen and (max-width: 900px) {
    width: auto;
  }
`;

export const ManageDoctorProfile = styled.div`
  margin-top: -120px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: auto;
  width: 100%;
  border-radius: 10px;

  @media only screen and (max-width: 1200px) {
    flex-direction: wrap;
  }
`;

export const ManageProfileText = styled.span`
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #03183c;
`;

export const ManageProfileButton = styled.button`
  height: 60px;
  width: 185px;
  border-radius: 7px;
  background: #4385c6;
  border: 1px solid #ebeaed;
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  align-self: flex-end;
  margin-right: 50px;
  margin-top: -160px;
`;

export const ModalContent = styled.div`
  max-height: 70vh;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const LogoutConfirmationText = styled.p`
  margin-top: 20px;
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  color: #000;
  text-align: center;
`;
