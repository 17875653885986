import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLoginApi } from "../../hooks/useLoginApi";
import AppleIcon from "../../assets/icons/AppleIcon";
import {
  CustomStyledButtonWrapper,
  StyledIconButtonWrapper,
} from "./styledComponents";
import { message } from "antd";

const AppleIconSignup = ({ props }) => {
  const { userType } = useParams();
  const { appleLoginSuccess } = useLoginApi();
  useEffect(() => {
    if (window.AppleID) {
      window.AppleID.auth.init({
        clientId: "telehealings",
        redirectURI:
          "https://api-staging.telehealings.com/api/v1/registration/callbacks/auth/apple",
        usePopup: true,
      });
    }
  }, []);
  const handleAppleSignup = async () => {
    try {
      const { AppleID } = window;
      if (!AppleID) throw new Error("Apple Sign-In is not available");

      AppleID.auth
        .signIn({
          scope: "email name",
        })
        .then(async (authResponse) => {
          if (authResponse?.authorization?.id_token) {
            await appleLoginSuccess(userType, authResponse);
          } else {
            throw new Error("Apple login failed");
          }
        });
    } catch (error) {
      message.error(error.message || "Apple Login Failed");
    }
  };
  return (
    // <StyledIconButtonWrapper
    //   onMouseOver={(e) => (e.target.style.cursor = "pointer")}
    //   onClick={() => handleAppleSignup()}
    // >
    //   <AppleIcon />
    // </StyledIconButtonWrapper>
    <CustomStyledButtonWrapper
      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
      onClick={() => handleAppleSignup()}
    >
      <AppleIcon />
      <p>Sign in with Apple</p>
    </CustomStyledButtonWrapper>
  );
};

export default AppleIconSignup;
