import React, { useEffect, useRef, useState } from "react";
import TextLogo from "../../assets/icons/Logo/TextLogo.js";
import { useParams } from "react-router-dom";
import { Row, Col, message } from "antd";
import PatientRegister from "./PatientRegister";
import { connect } from "react-redux";
import { RootContainer } from "./styledComponents.js";
import { Main, LogoContainer } from "./styledComponents";
import CorporateRegister from "./Corporate.jsx";
import Banner from "./Banner.jsx";
import PatientTherapistSelector from "./PatientTherapistSelector";
import PhoneForm from "./PhoneForm";
import PasswordForm from "./PasswordForm";
import SignInWithPassword from "./Buttons/SignInWithPassword";
import SignInWithGoogle from "./Buttons/SignInWithGoogle";
import SignInUsingOtp from "./Buttons/SignInUsingOtp";
import SendViaWhatsapp from "./Buttons/SendViaWhatsapp";
import SendViaSMS from "./Buttons/SendViaSMS";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ApiCalls from "../../apis/ApiCalls.js";
import SignUp from "./Buttons/SignUp.jsx";
import OtpInput from "./OtpInput.jsx";
import { formatPhoneNumber } from "../login/index.jsx";
import storage from "../../storage/index.js";
import reduxActions from "../../redux-store/actions/index.js";
import {
  FormContainer,
  LoginSignupContainer,
} from "../login/styledComponents.js";
import OtpVerification from "./OtpVerification.jsx";
import Continue from "./Buttons/Continue.jsx";
import ReCAPTCHA from "react-google-recaptcha";

const Signup = (props) => {
  const [loading, setLoading] = useState(false);
  const [signUpType, setSignUpType] = useState(null);
  const [passwordInput, setPasswordInput] = useState(false);
  const { userType } = useParams();

  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneNumberFormat, setPhoneNumberFormat] = useState();

  const [signupUsingOtp, setSignupUsingOtp] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [verificationSend, setVerificationSend] = useState(false);
  const [enableSignUpButton, setEnableSignUpButton] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [channel, setChannel] = useState("");

  const recaptchaRef = useRef();
  const recaptchSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    if (userType === "patient") setSignUpType("patient");
    else if (userType === "doctor") setSignUpType("doctor");
  }, [userType]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('id');
    if(userId) {
      localStorage.setItem("therapistBookingId", userId)
    }
  }, [])

  const history = useHistory();

  useEffect(() => {
    if (channel) {
      onSendSignUpOtp();
    }
  }, [channel]);

  const onSendSignUpOtp = () => {
    if (loading === true) {
      return message.error("Processing Request");
    }

    setLoading(true);

    if (!phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }

    if (agreeTerms === false) {
      return message.error("Please Agree to Terms & Conditions");
    }

    let formattedPhoneNumber = formatPhoneNumber(
      phoneNumber,
      phoneNumberFormat
    );

    let data = {
      phoneNumber: formattedPhoneNumber,
      channel: channel,
      userType: userType,
    };

    ApiCalls.sendSignUpOtp(data)
      .then((response) => {
        if (response.data.status === 1) {
          setVerificationSend(true);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });

    setLoading(false);
  };

  const verifySignUpOtp = () => {
    if (!phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }

    let otpLength = otp.join("");
    if (otpLength.length !== 6) {
      return message.error("Please enter the valid OTP");
    }

    let formattedPhoneNumber = formatPhoneNumber(
      phoneNumber,
      phoneNumberFormat
    );

    let data = {
      phoneNumber: formattedPhoneNumber,
      code: otp.join(""),
    };

    if (userType === "patient") {
      ApiCalls.verifyAndRegisterPatient(data)
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem("userType", response.data?.data?.userType);
            message.success("Phone Number verified successfully");

            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);
            props.dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );

            if (response.data.data.page !== 0) {
              history.push("/user/base/home/dashboard");
            } else {
              history.push("/user/patient/update-profile");
            }
          }
        })
        .catch((error) => {
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    } else if (userType === "doctor") {
      ApiCalls.verifyAndRegisterProvider(data)
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem("userType", response.data?.data?.userType);
            message.success("Phone Number verified successfully");

            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);
            props.dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );

            if (response.data.data.page !== 0) {
              history.push("/user/base/home/dashboard");
            } else {
              history.push("/user/doctor/update-profile");
            }
          }
        })
        .catch((error) => {
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    }
  };

  const onResendOtp = () => {
    if (!phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }

    let formattedPhoneNumber = formatPhoneNumber(
      phoneNumber,
      phoneNumberFormat
    );

    let data = {
      phoneNumber: formattedPhoneNumber,
      channel: channel,
      userType: userType,
    };

    ApiCalls.sendSignUpOtp(data)
      .then((response) => {
        if (response.data.status === 1) {
          message.success(response.data.data);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  const onContinue = async () => {
    if (!phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }
    if (agreeTerms === false) {
      return message.error("Please Agree to Terms & Conditions");
    }

    try {
      const captchaVerified = await handleCaptcha();
      if (captchaVerified) {
        message.success("Captcha Completed");
        setSignupUsingOtp(true);
      } else {
        message.error("Captcha verification failed");
      }
    } catch (error) {
      message.error("An error occurred during captcha verification");
    }
  };

  const handleCaptcha = async () => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      const response = await ApiCalls.verifyCaptcha({ token: token });

      if (response.data.status === 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      message.error(
        error.response
          ? error?.response?.data?.data?.message
          : "Captcha Verification Failed",
        5
      );
      return false;
    }
  };

  return (
    <LoginSignupContainer>
      <Banner />
      <FormContainer>
        {verificationSend ? (
          <OtpVerification
            otp={otp}
            setOtp={setOtp}
            verifyLoginOtp={verifySignUpOtp}
            onResendOtp={onResendOtp}
          />
        ) : (
          <>
            <div className="FormTitle">
              <span className="primary">Let’s Get Started! ✨</span>
              <span className="secondary">
                Your mental healing starts here{" "}
              </span>
            </div>

            <div className="SelectorWrapper">
              <PatientTherapistSelector
                loginType={signUpType}
                onClickPatient={() => history.push("/user/patient/signup")}
                onClickTherapist={() => history.push("/user/doctor/signup")}
              />
            </div>

            <div className="InputWrapper">
              <span>
                {passwordInput
                  ? "Enter your Password"
                  : "Enter your Phone Number"}
              </span>
              <PhoneForm
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                setPhoneNumberFormat={setPhoneNumberFormat}
              />
            </div>

            <div className="AdditionalInfoWrapper">
              <div className="rememberMeWrapper">
                <input
                  type="checkbox"
                  checked={agreeTerms}
                  onChange={(e) => setAgreeTerms(e.target.checked)}
                />
                <span>I Agree to Telehealings Terms & Conditions.</span>
              </div>
            </div>

            <div className="ButtonsWrapper">
              {!signupUsingOtp && (
                <>
                  <Continue onFinish={onContinue} />
                  <SignInWithGoogle props={props} />
                </>
              )}

              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={recaptchSiteKey}
              />

              {signupUsingOtp && (
                <>
                  <SendViaWhatsapp
                    sendVerification={() => setChannel("whatsapp")}
                  />
                  <SendViaSMS sendVerification={() => setChannel("sms")} />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span
                      style={{
                        textDecoration: "underline",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#2C509F",
                        fontFamily: "Metropolis Bold",
                      }}
                      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                      onClick={() => setSignupUsingOtp(false)}
                    >
                      Back
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="signUpInfoWrapper">
              <span className="createAccount">Already have an account?</span>
              <span
                className="signUpLink"
                onClick={() => history.push(`/user/${userType}/login`)}
              >
                <a>Sign In</a>
              </span>
            </div>
          </>
        )}
      </FormContainer>
    </LoginSignupContainer>
  );
};
const mapStateToProps = (store) => {
  return {
    cities: store.lookups.cities,
    credentials: store.lookups.credentials,
  };
};

export default connect(mapStateToProps)(Signup);
