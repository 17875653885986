import styled from "styled-components";
import { Form } from "antd";

export const Wrapper = styled.div`
  label {
    color: rgba(153, 153, 153, 1);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.01em;
    font-family: "Schibsted Grotesk";
  }
`;

export const StyledFormItem = styled(Form.Item)`
  position: relative;
  margin: 4px 0 0 0;
  background-color: transparent;
  & input {
    height: 54px;
    line-height: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-family: "Schibsted Grotesk";
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
    padding: 10px 15px 10px 15px;
    background-color: #fbfbfb !important;
    border: ${(props) => (props.type2 ? "none" : "")};
    margin-left: ${({ name }) => (name === "password" ? "-10px" : "")};
    @media only screen and (max-width: 900px) {
      font-size: 12px;
      height: 50px;
    }
  }

  & #ant-file-upload-button {
    display: none;
    background-color: red;
  }

  & #password,
  #confirm {
    line-height: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-family: "Schibsted Grotesk";
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
    padding: 10px 15px 10px 15px;
    background-color: #fbfbfb !important;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }

  & .ant-form-item-required::before {
    content: "" !important;
  }

  & .ant-form-item-required::after {
    display: inline-block !important;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: "Schibsted Grotesk";
    line-height: 1;
    content: "*" !important;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }

  & .ant-form-item-label {
    padding: 0;
    position: absolute;
    top: 5px;
    left: ${(props) => (props.left || props.type2 ? "12px" : "42px")};
    z-index: 1;
    & label {
      font-size: 16px;
      font-family: "Schibsted Grotesk";
      color: rgba(153, 153, 153, 1);
      font-weight: 700;
      letter-spacing: -0.01em;
      &::after {
        content: "";
      }

      @media only screen and (max-width: 900px) {
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 900px) {
      left: 25px;
    }
  }

  & .ant-input-affix-wrapper {
    background-color: #fbfbfb !important;
    border-radius: 10px;
    padding: 0 10px;
    border: ${(props) => (props.type2 ? "none" : null)};
  }

  & .react-tel-input {
    height: 54px;

    & .form-control {
      height: 54px;
      width: 100%;
      line-height: 20px !important;
      border-radius: 8px;
      font-size: 16px;
      font-family: "Schibsted Grotesk";
      color: rgba(153, 153, 153, 1);
      padding: 10px 15px 10px 15px !important;
      background-color: #fbfbfb !important;
      border: ${(props) => (props.type2 ? "none !important" : "")};

      @media only screen and (max-width: 900px) {
        font-size: 14px;
      }
    }

    & .flag-dropdown {
      background-color: #fbfbfb !important;
      position: absolute;
      top: 0;
      right: 0;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      border: ${(props) => (props.type2 ? "none !important" : "")};

      & .country-list {
        position: absolute;
        left: -260px;
      }
    }
  }

  & .ant-input-prefix {
    color: #6b6b6b;
  }

  & .ant-picker {
    height: 67px;
    padding-left: 0px;
    border-radius: 10px !important;
    background-color: #fbfbfb !important;
    border: ${(props) => (props.type2 ? "none" : "")};
    & .anticon-calendar {
      font-size: 23px;
    }
  }
  & .ant-select-selection-search-input {
    padding: 25px 4px 0px !important;
    line-height: 20px !important;
    height: 100% !important;
  }

  & textarea {
    min-height: 120px;
    line-height: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-family: "Schibsted Grotesk";
    color: rgba(153, 153, 153, 1);
    padding: 10px 15px 10px 15px;
    background-color: #fbfbfb !important;
    border: ${(props) => (props.type2 ? "none" : "")};

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }
`;
