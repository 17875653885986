import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import jwt from "jwt-decode";
import {
  ConfirmedImageWrapper,
  StyledLoginSignupContainer,
  UpdateCardContent,
  UpdateContentWrapper,
} from "../UpdateProfilePatient/styledComponents";
import BackButtonWithTitle from "../../components/BackButtonWithTitle";
import Lottie from "lottie-react";
import animationData from "../../assets/lottie/Blue-Confirm.json";
import {
  CommonDMFontTextRegular,
  CommonSchibstedFontTextBold,
} from "../../utils/styledUtils";
import CustomArticleButton from "../../components/CustomArticleButton";
import { Form, message } from "antd";
import Name from "./ProfileInfo/Name";
import { useDocOnboardingApi } from "../../hooks/useDocOnboardingApi";
import moment from "moment";
import useLocationAndTimezone from "../../hooks/useLocationAndTimezone";
import { validatePassword } from "../../utils/commonFunctions";
import { UpdateDocScrollWrapper } from "./styledComponents";
import { useParams } from "react-router-dom";
import PendingLottie from "../../assets/lottie/PendingLottie.json";

export const WeekData = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const UpdateProfileDoctor = () => {
  const { action } = useParams();
  const { fetchLocationAndTimezone } = useLocationAndTimezone();
  const [form] = Form.useForm();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(
    location.state ? location.state.page : 1
  );
  const history = useHistory();
  const [isUpdated, setIsUpdated] = useState(false);
  const [payload, setPayload] = useState({
    gender: "Male",
    hiddenFields: ["dob", "timeZone"],
  });
  const {
    updateDocInfo,
    updateDoctorWorkProfileInfo,
    fetchWorkProfile,
    docProfileDetails,
    loading,
    updateDocExtraInfo,
  } = useDocOnboardingApi();
  const [oauthData, setOauthData] = useState(null);
  const [isExpEduUpdated, setIsExpEduUpdated] = useState(false);
  const bankFormRef = useRef(null);
  const [servicePayload, setServicePayload] = useState({
    languagesFilterData: [],
    specializationFilterData: [],
    professionalTitle: "",
  });
  const [timeSelected, setTimeSelected] = useState("");
  const [isDetailUpdated, setIsDetailUpdated] = useState(false);
  const [availability, setAvailability] = useState(
    WeekData.reduce((acc, day) => {
      acc[day] = {
        isAvailable: false,
        hasBreak: false,
        startTime: null,
        endTime: null,
        breakStartTime: null,
        breakEndTime: null,
      };
      return acc;
    }, {})
  );
  const [pricePayload, setPricePayload] = useState({
    currency: "",
    consultationFee: "",
  });
  const onFinish = (val) => {
    let data = {};
    let address = {};
    data.dob = val.dob ? moment(val.dob).toISOString() : undefined;
    data.gender = payload?.gender;
    data.aboutYou = val.aboutYou;
    data.name = val.name;
    address.latitude = `${payload.lat}° N`;
    address.longitude = `${payload.lng}° E`;
    data.address = address;
    data.hiddenFields = payload.hiddenFields;

    if (!oauthData) {
      data.email = val.email;
      data.password = val.password;
    }
    if (val.password && val.confirm) {
      const error = validatePassword(val.password, val.confirm);
      if (error) {
        message.error(error);
        return;
      }
    }
    if (!payload?.gender) {
      message.error("Select gender to continue", 5);
      return;
    }
    updateDocInfo(data, () => {
      setCurrentPage((prev) => prev + 1);
      setIsDetailUpdated((prevData) => !prevData);
    });
  };
  const serviceSubmit = () => {
    if (
      servicePayload.languagesFilterData.length > 0 &&
      servicePayload.specializationFilterData.length > 0
    ) {
      let data = {
        professionalTitle: servicePayload.professionalTitle,
        specialization: Array.isArray(servicePayload.specializationFilterData)
          ? [...servicePayload.specializationFilterData]
          : [],
        languagesKnown: Array.isArray(servicePayload.languagesFilterData)
          ? [...servicePayload.languagesFilterData]
          : [],
      };
      updateDoctorWorkProfileInfo(data, () => {
        setCurrentPage((prev) => prev + 1);
        setIsDetailUpdated((prevData) => !prevData);
      });
    } else if (servicePayload.professionalTitle === "") {
      message.error("Please enter professional title to continue.", 5);
    } else {
      message.error("Please select at least one language  to continue.", 5);
    }
  };
  const formatTime = (time) => {
    return time ? time.toISOString() : null;
  };
  const availabilitySubmit = () => {
    if (timeSelected.length === 0) {
      message.error("Please select a period for consultation", 5);
      return;
    }
    const isAvailable = Object.values(availability).some(
      (day) => day.startTime !== null && day.endTime !== null
    );
    if (!isAvailable) {
      message.error("Please select the available days to continue", 5);
      return;
    }
    const timeSlot = Object.keys(availability)
      .filter((day) => availability[day].isAvailable)
      .map((day) => {
        const { startTime, endTime, breakStartTime, breakEndTime, hasBreak } =
          availability[day];

        return {
          day,
          startTime: formatTime(startTime),
          endTime: formatTime(endTime),
          addBreak: hasBreak
            ? [
                {
                  startTime: formatTime(breakStartTime),
                  endTime: formatTime(breakEndTime),
                },
              ]
            : [],
        };
      });
    const payload = {
      timeSlot: timeSlot,
      specialTime: [],
      offDays: [],
      consultationDuration:
        timeSelected === "2hr" ? 120 : timeSelected === "1hr" ? 60 : 30,
    };
    updateDoctorWorkProfileInfo(payload, () => {
      setCurrentPage((prev) => prev + 1);
      setIsDetailUpdated((prevData) => !prevData);
    });
  };
  const onSpecPricingSubmit = () => {
    if (!pricePayload.consultationFee)
      message.error("Please fill the price to continue", 5);
    else {
      let payload = {
        consultationFee: Number(pricePayload.consultationFee),
      };
      updateDoctorWorkProfileInfo(payload, () => {
        if (
          docProfileDetails.status === "pending" ||
          docProfileDetails.status === "waiting for approval"
        ) {
          history.push("/user/doctor/update-profile/pending");
          setIsDetailUpdated((prevData) => !prevData);
        } else {
          history.push("/user/base/home/dashboard");
        }
      });
    }
  };
  const totalExperienceUpdate = () => {
    if (isExpEduUpdated) {
      if (
        servicePayload.totalWorkExperience !== "" ||
        servicePayload.totalWorkExperienc !== undefined
      ) {
        updateDocExtraInfo(
          {
            totalWorkExperience: servicePayload.totalWorkExperience,
          },
          null,
          () => {
            setCurrentPage((prev) => prev + 1);
            setIsDetailUpdated((prevData) => !prevData);
          }
        );
      } else {
        setCurrentPage((prev) => prev + 1);
        setIsDetailUpdated((prevData) => !prevData);
      }
    } else {
      message.error(
        "Please update your education and experience to continue",
        5
      );
    }
  };
  const onContinue = async () => {
    try {
      switch (currentPage) {
        case 1:
          await form.submit();
          break;
        case 2:
          await totalExperienceUpdate();
          break;
        case 3:
          await serviceSubmit();
          break;
        case 4:
          await availabilitySubmit();
          break;
        case 5:
          if (bankFormRef.current) {
            await bankFormRef.current.submitForm();
          } else {
            message.error("Form reference is not available", 5);
          }
          break;
        case 6:
          await onSpecPricingSubmit();
          break;
        default:
          message.error("Invalid page", 5);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    try {
      const auth = jwt(localStorage.getItem("authToken"));
      if (auth.exp * 1000 < Date.now()) {
        history.push("/user/patient/signup");
      }
    } catch (e) {
      history.push("/user/patient/signup");
    }
  }, []);

  useEffect(() => {
    if (currentPage === 6) {
      window.fbq("track", "CompleteRegistration");
    }
  }, [currentPage]);

  useEffect(() => {
    let page = localStorage.getItem("pageInfo");
    let pageInt = parseInt(page);
    if (pageInt) {
      setCurrentPage(pageInt + 1);
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          if (newLocation) {
            fetchLocationAndTimezone(
              newLocation.lat,
              newLocation.lng,
              (data) => {
                setPayload((prevData) => ({
                  ...prevData,
                  timeZone: data.timezone,
                  globalTimeZone: data.globalTimeZone,
                  lat: newLocation.lat,
                  lng: newLocation.lng,
                }));
              }
            );
          }
        },
        () => {
          const defaultLocation = { lat: 22.5726, lng: 88.3639 };
          fetchLocationAndTimezone(
            defaultLocation.lat,
            defaultLocation.lng,
            (data) => {
              setPayload((prevData) => ({
                ...prevData,
                timeZone: data.timezone,
                globalTimeZone: data.globalTimeZone,
                lat: defaultLocation.lat,
                lng: defaultLocation.lng,
              }));
            }
          );
        }
      );
    }
    const storedData = localStorage.getItem("oauthData");
    if (storedData) {
      setOauthData(JSON.parse(storedData));
    }
  }, []);
  useEffect(() => {
    if (oauthData) form.setFieldsValue({ email: oauthData?.email });
  }, [oauthData]);
  useEffect(() => {
    setPayload((prevData) => {
      const currentHiddenFields = prevData.hiddenFields || [];
      let updatedHiddenFields;
      if (!payload.showDob) {
        updatedHiddenFields = currentHiddenFields.includes("dob")
          ? currentHiddenFields
          : [...currentHiddenFields, "dob"];
      } else {
        updatedHiddenFields = currentHiddenFields.filter(
          (field) => field !== "dob"
        );
      }
      return {
        ...prevData,
        hiddenFields: updatedHiddenFields,
      };
    });
  }, [payload.showDob]);
  useEffect(() => {
    fetchWorkProfile();
  }, [isDetailUpdated]);
  return (
    <>
      <StyledLoginSignupContainer>
        <BackButtonWithTitle
          title={"Basic Details"}
          isButtonRequired
          onClick={() => {
            if (currentPage !== 1) {
              setCurrentPage(currentPage - 1);
            } else {
              window.history.back();
            }
          }}
        />
        <UpdateDocScrollWrapper
          className={action === "pending" ? "PendingWrapper" : ""}
        >
          {action && action === "pending" ? (
            <UpdateCardContent className="PendingCardStyle">
              <ConfirmedImageWrapper className="ConfirmedImageWrapper">
                <Lottie animationData={PendingLottie} loop={true} />
              </ConfirmedImageWrapper>
              <CommonSchibstedFontTextBold
                fontSize={"28px"}
                className="Title-Style"
              >
                Pending Approval
              </CommonSchibstedFontTextBold>
              <CommonDMFontTextRegular
                className="Sub-Title-Style"
                fontWeight={300}
                style={{ textAlign: "center" }}
              >
                Your profile is pending for approval, please check back again
                soon.
              </CommonDMFontTextRegular>
              <CustomArticleButton
                buttonText={"Okay"}
                onClick={() => {
                  history.push("/user/doctor/login");
                }}
                className={"Dashboard-Redirect-Btn-Style"}
              />
            </UpdateCardContent>
          ) : (
            <UpdateContentWrapper
              style={{ height: "auto" }}
              className={`${
                isUpdated ? "Updated-Wrapper-Style" : "Update-Content-Wrapper"
              } pageInfo`}
            >
              {isUpdated && (
                <ConfirmedImageWrapper>
                  <Lottie animationData={animationData} loop={true} />
                </ConfirmedImageWrapper>
              )}
              <CommonSchibstedFontTextBold
                fontSize={isUpdated ? "28px" : "32px"}
                className="Title-Style"
              >
                {isUpdated
                  ? "You’re All Set!"
                  : "Let's get to know you properly"}
              </CommonSchibstedFontTextBold>
              <CommonDMFontTextRegular
                className="Sub-Title-Style"
                fontWeight={isUpdated ? 300 : 500}
                style={{ textAlign: "center" }}
              >
                {isUpdated
                  ? "Your profile has been created successfully"
                  : `Answer a few quick questions so we can tailor your experience to fit
            your needs and preferences.`}
              </CommonDMFontTextRegular>
              {isUpdated ? (
                <CustomArticleButton
                  buttonText={"finish"}
                  onClick={() => {
                    history.push("/user/base/home/dashboard");
                  }}
                  className={"Dashboard-Redirect-Btn-Style"}
                />
              ) : (
                <UpdateCardContent className="UpdateCardContent">
                  <div
                    className="Progress-Wrapper"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      flexWrap: "wrap",
                      padding: " 0px 10px",
                    }}
                  >
                    <BackButtonWithTitle
                      stroke={"rgba(255, 255, 255, 1)"}
                      backgroundColor={
                        currentPage > 1
                          ? "rgba(66, 88, 137, 1)"
                          : "rgba(204, 204, 204, 1)"
                      }
                      isButtonRequired
                      onClick={() => {
                        if (currentPage !== 1) {
                          setCurrentPage(currentPage - 1);
                        }
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        width: "calc(100% - 50px)",
                      }}
                    >
                      {localStorage.getItem("oauthData")
                        ? [1, 2, 3].map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  width: "100px",
                                  maxWidth: "calc(20% - 10px)",
                                  height: "10px",
                                  borderRadius: "100px",
                                  backgroundColor:
                                    currentPage >= index + 1
                                      ? "#74ABE8"
                                      : "#E3E3E3",
                                  flexGrow: 1,
                                  flexShrink: 1,
                                  flexBasis: "0%",
                                }}
                              ></div>
                            );
                          })
                        : [1, 2, 3, 4, 5, 6].map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  width: "100px",
                                  maxWidth: "calc(20% - 10px)",
                                  height: "6px",
                                  borderRadius: "100px",
                                  backgroundColor:
                                    currentPage >= index + 1
                                      ? "rgba(66, 88, 137, 1)"
                                      : "rgba(255, 255, 255, 1)",
                                  flexGrow: 1,
                                  flexShrink: 1,
                                  flexBasis: "0%",
                                }}
                              ></div>
                            );
                          })}
                    </div>
                  </div>
                  <div>
                    {currentPage && (
                      <Name
                        form={form}
                        currentPage={currentPage}
                        payload={payload}
                        setPayload={setPayload}
                        setCurrentPage={setCurrentPage}
                        onFinish={onFinish}
                        oauthData={oauthData}
                        setIsExpEduUpdated={setIsExpEduUpdated}
                        setIsUpdated={setIsUpdated}
                        servicePayload={servicePayload}
                        setServicePayload={setServicePayload}
                        setIsDetailUpdated={setIsDetailUpdated}
                        docProfileDetails={docProfileDetails}
                        loading={loading}
                        setAvailability={setAvailability}
                        availability={availability}
                        ref={bankFormRef}
                        setTimeSelected={setTimeSelected}
                        timeSelected={timeSelected}
                        setPricePayload={setPricePayload}
                        pricePayload={pricePayload}
                      />
                    )}
                  </div>
                  {currentPage === 5 && (
                    <CustomArticleButton
                      closeBtn
                      buttonText={"skip for now"}
                      onClick={() => {
                        setCurrentPage((prev) => prev + 1);
                      }}
                      style={{ width: "100%", height: "48px" }}
                    />
                  )}
                  <CustomArticleButton
                    buttonText={"CONTINUE"}
                    onClick={() => onContinue()}
                    style={{ width: "100%", height: "48px" }}
                  />
                </UpdateCardContent>
              )}
            </UpdateContentWrapper>
          )}
        </UpdateDocScrollWrapper>
      </StyledLoginSignupContainer>
    </>
  );
};

export default UpdateProfileDoctor;
