import { Typography } from "antd";
import styled from "styled-components";

const CustomText = styled(Typography)`
  font-family: ${(props) => props.fontFamily || "Montserrat"};
  color: ${(props) => {
    switch (props.color) {
      case "maroon":
        return "#B3261E";
      case "grey":
        return "#7C7C7C";
      case "blue":
        return "#2959A8";
      case "green":
        return "#2BA608";
      case "black":
        return "#000000";
      case "darkgrey":
        return "#6d6d6d";
      default:
        return props.color;
    }
  }};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.fontWeight};
`;

export default CustomText;
