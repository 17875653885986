import React, { useState, useRef, useEffect } from "react";
import { TextWrapper, Wrapper } from "./styledComponent";
import { CommonDMFontTextSmall } from "../../utils/styledUtils";

const OtpInput = ({
  numInputs = 4,
  onChange,
  value,
  className,
  inputStyle,
  onResendOtp,
  invalidText,
  isOtpInvalid,
}) => {
  const [otp, setOtp] = useState(value.split(""));
  const inputsRef = useRef([]);
  const [timer, setTimer] = useState(60);

  const handleChange = (value, index) => {
    if (/^[0-9]?$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);
      onChange(updatedOtp.join(""));
      if (value && index < numInputs - 1) {
        if (inputsRef.current[index + 1]) {
          inputsRef.current[index + 1].focus();
        }
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      if (inputsRef.current[index - 1]) {
        inputsRef.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData("text")
      .slice(0, numInputs)
      .split("");
    const updatedOtp = Array(numInputs)
      .fill("")
      .map((_, i) => pastedData[i] || otp[i]);
    setOtp(updatedOtp);
    onChange(updatedOtp.join(""));
  };
  const handleResendClick = () => {
    if (timer === 0 && onResendOtp) {
      onResendOtp();
      setTimer(60);
    }
  };
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);
  return (
    <Wrapper className={className} onPaste={handlePaste}>
      <div className="otp-container">
        {Array.from({ length: numInputs }).map((_, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={otp[index] || ""}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(el) => (inputsRef.current[index] = el)}
            style={{
              ...inputStyle,
            }}
          />
        ))}
      </div>
      {isOtpInvalid && (
        <CommonDMFontTextSmall
          textColor={"rgba(194, 70, 70, 1)"}
          className="Invalid-Text-Style"
        >
          {invalidText}
        </CommonDMFontTextSmall>
      )}
      <TextWrapper>
        <p>
          Didn’t Receive Code?{" "}
          <span
            onClick={handleResendClick}
            style={{ cursor: timer === 0 ? "pointer" : "not-allowed" }}
          >
            Resend {timer > 0 ? `(${timer}s)` : ""}
          </span>
        </p>
      </TextWrapper>
    </Wrapper>
  );
};

export default OtpInput;
