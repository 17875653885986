import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, DatePicker, message } from "antd";
import moment from "moment";
import {
  FormHeading,
  WelcomeText,
  StyledFormItem,
  LogoContainer,
  FormWrapper,
} from "../signup/styledComponents";
import { Main, PhotoWrapper, PhotoContainer } from "./styledComponents";
import TextLogo from "../../assets/icons/Logo/TextLogo";
import TelehealingsLogo from "../../assets/images/telehealings_logo.png";
import UploadPhoto from "./UploadPhoto";
import PrimaryButton from "../../components/PrimaryButton/index";
import { useHistory } from "react-router-dom";
import ApiCalls from "../../apis/ApiCalls.js";
import jwt from "jwt-decode";
import Name from "./ProfileInfo/Name.jsx";
import ContinueButton from "./ProfileInfo/ContinueButton.jsx";
import Gender from "./ProfileInfo/Gender.jsx";
import Age from "./ProfileInfo/Age.jsx";
import Email from "./ProfileInfo/Email.jsx";
import Password from "./ProfileInfo/Password.jsx";
import { emailValidation } from "../../utils/validation.js";
import CustomButton from "../Utils/CustomButton.jsx";

const UpdateProfilePatient = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState();
  const [email, setEmail] = useState();
  const [skipEmail, setSkipEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [page, setPage] = useState(1);
  let oauthData;

  const onContinue = async () => {
    try {
      switch (page) {
        case 1:
          await validateAndUpdateName();
          break;
        case 2:
          await validateAndUpdateGender();
          break;
        case 3:
          await validateAndUpdateAge();
          break;
        case 4:
          await validateAndUpdateEmail();
          break;
        case 5:
          await validateAndUpdatePassword();
          break;
        default:
          message.error("Invalid page");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const validateAndUpdateName = async () => {
    if (name.trim().length < 2) {
      message.error("Please provide a valid name (at least 2 characters)");
    } else {
      await updateProfile({ name: name.trim() });
      setPage(2);
    }
  };

  const validateAndUpdateGender = async () => {
    if (!["male", "female", "other"].includes(gender.toLowerCase())) {
      message.error("Please select a valid gender");
    } else {
      await updateProfile({ gender: gender.toLowerCase() });
      setPage(3);
    }
  };

  const validateAndUpdateAge = async () => {
    if (!dob) {
      message.error("Please select a valid birth date");
      return;
    }

    const today = moment();
    const dobMoment = moment(dob);

    if (!dobMoment.isValid()) {
      message.error("Invalid date format");
      return;
    }

    const age = today.diff(dobMoment, "years");

    if (age < 18 || age > 120) {
      message.error("Age must be between 18 and 120 years");
    } else {
      try {
        let formattedDob = dob.toISOString();
        await updateProfile({ dob: formattedDob });
        localStorage.getItem("oauthData") &&
          history.push("/user/base/home/dashboard") &&
          window.fbq("track", "CompleteRegistration");
        !localStorage.getItem("oauthData") && setPage(4);
      } catch (error) {
        message.error("Failed to update profile");
        console.error(error);
      }
    }
  };

  const validateAndUpdateEmail = async () => {
    let isValidEmail = emailValidation(email);
    if (isValidEmail === "Invalid email") {
      message.error("Please enter a valid email address");
    } else {
      await updateProfile({ email: email.trim() });
      setPage(5);
    }
  };

  const validateAndUpdatePassword = async () => {
    if (password !== confirmPassword) {
      message.error("Passwords do not match");
    } else if (password.length < 8) {
      message.error("Password should be at least 8 characters long");
    } else if (
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[0-9]/.test(password)
    ) {
      message.error(
        "Password must contain at least one uppercase letter, one lowercase letter, and one number"
      );
    } else {
      await updateProfile({ password });
      history.push("/user/base/home/dashboard");
      window.fbq("track", "CompleteRegistration");
    }
  };

  const updateProfile = async (data) => {
    const response = await ApiCalls.updatePatientProfile(data);
    if (response.data.status !== 1) {
      message.error(response.data.message || "Failed to update profile");
    }
  };

  useEffect(() => {
    if (skipEmail === true) {
      setPage(page + 1);
    }
  }, [skipEmail]);

  useEffect(() => {
    try {
      const auth = jwt(localStorage.getItem("authToken"));
      if (auth.exp * 1000 < Date.now()) {
        history.push("/user/patient/login");
      }
    } catch (e) {
      history.push("/user/patient/login");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let page = localStorage.getItem("pageInfo");
    let pageInt = parseInt(page);
    if (pageInt) {
      setPage(pageInt + 1);
    }

    oauthData = localStorage.getItem("oauthData");
    if (oauthData) {
      oauthData = JSON.parse(oauthData);
      setName(oauthData.name);
      setEmail(oauthData.email);
    }
  }, []);

  return (
    <>
      <div
        className="Main"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 20px",
            width: "100%",
          }}
        >
          <img
            className="logo"
            src={TelehealingsLogo}
            style={{ width: "196px", height: "56px" }}
          ></img>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.6663 8L17.9997 20.6667L11.333 14L1.33301 24"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M22.667 8H30.667V16"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "5%",
            gap: "24px",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "1096px",
            margin: "0 auto",
          }}
          className="pageInfo"
        >
          <span
            style={{
              fontSize: "clamp(18px, 4vw, 24px)",
              fontWeight: "400",
              color: "#262626",
              fontFamily: "Metropolis Black, sans-serif",
              textAlign: "center",
            }}
          >
            Let's get to know you properly
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <div
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              onClick={() => {
                if (page !== 1) {
                  setPage(page - 1);
                }
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.3337 16H6.66699"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.0003 25.3332L6.66699 15.9998L16.0003 6.6665"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
                width: "75%",
              }}
            >
              {localStorage.getItem("oauthData")
                ? [1, 2, 3].map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100px",
                          maxWidth: "calc(20% - 10px)",
                          height: "10px",
                          borderRadius: "100px",
                          backgroundColor:
                            page >= index + 1 ? "#74ABE8" : "#E3E3E3",
                          flexGrow: 1,
                          flexShrink: 1,
                          flexBasis: "0%",
                        }}
                      ></div>
                    );
                  })
                : [1, 2, 3, 4, 5].map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100px",
                          maxWidth: "calc(20% - 10px)",
                          height: "10px",
                          borderRadius: "100px",
                          backgroundColor:
                            page >= index + 1 ? "#74ABE8" : "#E3E3E3",
                          flexGrow: 1,
                          flexShrink: 1,
                          flexBasis: "0%",
                        }}
                      ></div>
                    );
                  })}
            </div>

            <span
              style={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#262626",
                fontFamily: "Metropolis Bold",
              }}
            >
              {page + "/" + (localStorage.getItem("oauthData") ? 3 : 5)}
            </span>
          </div>
          <div>
            {page === 1 && <Name name={name} setName={setName} />}
            {page === 2 && <Gender gender={gender} setGender={setGender} />}
            {page === 3 && <Age dob={dob} setDob={setDob} />}
            {page === 4 && (
              <Email
                email={email}
                setEmail={setEmail}
                skipEmail={() => {
                  setSkipEmail(true);
                }}
              />
            )}
            {page === 5 && (
              <Password
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
              />
            )}
          </div>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <CustomButton
              buttonName={"Continue"}
              onClick={() => onContinue()}
              customStyles={{ width: "100%", maxWidth: "386px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProfilePatient;
