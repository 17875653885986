import React, { useEffect, useState } from "react";
import {
  DoneButtonContainer,
} from "../BookAppointment/styledComponents";
import PrimaryButton from "../../components/PrimaryButton/index";
import { useHistory } from "react-router-dom";
import PaymentSuccess from "../../assets/images/CoporatePatientModule/success.svg";
import { SuccessMessage, DoctorName, Confirmtext, BookingValue } from "./styledComponents"
import {
  PageHeader,
  Title
} from "../DoctorProfile/styledComponents";
import Profile from "../../assets/images/patientModuleIcons/user_blue.svg";
import Calendar from "../../assets/images/patientModuleIcons/calendar_blue.svg";
import Clock from "../../assets/images/patientModuleIcons/clock_blue.svg";
import { useParams } from 'react-router-dom';
import ApiCalls from "../../apis/ApiCalls";
const moment = require('moment');

const SuccessInfo = ({
  doctorData,
  time,
  setShowConfirmationModal,
  setEditTime,
  priceDetails,
  paymentStatus
}) => {
  const [doctorName, setDoctorName] = useState("")
  const [scheduleDetails, setScheduleDetails] = useState([])
  const { id, transactionId } = useParams();

  useEffect(() => {
    getScheduleInfo()
    getPaymentStatus(transactionId)
  }, [])

  const getDoctorData = (dId) => {
    ApiCalls.getDoctorDetails(dId)
      .then((response) => {
        if (response.data.status) {
          setDoctorName(response.data.data.name)
        }
      })
      .catch((error) => {
        console.log("error.....", error)
      })
  }
  const getScheduleInfo = () => {
    ApiCalls.getScheduleDetails(id)
      .then((response) => {
        if (response.data) {
          setScheduleDetails(response.data.data)
          getDoctorData(response.data.data.providerId)
        }
      })
      .catch((error) => {
        console.log("error....", error)
      });
  };
  const getPaymentStatus = (id) => {
    ApiCalls.verifyStripePayment(id)
      .then((response) => {
        if (response.data.status) {
          console.log("response....", response)
        }
      })
      .catch((error) => { console.log("error....", error) }
      );
  };

  const displayName = doctorName ? doctorName : (doctorData?.name || doctorData?.fullName || `${doctorData?.firstName} ${doctorData?.lastName}`);
  const urlObject = new URL(window.location.href);
  const lastSegment = urlObject.pathname.split('/').pop();
  localStorage.setItem("appointmentId", lastSegment)
  const history = useHistory();

  const formatDate = (isoDateString) => {
    const date = moment(isoDateString);
    const formattedDate = date.format('Do MMMM, YYYY');
    return formattedDate;
  };

  const formatTime = (timeString) => {
    const time = moment(timeString);
    const formattedTime = time.format('h:mm A');
    return formattedTime;
  };

  const handleConfirmation = () => {
    localStorage.removeItem("paymentMethod")
    localStorage.removeItem("paymentData")
    // setShowConfirmationModal(false);
    history.push("/user/base/home/appointments");
  }

  return (
    <>
      <PageHeader>
        <Title style={{ fontFamily: 'Metropolis Black', fontSize: '24px', fontWeight: '400' }}>Payment & Booking Successful</Title>
      </PageHeader>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "20px",
        }}
      >
        <img
          src={PaymentSuccess}
          height="200px"
          weight="200px"
          alt=""
        />
      </div>
      <SuccessMessage type2={true} style={{ fontFamily: 'Metropolis Regular', fontSize: '28px', fontWeight: '700' }}>
        Payment & Booking Successful!
      </SuccessMessage>
      <Confirmtext type2={true} style={{ fontFamily: 'Metropolis Regular', fontSize: '16px', fontWeight: '400' }}>
        You have successfully booked appointment with
      </Confirmtext>
      <DoctorName type2={true} style={{ fontFamily: 'Metropolis Regular', fontSize: '20px', fontWeight: '700' }}>
        {displayName}
      </DoctorName>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <span>
          <img src={Profile} />
          <BookingValue>{displayName}</BookingValue>
        </span>
        <span>
          <img src={Calendar} />
          <BookingValue>{formatDate(scheduleDetails?.startTime) || formatDate(time?.startTime)}</BookingValue>
        </span>
        <span>
          <img src={Clock} />
          <BookingValue>{formatTime(scheduleDetails?.startTime) || formatTime(time?.startTime)}</BookingValue>
        </span>
      </div>
      <DoneButtonContainer>
        <PrimaryButton
          label="Done"
          onClick={() => handleConfirmation()}
        />
      </DoneButtonContainer>
    </>
  );
};

export default SuccessInfo;
