import { useState } from "react";
import ApiCalls from "../../apis/ApiCalls";
const useBlogApi = () => {
  const [loading, setLoading] = useState(false);
  const [blogsData, setBlogsData] = useState([]);

  const fetchBlogData = (postSuccessFunction = () => {}) => {
    setLoading(true);
    ApiCalls.getArticles()
      .then(function (response) {
        if (postSuccessFunction) {
          setBlogsData(response.data);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction(response.data);
          }
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.error("Error fetching data: ", error);
      });
  };
  return { blogsData, fetchBlogData, loading };
};

export default useBlogApi;
