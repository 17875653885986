import { useState } from "react";
import ApiCalls from "../apis/ApiCalls";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import storage from "../storage";
import reduxActions from "../redux-store/actions";
import { useDispatch } from "react-redux";

export const useLoginApi = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const sendLoginOtp = (
    data,
    postSuccessFunction = () => {},
    postErrorFunction = () => {}
  ) => {
    setLoading(true);
    ApiCalls.sendLoginOtp(data)
      .then((response) => {
        if (response.data.status === 1) {
          message.success(response?.data?.data, 5);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        if (
          error?.response?.data?.data?.message ===
          "User does not exist. Please Sign Up"
        ) {
          if (typeof postErrorFunction === "function") {
            postErrorFunction({ email: true, password: false });
          }
        } else {
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
          if (typeof postErrorFunction === "function") {
            postErrorFunction({ email: false, password: true });
          }
        }
        setLoading(false);
      });
  };
  const onPasswordLogin = (values, userType, postErrorFunction = () => {}) => {
    if (userType === "patient") {
      ApiCalls.patientLogin(values)
        .then((response) => {
          if (response.data.status === 1) {
            setLoading(false);
            localStorage.setItem("userType", response.data?.data?.userType);
            message.success("Logged in successfully");

            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);
            storage.pageInfo.setItem(response.data.data.page);
            dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );
            if (response.data.data.status === "active") {
              history.push("/user/base/home/dashboard");
            } else {
              history.push("/user/patient/update-profile");
            }
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.data?.message ===
            "User with the email address or phone number not found"
          ) {
            if (typeof postErrorFunction === "function") {
              postErrorFunction({ email: true, password: false });
            }
          } else if (
            error?.response?.data?.data?.message ===
            "Credentials of the user doesn't match"
          ) {
            if (typeof postErrorFunction === "function") {
              postErrorFunction({ email: false, password: true });
            }
          } else {
            message.error(
              error.response
                ? error?.response?.data?.data?.message
                : "No Response From Server",
              5
            );
            if (typeof postErrorFunction === "function") {
              postErrorFunction({ email: false, password: false });
            }
          }
          setLoading(false);
        });
    } else if (userType === "doctor") {
      ApiCalls.doctorLogin(values)
        .then((response) => {
          if (response.data.status === 1) {
            setLoading(false);
            message.success("Logged in successfully");
            localStorage.setItem("userType", response.data?.data?.userType);
            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);
            storage.pageInfo.setItem(response.data.data.page);

            dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );

            if (response.data.data.status === "active") {
              history.push("/user/base/home/dashboard");
            } else {
              history.push({
                pathname: "/user/doctor/update-profile",
                state: { page: response.data.data.page },
              });
            }
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    }
  };
  const verifyLoginOtp = (data, userType) => {
    if (userType === "patient") {
      ApiCalls.verifyLoginOtp(data)
        .then((response) => {
          if (response.data.status === 1) {
            setLoading(false);
            localStorage.setItem("userType", response.data?.data?.userType);
            message.success("Logged in successfully");

            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);
            storage.pageInfo.setItem(response.data.data.page);
            dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );
            if (response.data.data.status === "active") {
              history.push("/user/base/home/dashboard");
            } else {
              history.push("/user/patient/update-profile");
            }
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    } else if (userType === "doctor") {
      ApiCalls.verifyProviderLoginOtp(data)
        .then((response) => {
          if (response.data.status === 1) {
            setLoading(false);
            localStorage.setItem("userType", response.data?.data?.userType);
            message.success("Logged in successfully");

            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);
            storage.pageInfo.setItem(response.data.data.page);
            dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );
            if (response.data.data.status === "active") {
              history.push("/user/base/home/dashboard");
            } else {
              history.push("/user/doctor/update-profile");
            }
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (
            error?.response?.data?.data?.message ===
            "Phone Number is not verified"
          ) {
            history.push("/user/doctor/reverify");
          }
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    }
  };
  const sendSignUpOtp = (
    data,
    postSuccessFunction = () => {},
    postErrorFunction = () => {}
  ) => {
    setLoading(true);
    ApiCalls.sendSignUpOtp(data)
      .then((response) => {
        if (response.data.status === 1) {
          message.success(response?.data?.data, 5);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction(data?.data);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        if (
          error?.response?.data?.data?.message ===
          "User with the phone number already exists"
        ) {
          if (typeof postErrorFunction === "function") {
            postErrorFunction({ email: true, password: false });
          }
        } else {
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
          if (typeof postErrorFunction === "function") {
            postErrorFunction({ email: false, password: false });
          }
        }
        setLoading(false);
      });
  };
  const verifySignUpOtp = (data, userType, postErrorFunction = () => {}) => {
    setLoading(true);
    if (userType === "patient") {
      ApiCalls.verifyAndRegisterPatient(data)
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem("userType", response.data?.data?.userType);
            message.success("Phone Number verified successfully");

            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);
            dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );

            if (response.data.data.status === "active") {
              history.push("/user/base/home/dashboard");
            } else {
              history.push("/user/patient/update-profile");
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          if (
            error?.response?.data?.data?.message === "Invalid Verification Code"
          ) {
            if (typeof postErrorFunction === "function") {
              postErrorFunction({ email: true, password: false });
            }
          } else {
            message.error(
              error.response
                ? error?.response?.data?.data?.message
                : "No Response From Server",
              5
            );
            if (typeof postErrorFunction === "function") {
              postErrorFunction({ email: false, password: false });
            }
          }
          setLoading(false);
        });
    } else if (userType === "doctor") {
      ApiCalls.verifyAndRegisterProvider(data)
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem("userType", response.data?.data?.userType);
            message.success("Phone Number verified successfully");

            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);
            dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );
            if (response.data.data.status === "active") {
              history.push("/user/base/home/dashboard");
            } else {
              history.push("/user/doctor/update-profile");
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          if (
            error?.response?.data?.data?.message === "Invalid Verification Code"
          ) {
            if (typeof postErrorFunction === "function") {
              postErrorFunction({ email: true, password: false });
            }
          } else {
            message.error(
              error.response
                ? error?.response?.data?.data?.message
                : "No Response From Server",
              5
            );
            if (typeof postErrorFunction === "function") {
              postErrorFunction({ email: false, password: false });
            }
          }
          setLoading(false);
        });
    }
  };

  const googleSignup = (userType, credentialResponse) => {
    const apiCall =
      userType === "patient"
        ? ApiCalls.googleAuth
        : ApiCalls.googleProviderAuth;

    apiCall({ token: credentialResponse.credential })
      .then((response) => {
        if (response.data.status) {
          localStorage.setItem("userType", response.data?.data?.userType);

          storage.authToken.setItem(response.data.data.authToken);
          storage.accountStatus.setItem(response.data.data.status);

          if (response.data.data.oauthData) {
            storage.oauthData.setItem(response.data.data.oauthData);
          }
          if (response.data.data.page) {
            storage.pageInfo.setItem(response.data.data.page);
          }

          dispatch(
            reduxActions.authActions.setAuthToken(response.data.data.authToken)
          );

          message.success("Google OAuth Successful");

          if (
            response.data.data.oauthData &&
            [3, 4, 5].includes(response.data.data.page)
          ) {
            history.push("/user/base/home/dashboard");
          } else {
            history.push(
              userType === "patient"
                ? "/user/patient/update-profile"
                : "/user/doctor/update-profile"
            );
          }
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const appleLoginSuccess = async (userType, authResponse) => {
    try {
      const apiCall =
        userType === "patient"
          ? ApiCalls.appleAuth
          : ApiCalls.appleProviderAuth;

      const response = await apiCall({
        token: authResponse.authorization.id_token,
        platform: "web",
      });
      if (response.data.status) {
        localStorage.setItem("userType", response.data?.data?.userType);

        storage.authToken.setItem(response.data.data.authToken);
        storage.accountStatus.setItem(response.data.data.status);

        if (response.data.data.oauthData) {
          storage.oauthData.setItem(response.data.data.oauthData);
        }
        if (response.data.data.page) {
          storage.pageInfo.setItem(response.data.data.page);
        }

        dispatch(
          reduxActions.authActions.setAuthToken(response.data.data.authToken)
        );

        message.success("Apple OAuth Successful");

        if (
          response.data.data.oauthData &&
          [3, 4, 5].includes(response.data.data.page)
        ) {
          history.push("/user/base/home/dashboard");
        } else {
          history.push(
            userType === "patient"
              ? "/user/patient/update-profile"
              : "/user/doctor/update-profile"
          );
        }
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(
        error.response?.data?.data?.message || "No Response From Server",
        5
      );
    }
  };
  const verifyForgotPasswordOtpCode = (userType, data) => {
    ApiCalls.verifyForgotPasswordCode(data)
      .then((response) => {
        if (response.data.status === 1) {
          history.push(
            `/user/${userType}/forgot-password/reset/${response.data.data.authToken}`
          );
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const sendForgotPasswordVerification = (
    data,
    postSuccessFunction = () => {}
  ) => {
    ApiCalls.sendForgotPasswordVerification(data)
      .then((response) => {
        if (response.data.status === 1) {
          message.success(response?.data?.data, 5);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction(data?.data);
          }
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const resetPasswordSubmit = (userType, data) => {
    ApiCalls.resetPassword(data)
      .then((response) => {
        if (response.data.status) {
          message.success(response?.data?.data.message, 5);
          history.push(`/user/${userType}/login`);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  return {
    loading,
    sendLoginOtp,
    sendSignUpOtp,
    verifySignUpOtp,
    googleSignup,
    appleLoginSuccess,
    verifyLoginOtp,
    onPasswordLogin,
    verifyForgotPasswordOtpCode,
    sendForgotPasswordVerification,
    resetPasswordSubmit,
  };
};
