import { useState } from "react";
import ApiCalls from "../apis/ApiCalls";
import { message } from "antd";
import SocketActions from "../sockets/SocketActions";
import storage from "../storage";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import reduxActions from "../redux-store/actions";
export const useMyAccountApi = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [accountSettings, setAccountSettings] = useState(null);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [relationships, setRelationships] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [appointmentsList, setAppointmentsList] = useState([]);
  const user = useSelector((state) => state.auth.userData);

  const fetchAccountSettingsInfo = () => {
    ApiCalls.getAccountSettingsInfo()
      .then(function (response) {
        if (response.data.status) {
          setAccountSettings(response.data.data);
        }
      })
      .catch((error) => {
        message.error(
          typeof error?.response?.data?.data?.message === "string"
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getFamilyMembers = () => {
    let params = {
      page: 0,
      limit: 15,
    };
    setLoading(true);
    ApiCalls.fetchFamilyMembers(params)
      .then((response) => {
        if (response.data.status) {
          setFamilyMembers(response.data.data.familyMembers);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getAllPaymentHistory = (page) => {
    setLoading(true);
    let params = { page: page, limit: 10 };
    ApiCalls.fetchAllCompaniesPaymentDetails(params)
      .then((res) => {
        if (res.data.status) {
          let response = res.data.data.paymentDetails;
          setPaymentHistory(response);
          setHasMore(res.data.data.count > paymentHistory.length);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getRelationshipData = () => {
    ApiCalls.getRelationships()
      .then((res) => {
        if (res.data.status) setRelationships(res.data.data);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const addFamilyMember = (values, postSuccessFunction) => {
    console.log(values, "values");
    setLoading(true);
    ApiCalls.addFamilyMember(values)
      .then((response) => {
        postSuccessFunction(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const deleteFamilyMember = (id, postSuccessFunction) => {
    setLoading(true);
    let params = { deleteFieldId: id };
    ApiCalls.deleteFamilyMember(params)
      .then((response) => {
        postSuccessFunction(response);
        setLoading(false);
        if (response.data.status) {
          message.success("Deleted successfully");
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const accountPasswordChange = (payload, postSuccessFunction) => {
    setLoading(true);
    ApiCalls.changePassword(payload)
      .then((response) => {
        if (response.data.status) {
          message.success("Successfully changed password");
          postSuccessFunction();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const fetchAppointmentsHistory = (
    payload,
    postSuccessFunction = () => {}
  ) => {
    setLoading(true);
    if (user.userType === "patient") {
      ApiCalls.getAppointmentsPatient(payload)
        .then((response) => {
          if (response.data.status) {
            setAppointmentsList(response?.data?.data);
            if (typeof postSuccessFunction === "function") {
              postSuccessFunction(payload, response?.data?.data);
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    } else {
      ApiCalls.getAppointmentsDoctor(payload)
        .then((response) => {
          if (response.data.status) {
            setAppointmentsList(response?.data?.data);
            postSuccessFunction(payload, response?.data?.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    }
  };
  const userAccountDeletion = (payload) => {
    setLoading(true);
    ApiCalls.deleteAccount(payload)
      .then((response) => {
        if (response.data.status) {
          message.success(
            response.data.data
              ? response.data.data.message
              : "User Profile Deleted Successfully",
            5
          );
          SocketActions.disconnect();
          storage.authToken.clear();
          storage.user.clear();
          dispatch(reduxActions.authActions.removeAuthToken());
          dispatch(reduxActions.authActions.removeUserData());
          history.push("/user/patient/signup");
          window.location.reload();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };

  const emailVerificationForAccountDeletion = (postSuccessFunction) => {
    setLoading(true);
    ApiCalls.emailVerificationForAccountDeletion()
      .then((response) => {
        if (response.data.status) {
          message.success(
            response.data.data
              ? response.data.data.message
              : "Email Sent Successfully",
            5
          );
          postSuccessFunction();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const updatePatientProfile = (payload, postSuccessFunction) => {
    setLoading(true);
    ApiCalls.editPatientProfile(payload)
      .then((response) => {
        if (response.data.status) {
          setLoading(false);
          message.success("Updated successfully");
          postSuccessFunction();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getPatientProfileData = () => {
    setLoading(true);
    ApiCalls.getUserProfile()
      .then((response) => {
        if (response.data.status) {
          let userData = response.data.data[0];
          storage.user.setItem(userData);
          dispatch(reduxActions.authActions.setUserData(userData));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const updateNotficationStatus = (payload, postSuccessFunction) => {
    setLoading(true);
    ApiCalls.changeNotificationStatus(payload)
      .then((response) => {
        if (response.data.status) {
          message.success("Successfully changed notification settings");
          postSuccessFunction();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  return {
    fetchAccountSettingsInfo,
    accountSettings,
    getAllPaymentHistory,
    hasMore,
    paymentHistory,
    getFamilyMembers,
    loading,
    familyMembers,
    getRelationshipData,
    relationships,
    addFamilyMember,
    accountPasswordChange,
    fetchAppointmentsHistory,
    appointmentsList,
    userAccountDeletion,
    emailVerificationForAccountDeletion,
    updatePatientProfile,
    getPatientProfileData,
    updateNotficationStatus,
    deleteFamilyMember,
  };
};
