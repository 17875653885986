import styled from "styled-components";
import { Form } from "antd";

export const Wrapper = styled.div`
  label {
    color: rgba(153, 153, 153, 1);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.01em;
    font-family: "Schibsted Grotesk";
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin: 4px 0 0 0;
  position: relative;
  margin-bottom: 0px;
  background-color: #fbfbfb;
  & .ant-form-item-required::before {
    content: "" !important;
  }
  .ant-select-arrow {
    display: none !important;
  }
  & .ant-form-item-required::after {
    display: inline-block !important;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: "Schibsted Grotesk";
    line-height: 1;
    content: "*" !important;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }

  & .ant-picker {
    height: 67px;
    padding-left: 0px;
    border-radius: 10px !important;
    background-color: #fbfbfb !important;
    border: ${(props) => (props.type2 ? "none" : "")};
    & .anticon-calendar {
      font-size: 23px;
    }
  }
  & .ant-select-selector {
    height: 52px !important;
    border-radius: 10px !important;
    background-color: #fbfbfb !important;
    padding: 10px 15px 10px 15px !important;
    border: ${(props) => (props.type2 ? "none !important" : "")};
    @media only screen and (max-width: 900px) {
      height: 50px !important;
      padding: 0 15px !important;
      display: flex;
      align-items: center;
    }
  }
  & .ant-select-selection-item {
    line-height: 20px !important;
    font-size: 16px;
    font-family: "Schibsted Grotesk";
    display: flex;
    align-items: center;
    color: rgba(153, 153, 153, 1) !important;
    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
  }
  & .ant-select-selection-search-input {
    padding: 25px 4px 0px !important;
    line-height: 20px !important;
    height: 100% !important;
  }
`;
