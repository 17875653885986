import React from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  CommonDMFontTextRegular,
  CommonDMFontTextSmall,
  CommonSchibstedFontTextBold,
} from "../../utils/styledUtils";
import SaveCancelButtons from "../SaveCancelButtons";
import OtpInput from "../CustomOtpField";
import CloseIcon from "../../assets/icons/CloseIcon";
import PhoneInput from "react-phone-input-2";
import { Checkbox } from "antd";
import { LoginSignupCardWrapper } from "./styledComponents";
import CustomArticleButton from "../CustomArticleButton";
import CustomUserInputField from "../CustomUserInputField";
import LockIcon from "../../assets/icons/LockIcon";

const LoginSignupCard = ({
  userType,
  title,
  subTitle,
  cancelButtonTitle,
  saveButtonTitle,
  onCancelClick,
  onSaveClick,
  cancelBgColor,
  buttonFlexDirection,
  onResendOtp,
  setOtp,
  otp,
  setFormData,
  formData,
  action,
  cancelTextColor,
  isOtpInvalid,
  alreadyExists,
  password,
  setPassword,
  className,
  onForgotPasswordClick,
  confirmPassword,
  setConfirmPassword,
  isInvalidPassword,
  onOtpSubmitClick,
}) => {
  const history = useHistory();
  const { step, channel } = useParams();
  console.log(formData, "formData");
  const handleOtpChange = (value) => setOtp(value);
  const handlePhoneChange = (value, format) => {
    console.log(value, "value");
    setFormData((prev) => ({
      ...prev,
      phoneNumber: value,
      phoneNumberFormat: format.dialCode,
    }));
  };
  const clearPhoneNumber = () =>
    setFormData((prev) => ({ ...prev, phoneNumber: "" }));

  const handleCheckbox = (e) =>
    setFormData((prev) => ({ ...prev, agreementSigned: e.target.checked }));

  const navigateTo = (path) => history.push(path);

  return (
    <LoginSignupCardWrapper className={`${className} LoginSignupCardWrapper`}>
      <div className="Card-Text-Wrapper">
        <CommonSchibstedFontTextBold fontSize="32px" className="Card-Title">
          {title}
        </CommonSchibstedFontTextBold>
        <CommonDMFontTextRegular fontWeight="300" className="Card-Sub-Title">
          {subTitle}
        </CommonDMFontTextRegular>
      </div>
      {channel && channel !== "reset" ? (
        <>
          <OtpInput
            numInputs={6}
            value={otp}
            onChange={handleOtpChange}
            onResendOtp={() => onResendOtp(channel)}
            className="Signup-Otp-Wrapper"
            invalidText="Invalid OTP! Try Again"
            isOtpInvalid={isOtpInvalid}
          />
        </>
      ) : (
        ((action === "signup" && step) ||
          action === "login" ||
          action === "forgot-password" ||
          step) &&
        channel !== "reset" && (
          <div className="Auth-Content-Wrapper">
            {step || action === "login" || action === "forgot-password" ? (
              <div className="CustomUserInputField">
                <PhoneInput
                  placeholder="Phone Number"
                  inputProps={{ name: "phoneNumber", required: true }}
                  value={formData?.phoneNumber}
                  onChange={handlePhoneChange}
                  country="in"
                />
                {formData?.phoneNumber && (
                  <div className="Close-Icon-Style" onClick={clearPhoneNumber}>
                    <CloseIcon height="17px" width="17px" />
                  </div>
                )}
              </div>
            ) : null}
            {action && (
              <>
                {step && action === "login" && step === "password" && (
                  <div className="CustomPasswordField">
                    <LockIcon
                      width="18px"
                      height="20px"
                      className="Custom-Lock-Style"
                      stroke="rgba(102, 102, 102, 1)"
                    />
                    <CustomUserInputField
                      className="Password-Style"
                      placeholder="Enter Password"
                      isPasswordField
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      hideIconColor="rgba(153, 153, 153, 1)"
                    />
                  </div>
                )}
                {alreadyExists && (
                  <div
                    className="Already-Login-Style "
                    style={{ marginTop: "36px" }}
                  >
                    <CommonDMFontTextSmall textColor="rgba(194, 70, 70, 1)">
                      {action === "login"
                        ? "Number not registered"
                        : "This mobile number is already registered."}
                    </CommonDMFontTextSmall>
                    <CustomArticleButton
                      closeBtn
                      buttonText={
                        action === "login"
                          ? "Signup instead?"
                          : "Login instead?"
                      }
                      onClick={() => {
                        if (action === "login") {
                          navigateTo(`/user/${userType}/signup/get-started`);
                        } else {
                          navigateTo(`/user/${userType}/login`);
                        }
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {action === "login" && (
              <>
                <div className="Already-Login-Style">
                  <Checkbox
                    className="Agreement-Checkbox"
                    style={{ marginTop: "27px" }}
                  >
                    Remember me
                  </Checkbox>
                  {step === "password" && (
                    <CustomArticleButton
                      className="Forgot-Button-Style"
                      closeBtn
                      buttonText="Forgot Password?"
                      onClick={onForgotPasswordClick}
                    />
                  )}
                </div>
                {step === "password" && isInvalidPassword && (
                  <CommonDMFontTextSmall
                    textColor="rgba(194, 70, 70, 1)"
                    style={{ marginTop: "37px" }}
                  >
                    Incorrect password. Please try again
                  </CommonDMFontTextSmall>
                )}
              </>
            )}
            {action === "signup" && step && (
              <Checkbox
                onClick={handleCheckbox}
                checked={formData?.agreementSigned}
                className="Agreement-Checkbox"
                style={{ marginTop: "39px" }}
              >
                <span className="Agreement-Text-Style">
                  I Agree to Telehealings{" "}
                  <a
                    onClick={() =>
                      window.open(
                        "/user/base/home/confidentiality-agreement",
                        "_blank"
                      )
                    }
                  >
                    Terms & Conditions.
                  </a>
                </span>
              </Checkbox>
            )}
          </div>
        )
      )}
      {action === "forgot-password" && channel === "reset" ? (
        <div className="Auth-Content-Wrapper">
          <div className="CustomPasswordField">
            <LockIcon
              width="18px"
              height="20px"
              className="Custom-Lock-Style"
              stroke="rgba(102, 102, 102, 1)"
            />
            <CustomUserInputField
              className="Password-Style"
              placeholder="Enter New Password"
              isPasswordField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              hideIconColor="rgba(153, 153, 153, 1)"
            />
          </div>
          <div className="CustomPasswordField">
            <LockIcon
              width="18px"
              height="20px"
              className="Custom-Lock-Style"
              stroke="rgba(102, 102, 102, 1)"
            />
            <CustomUserInputField
              className="Password-Style"
              placeholder="Confirm Password"
              isPasswordField
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              hideIconColor="rgba(153, 153, 153, 1)"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {(action || step) && (!channel || channel === "reset") && (
        <>
          <SaveCancelButtons
            cancelButtonTitle={cancelButtonTitle}
            saveButtonTitle={saveButtonTitle}
            onCancelClick={onCancelClick}
            onSaveClick={onSaveClick}
            className={`${
              channel === "reset" ? "Hide-Buttons-Style" : ""
            } Login-Signup-Button-Wrapper`}
            cancelBgColor={cancelBgColor}
            buttonFlexDirection={buttonFlexDirection}
            cancelTextColor={cancelTextColor}
          />
          {action === "login" && step !== "password" && (
            <CustomArticleButton
              closeBtn
              buttonText="Sign in using Password"
              onClick={() => navigateTo(`/user/${userType}/login/password`)}
            />
          )}
        </>
      )}
      {userType === "doctor" && channel && channel !== "reset" && (
        <CustomArticleButton
          buttonText="Continue"
          onClick={onOtpSubmitClick}
          className={"ContinueButtonStyle"}
        />
      )}
    </LoginSignupCardWrapper>
  );
};

export default LoginSignupCard;
