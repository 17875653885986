import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Login from "./login";
import Signup from "./signup";
import UpdateProfilePatient from "./UpdateProfilePatient";
import UpdateProfileDoctor from "./UpdateProfileDoctor";
import Base from "./Base";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./login/ForgotPassword";
import ReverifyUser from "./signup/ReverifyUser";
import ReverifyUserEmail from "./signup/ReverifyUserEmail";
import OtpVerification2 from "./signup/OtpVerification2";
import SignUpV2 from "./SignUpV2";
import LoginV2 from "./loginV2";
import ForgotPasswordV2 from "./loginV2/Forgotpassword";

const DocPatient = ({ match }) => (
  <Switch>
    {/* <Route
      exact
      path={`${match.url}/:userType/signup`}
      render={(props) => <Signup />}
    /> */}
    <Route
      exact
      path={`${match.url}/:userType/signup/:step?/:channel?`}
      render={(props) => <SignUpV2 />}
    />
    <Route
      exact
      path={`${match.url}/patient/signup/otp-verification/:token`}
      render={() => <OtpVerification2 />}
    />
    {/* <Route
      exact
      path={`${match.url}/:userType/login`}
      render={(props) => <Login />}
    /> */}
    <Route
      exact
      path={`${match.url}/:userType/login/:step?/:channel?`}
      render={(props) => <LoginV2 />}
    />
    <Route
      exact
      path={`${match.url}/patient/update-profile/:action?`}
      render={(props) => <UpdateProfilePatient />}
    />
    <Route
      exact
      path={`${match.url}/doctor/update-profile/:action?`}
      render={(props) => <UpdateProfileDoctor />}
    />
    <Route path={`${match.url}/base`} render={() => <Base />} />
    {/* <Route
      exact
      path={`${match.url}/:userType/forgot-password`}
      component={(props) => <ForgotPassword />}
    /> */}
    <Route
      exact
      path={`${match.url}/:userType/forgot-password/:channel?/:accessToken?`}
      component={(props) => <ForgotPasswordV2 />}
    />
    <Route
      exact
      path={`${match.url}/patient/forgot-password/otp-verification/:token`}
      render={() => <OtpVerification2 forgotPassword={true} />}
    />
    <Route
      exact
      path={`${match.url}/:userType/reset-password/:accessToken`}
      component={() => <ResetPassword accountVerification={false} />}
    />
    <Route
      exact
      path={`${match.url}/:userType/reverify`}
      component={() => <ReverifyUser />}
    />
    <Route
      exact
      path={`${match.url}/:userType/reverify-email`}
      component={() => <ReverifyUserEmail />}
    />
    <Redirect to="/user/patient/signup" />
  </Switch>
);

export default withRouter(DocPatient);
