import styled from "styled-components";

export const DoctorProfileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 99px);
  padding: 35px 35px 35px 45px;
  & > .anticon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & svg {
      height: 2rem;
      width: 2rem;
    }
  }
`;

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;

  & .anticon-arrow-left {
    font-size: 18px;
    color: #35364f;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.div`
  font-size: 25px;
  font-family: "Gilroy SemiBold";
  padding-left: 13px;
  color: #35364f;
  text-transform: capitalize;
`;

export const PageDivisionContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 24px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ProfileInfoContainer = styled.div`
  height: auto;
  width: calc(100% - 390px);
  background-color: white;
  border-radius: 10px;

  @media only screen and (max-width: 900px) {
    padding: 30px 20px 30px 20px;
    width: 45vw;
  }

  @media only screen and (max-width: 600px) {
    padding: 10px 20px 20px 20px;
    width: 80vw;
  }
`;

export const AvailabilityTabWrapper = styled.div`
  width: 370px;
  height: auto;
  border-radius: 10px;
  background-color: white;
  padding: 22px 13px 25px 13px;

  @media only screen and (max-width: 600px) {
    padding: 0px;
    width: 80vw;
  }
`;

export const DoctorAdvancedInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const DoctorInfoContainer = styled.div`
  width: 100%;
  height: 170px;
  display: flex;
  align-items: center;
  max-width: 900px;

  @media only screen and (max-width: 900px) {
    align-items: flex-start;
  }
`;

export const DoctorPhotoContainer = styled.div`
  height: 98px;
  width: 80px;
  border-radius: 50%;
  background-color: darkcyan;
  & img {
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 900px) {
    width: 100px;
    height: 100px;
  }

  @media only screen and (max-width: 600px) {
    width: 50px;
    height: 50px;
  }
`;

export const DoctorIntro = styled.div`
  width: 100%;
  font-size: 17px;
  font-family: "Urbanist", sans-serif;
  color: #35364f;

  @media only screen and (max-width: 900px) {
    margin-top: 50px;
  }
`;

export const EducationWorkInfoContainer = styled.div`
  margin-top: 38px;
  width: 100%;
`;

export const EduWorkItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  background: linear-gradient(#e3e3e34d 30%, #e3e3e34d 30%);
  margin-bottom: 10px;
`;

export const EduWorkInfo = styled.div`
  font-size: 18px;
  font-family: "Gilroy SemiBold";
  color: #35364f;
  text-transform: capitalize;
`;

export const EduWorkLocation = styled.div`
  font-size: 18px;
  font-family: "Urbanist", sans-serif;
  color: #65667d;
  text-transform: capitalize;
`;

export const EduWorkItemIcon = styled.div`
  padding-right: 10px;
  & svg {
    height: 25px;
    width: 30px;
  }
`;

export const EduWorkItemInfo = styled.div`
  display: flex;
  flex: 1; /* Take up remaining space */
  justify-content: space-between; /* Align items with space between */
`;

export const EduWorkItemYear = styled.div`
  font-size: 18px;
  font-family: "Gilroy SemiBold";
  color: #35364f;
`;

export const DoctorSpecialitiesContainer = styled.div`
  margin-top: 27px;
  width: 100%;
  height: auto;
`;

export const ProfileItemTitle = styled.div`
  padding-bottom: 12px;
  font-size: 18px;
  font-family: "Gilroy Bold";
  color: #35364f;
`;
export const SpecialitiesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 17px;
  grid-template-columns: repeat(auto-fill, 130px);
`;

export const Speciality = styled.span`
  padding: 10px;
  color: #fff;
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  border-radius: 20px;
  text-transform: capitalize;
  text-align: center;
  background: #417fc2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Languages = styled.span`
  padding: 10px;
  border: 0.5px solid #ababab;
  font-family: Metropolis Regular;
  font-size: 12px;
  border-radius: 10px;
  text-transform: capitalize;
  text-align: center;
  background: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
`;

export const ReportIssue = styled.span`
  padding: 14px;
  border: 0.5px solid #b3261e;
  font-family: Metropolis Regular;
  font-size: 16px;
  border-radius: 10px;
  text-transform: capitalize;
  text-align: center;
  background: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  color: #b3261e;
  display: flex;
  width: 210px;
  justify-content: center;
`;

export const DoctorServicesContainer = styled.div`
  width: 100%;
  margin-top: 27px;
`;

export const StyledList = styled.ul`
  width: 100%;
  display: grid;
  grid-gap: 17px;
  grid-template-columns: repeat(auto-fill, 250px);
`;

export const ListItem = styled.li`
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  font-size: 16px;
  font-family: "Urbanist", sans-serif;
  color: #35364f;
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  &::marker {
    color: #4385c6;
    font-size: 25px;
  }
`;

export const DoctorAddressContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 27px;
  background: linear-gradient(#e3e3e34d 30%, #e3e3e34d 30%);
  padding: 10px;
  border-radius: 10px;
`;

export const Address = styled.div`
  width: 400px;
  height: auto;
  font-size: 18px;
  font-family: Gilroy Medium;
  color: #35364f;
`;

export const TabHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & .ant-picker {
    border: none !important;
    box-shadow: none !important;
    & svg {
      height: 10px;
      width: 10px;
      margin-top: 3px;
      color: grey;
    }
  }

  & input {
    width: 70px;
    cursor: pointer;
    font-size: 14px;
    font-family: "Urbanist", sans-serif;
    color: #35364f;
  }
`;

export const TabHeader = styled.div`
  font-size: 20px;
  font-family: "Gilroy SemiBold";
  color: #35364f;
`;

export const DatesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const DateCard = styled.div`
  height: 65px;
  width: 52px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: ${(props) =>
    props.selected ? "1px solid #4385C6" : "1px solid #EBEDF0"};
  margin-top: 10px;
  border-radius: 10px;
  background-color: ${(props) => (props.selected ? "#4385C6" : "white")};
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: #4385c6;
    border: 1px solid #4385c6;
  }

  &:hover > span {
    color: #fff !important;
  }

  & > span {
    color: ${(props) => (props.selected ? "#fff !important" : "")};
  }
`;

export const DateCardDay = styled.span`
  font-size: 10px;
  font-family: "Urbanist", sans-serif;
  color: #65667d;
`;

export const DateCardDate = styled.span`
  font-size: 14px;
  font-family: "Urbanist", sans-serif;
  color: #35364f;
`;

export const AvailableSlotWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 40px;
  height: 190px;
  overflow: auto;
  width: 100%;
  padding: 0;
  & .anticon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const AvailabilityMessage = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  color: #35364f;
`;

export const TimeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #ffffff;
  color: ${(props) => (props.selected ? "#fff" : "#35364F")};
  margin-top: 10px;
`;

export const ContinueButtonContainer = styled.div`
  width: 333px;
  margin: 0 auto;
  margin-top: 26px;
`;

export const PatientReviewContainer = styled.div`
  position: relative;
  width: 100%;
  & > :not(:first-of-type) {
    margin-top: 11px;
  }
  overflow: auto;
  & > .anticon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media only screen and (max-width: 600px) {
    width: 70vw;
    height: fit-content;
    min-height: fit-content;
  }
`;

export const ReviewCard = styled.div`
  width: 100%;
  height: 102px;
  padding: 13px 13px 21px 13px;
  border-radius: 10px;
`;

export const PersonInfoContainer = styled.div`
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const PersonImageContainer = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  background: lightgray;
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

export const NameRatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 13px;
`;

export const PersonName = styled.p`
  font-size: 16px;
  color: #35364f;
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 700;
  font-family: Metropolis Regular;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const ReviewDate = styled.p`
  font-size: 14px;
  color: #35364f;
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 500;
  font-family: Metropolis Regular;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const StarContainer = styled.div`
  width: 62px;
  display: flex;
  align-items: center;
  & > :not(:first-of-type) {
    margin-left: 3px;
  }
  & .anticon-star {
    font-size: 10px;
  }
`;

export const ReviewContent = styled.div`
  margin-top: 13px;
  width: 100%;
  height: auto;
  font-size: 16px;
  color: #65667d;
  font-weight: 400;
  font-family: Metropolis Regular;
`;

export const SubHeading = styled.div`
  font-size: 16px;
  margin-top: 20px;
  font-family: "Urbanist", sans-serif;
  color: #03183c;
  font-weight: "500";
`;

export const AppointmentTypeContainer = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin: 10px 0;
`;

export const ShowMoreButton = styled.button`
  position: absolute;
  top: 0;
  right: 20px;
  border: none;
  background: none;
  width: 70px;
  cursor: pointer;
  font-family: "Urbanist", sans-serif;
  color: #4385c6;
  font-size: 16px;
`;

export const FollowUpCheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 310px;
  padding: 80px 0;
  & input {
    line-height: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-family: "Urbanist", sans-serif;
    color: #35364f;
    padding: 15px;
    background-color: #fff !important;
    border: "none";
  }
`;

export const CaseIdText = styled.span`
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  color: #35364f;
  padding-bottom: 10px;
`;

export const FlowLoadingContainer = styled.div`
  position: relative;
  height: 385px;
  width: 100%;
  & > .anticon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const DoctorProfileInfoWrap = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const FAQContainer = styled.div`
  margin-top: 20px;
`;

export const FAQQuestion = styled.h4`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: linear-gradient(#e3e3e34d 30%, #e3e3e34d 30%);
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
`;

export const FAQAnswer = styled.p`
  padding: 15px 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
`;

export const DateBoxContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
`;

export const SingleDate = styled.div`
  flex: 0 0 auto;
  margin-right: 20px;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-color: ${(props) => (props.selected ? "blue" : "transparent")};
  background: ${(props) => (props.selected ? "#4486C74D" : "transparent")};
`;

export const DateLabel = styled.div`
  font-weight: bold;
`;

export const DateText = styled.div`
  margin-top: 5px;
`;
