import * as React from "react";

const GoogleIcon = ({ fill, width, height }) => {
  return (
    <svg
      width={width ? width : "25"}
      height={height ? height : "25"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3055 10.5415H21.5V10.5H12.5V14.5H18.1515C17.327 16.8285 15.1115 18.5 12.5 18.5C9.1865 18.5 6.5 15.8135 6.5 12.5C6.5 9.1865 9.1865 6.5 12.5 6.5C14.0295 6.5 15.421 7.077 16.4805 8.0195L19.309 5.191C17.523 3.5265 15.134 2.5 12.5 2.5C6.9775 2.5 2.5 6.9775 2.5 12.5C2.5 18.0225 6.9775 22.5 12.5 22.5C18.0225 22.5 22.5 18.0225 22.5 12.5C22.5 11.8295 22.431 11.175 22.3055 10.5415Z"
        fill="#FFC107"
      />
      <path
        d="M3.65283 7.8455L6.93833 10.255C7.82733 8.054 9.98033 6.5 12.4998 6.5C14.0293 6.5 15.4208 7.077 16.4803 8.0195L19.3088 5.191C17.5228 3.5265 15.1338 2.5 12.4998 2.5C8.65883 2.5 5.32783 4.6685 3.65283 7.8455Z"
        fill="#FF3D00"
      />
      <path
        d="M12.5002 22.5C15.0832 22.5 17.4302 21.5115 19.2047 19.904L16.1097 17.285C15.0719 18.0742 13.8039 18.501 12.5002 18.5C9.89916 18.5 7.69066 16.8415 6.85866 14.527L3.59766 17.0395C5.25266 20.278 8.61366 22.5 12.5002 22.5Z"
        fill="#4CAF50"
      />
      <path
        d="M22.3055 10.5415H21.5V10.5H12.5V14.5H18.1515C17.7571 15.6082 17.0467 16.5766 16.108 17.2855L16.1095 17.2845L19.2045 19.9035C18.9855 20.1025 22.5 17.5 22.5 12.5C22.5 11.8295 22.431 11.175 22.3055 10.5415Z"
        fill="#1976D2"
      />
    </svg>
  );
};

export default GoogleIcon;
