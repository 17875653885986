import React from "react";
import CustomModal from "../../../components/CustomModal";
import CloseIcon from "../../../assets/icons/CloseIcon";
import { ListText, ListWrapper } from "../styledComponents";
import { Input } from "antd";
import SearchIcon from "../../../assets/icons/SearchIcon";
import { CommonSchibstedFontTextRegular } from "../../../utils/styledUtils";

const AddSpecLanguagePopup = ({
  visible,
  onCloseClick,
  className,
  okLabel,
  title,
  cancelLabel,
  data,
  addNewLanguage,
  setServicePayload,
  servicePayload,
  onOkClick,
  setSearchTerm,
  searchTerm,
}) => {
  const { Search } = Input;
  const handleFilterOption = (id) => {
    setServicePayload((prevData) => {
      if (addNewLanguage) {
        return {
          ...prevData,
          languagesFilterData: prevData.languagesFilterData.includes(id)
            ? prevData.languagesFilterData.filter((itemId) => itemId !== id)
            : [...prevData.languagesFilterData, id],
        };
      } else {
        return {
          ...prevData,
          specializationFilterData: prevData.specializationFilterData.includes(
            id
          )
            ? prevData.specializationFilterData.filter(
                (itemId) => itemId !== id
              )
            : [...prevData.specializationFilterData, id],
        };
      }
    });
  };
  const filteredData = data.filter((item) =>
    (item.language ?? item.name)
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  return (
    <CustomModal
      visible={visible}
      title={title}
      okLabel={okLabel}
      onOk={onOkClick}
      closeModal={onCloseClick}
      onCancel={onCloseClick}
      className={`${className} AddEditEducationExperiencePopup-Style AddSpecLangPopup-Style`}
      cancelLabel={cancelLabel}
      closeIcon={
        <CloseIcon stroke={"#747474"} width={"18px"} height={"18px"} />
      }
    >
      {addNewLanguage ? (
        <Search
          placeholder="Search language"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          className="Search-Input-Style"
          enterButton={<SearchIcon />}
        />
      ) : (
        ""
      )}
      <ListWrapper
        className={`${filteredData.length === 0 ? "no-data-style" : ""} ${
          addNewLanguage ? "ListWrapper-Spec-Style" : ""
        }`}
      >
        {filteredData.length > 0 ? (
          filteredData.map((item, index) => (
            <ListText
              key={index}
              onClick={() => handleFilterOption(item.language ?? item._id)}
              className={`${
                servicePayload.specializationFilterData.includes(item._id)
                  ? "selected"
                  : servicePayload.languagesFilterData.includes(item.language)
                  ? "selected"
                  : ""
              } List-Button-Style`}
            >
              {item.language ? item.language : item.name}
            </ListText>
          ))
        ) : (
          <CommonSchibstedFontTextRegular className="no-data-message">
            No language found
          </CommonSchibstedFontTextRegular>
        )}
      </ListWrapper>
    </CustomModal>
  );
};

export default AddSpecLanguagePopup;
