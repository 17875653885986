import * as React from "react";

const TherapistEditIcon = ({ fill, width, height, onClick }) => {
  return (
    <svg
      width={width ? width : "11"}
      height={height ? height : "13"}
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.898345 11.7143H9.56501C9.72919 11.7143 9.88665 11.7795 10.0027 11.8956C10.1188 12.0117 10.1841 12.1691 10.1841 12.3333C10.1841 12.4975 10.1188 12.655 10.0027 12.771C9.88665 12.8871 9.72919 12.9524 9.56501 12.9524H0.898345C0.734163 12.9524 0.576706 12.8871 0.460612 12.771C0.344518 12.655 0.279297 12.4975 0.279297 12.3333C0.279297 12.1691 0.344518 12.0117 0.460612 11.8956C0.576706 11.7795 0.734163 11.7143 0.898345 11.7143ZM0.279297 8.61903L6.46977 2.42855L8.32692 4.2857L2.13644 10.4762H0.279297V8.61903ZM7.08882 1.80951L8.32692 0.571411L10.1841 2.42855L8.94534 3.66727L7.08882 1.80951Z"
        fill={fill ? fill : "white"}
      />
    </svg>
  );
};

export default TherapistEditIcon;
