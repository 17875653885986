import styled, { keyframes } from "styled-components";
import { Menu, Layout } from "antd";
import MenuItemWithIcon from "../../components/MenuItemWithIcon";
const { Sider, Content } = Layout;

export const StyledMenu = styled(Menu)`
  padding-top: 30px;
  background: rgba(55, 63, 106, 1);
  overflow-x: auto;
  height: calc(100% - 150px);

  li {
    display: flex;
    align-items: center;
    padding-left: 0px;
    font-size: 16px;
    font-family: "Urbanist", sans-serif;
    font-weight: 500;
    & > a {
      display: flex;
      color: #ffffff;
    }
  }
  &.ant-menu {
    .Active-Style {
      background-color: #c7d7fe !important;
      a {
        color: #2c509f;
      }
    }
  }
  .ant-menu-item {
    display: flex;
    align-items: center;
    gap: 12.5px;
    justify-content: start;
    padding: 12px 17px !important;
    height: 44px;
    width: calc(100% - 24px);
    border-radius: 4px;
    margin: 8px 12px;
    & a {
      font-family: "Urbanist", sans-serif;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .ant-menu-item-selected {
    background-color: #c7d7fe !important;
    & a {
      color: #2c509f;
    }

    & svg {
      & path {
        stroke: #2c509f;
      }
    }

    &::after {
      border: none;
    }
  }
`;

export const VideoCallContainer = styled.div`
  position: fixed;
  top: ${(props) => (props.minimizeWindow ? "calc(100vh - 150px)" : "0")};
  left: ${(props) => (props.minimizeWindow ? "calc(100vw - 266px)" : "0")};
  height: ${(props) => (props.minimizeWindow ? "100px" : "100vh")};
  width: ${(props) => (props.minimizeWindow ? "166px" : "100vw")};
  z-index: 500;
  background: rgba(0, 0, 0, 0.4);
`;

export const StyledWindowButton = styled.button`
  position: absolute;
  top: ${(props) => (props.minimizeWindow ? "0" : "10px")};
  left: ${(props) => (props.minimizeWindow ? "140px" : "10px")};
  z-index: 510;
  background: rgba(255, 255, 255, 0.27);
  border: none;
  cursor: pointer;
  & svg > path {
    fill: #fff;
  }
`;

export const StyledLayout = styled(Layout)``;

export const StyledSider = styled(Sider)`
  background: rgba(55, 63, 106, 1);

  .ant-menu-inline {
    border: none;
  }
  @media only screen and (max-width: 900px) {
    width: ${({ showSlider }) => (showSlider ? "288px" : "0px")} !important;
    overflow-x: hidden !important;
    min-width: 0px !important;
    position: absolute;
    z-index: 500;
    top: 0;
    bottom: 0;
    height: 100vh;
    padding-top: 50px;
  }
`;

export const StyledContent = styled(Content)`
  position: relative;
  height: calc(100vh - 80px);
  width: calc(100vw - 266px);
  background: #fff;
  overflow-y: auto;

  & > .anticon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & svg {
      height: 2rem;
      width: 2rem;
    }
  }
`;

const float = keyframes`
  0%{
    top: -125px;
  }40%{
    top: -92.5px;
  }80%{
    top: 60px;
  }100%{
    top: 30px;
  }
`;

export const NewMessageNotification = styled.div`
  position: fixed;
  top: 25px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 550;
  display: flex;
  height: 100px;
  width: 400px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  animation: ${float} 0.8s linear;
  cursor: pointer;
`;

export const UserPFP = styled.div`
  height: 50px;
  width: 50px;
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
`;

export const SenderName = styled.p`
  margin-bottom: 0;
  font-family: "Gilroy Semibold";
  font-size: 16px;
  color: #35364f;
`;

export const Chatinfo = styled.div`
  margin-left: 20px;
`;

export const ChatMsg = styled.p`
  font-family: "Gilroy Regular";
  font-size: 14px;
  color: #35364f;
  width: 290px;
  word-break: break-all;
`;

export const CloseMsgNotification = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`;

export const TabIcons = styled.img`
  filter: ${({ active }) =>
    active
      ? "brightness(0) saturate(100%) invert(27%) sepia(15%) saturate(2350%) hue-rotate(189deg) brightness(94%) contrast(87%)"
      : "invert(0%) sepia(100%) saturate(0%) hue-rotate(264deg) brightness(107%) contrast(102%)"};
`;

export const MoreIcon = styled.img`
  position: absolute;
  top: 27px !important;
  left: 60px !important;

  @media only screen and (max-width: 600px) {
    left: 30px !important;
  }
`;

export const BookSessionWrapper = styled.div`
  padding: 24px 8px;
  background-color: rgba(55, 63, 106, 1);
  border-bottom: 1px solid #e3e3e34d;
  display: flex;
  justify-content: center;
  align-items: center;

  & button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #74abe8;
    border-radius: 100px;
    width: 256px;
    border: none;
    padding: 10px 16px;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  & button:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #5b9de0;
  }

  & span {
    font-weight: 700;
    font-size: 14px;
    font-family: Metropolis Bold;
    color: #ffffff;
  }
`;
export const StyledMenuItemwithIcon = styled(MenuItemWithIcon)`
  width: calc(100% - 24px);
  margin: 0 12px 12px;
  padding: 12px 17px !important;
  .IconTextWrapper {
    svg {
      path {
        ${(props) =>
          props.activeKey ? "rgba(55, 63, 106, 1) !important" : "#ffffff"};
      }
    }
  }
  :hover {
    background-color: #ffffff33;
    border-color: transparent;
    .IconTextWrapper {
      .MenuText {
        color: #ffffff;
      }
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
`;
