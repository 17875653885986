import { useState } from "react";
import ApiCalls from "../apis/ApiCalls";
import { message } from "antd";

export const useCommonLookupsApi = () => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [specialities, setSpecialities] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const getCountriesLookup = (postSuccessFunction = () => {}) => {
    setLoading(true);
    ApiCalls.getCountries()
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let countries = response.data.data;
          setCountries(countries);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction(countries);
          }
        } else message.error("Couldn't fetch countries");
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getAllLanguages = (payload) => {
    setScrollLoading(true);
    if (payload.page === 0) setLanguageList([]);

    ApiCalls.getAllLanguages(payload)
      .then((response) => {
        if (response.data.status) {
          let languages = response.data.data.languages;
          if (payload.page === 0) {
            setLanguageList(languages);
          } else {
            setLanguageList(languageList.concat(languages));
          }
          setScrollLoading(false);
          setHasMore(languages.length > 19);
        }
      })
      .catch((error) => {
        setScrollLoading(false);
      });
  };
  const getSpecialisationList = () => {
    ApiCalls.getSpecialisationList()
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setSpecialities(response.data.data);
        } else {
          message.error("Can't fetch specialites");
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getLanguageList = () => {
    ApiCalls.getLanguages()
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setLanguages(response.data.data);
        } else {
          message.error("Can't fetch languages");
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getStatesLookup = (countryCode, postSuccessFunction = () => {}) => {
    ApiCalls.getStates(countryCode)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setStates(response.data.data);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction(response.data.data);
          }
        } else {
          message.error("Can't fetch states");
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getCitiesLookup = (
    countryCode,
    state,
    postSuccessFunction = () => {}
  ) => {
    ApiCalls.getAvailableCities(countryCode, state)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setCities(response.data.data);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction(response.data.data);
          }
        } else {
          message.error("Can't fetch cities");
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getCurrencies = (postSuccessFunction = () => {}) => {
    setLoading(true);
    ApiCalls.fetchCurrencies()
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let currencies = response.data.data.currencies;
          setCurrencies(currencies);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction(currencies);
          }
        } else message.error("Couldn't fetch currencies");
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  return {
    getCountriesLookup,
    loading,
    countries,
    getAllLanguages,
    scrollLoading,
    hasMore,
    languageList,
    getSpecialisationList,
    specialities,
    getLanguageList,
    languages,
    getStatesLookup,
    states,
    getCitiesLookup,
    cities,
    getCurrencies,
    currencies,
  };
};
