import React, { forwardRef, useEffect } from "react";
import { NameWrapper } from "../../UpdateProfilePatient/styledComponents";
import { CommonSchibstedFontTextBold } from "../../../utils/styledUtils";
import moment from "moment";
import BasicInformation from "./BasicInformation";
import EducationExperience from "./EducationExperience";
import ServicesAndSpeciality from "./ServicesAndSpeciality";
import Availability, { TIME_OPTIONS } from "./Availability";
import { WeekData } from "..";
import BankDetails from "./BankDetails";
import PricingDetail from "./PricingDetail";

const Name = forwardRef(
  (
    {
      currentPage,
      payload,
      setPayload,
      form,
      onFinish,
      oauthData,
      setIsExpEduUpdated,
      servicePayload,
      setServicePayload,
      setIsDetailUpdated,
      docProfileDetails,
      loading,
      setAvailability,
      availability,
      setCurrentPage,
      setTimeSelected,
      timeSelected,
      pricePayload,
      setPricePayload,
    },
    ref
  ) => {
    useEffect(() => {
      if (docProfileDetails) {
        setIsExpEduUpdated(
          docProfileDetails?.educationalDetails?.length > 0 &&
            docProfileDetails?.experienceDetails?.length > 0
        );
        setServicePayload((prevData) => ({
          ...prevData,
          languagesFilterData: docProfileDetails?.languagesKnown || [],
          specializationFilterData:
            docProfileDetails?.specialization?.map((item) => item._id) || [],
          professionalTitle: docProfileDetails?.professionalTitle || "",
          totalWorkExperience: docProfileDetails?.totalWorkExperiance || "",
        }));
        setPricePayload((prevData) => ({
          ...prevData,
          currency: docProfileDetails?.currency || "",
          consultationFee:
            docProfileDetails?.consultationFee !== undefined &&
            docProfileDetails?.consultationFee !== "undefined"
              ? docProfileDetails.consultationFee
              : "",
        }));
        if (docProfileDetails.consultationDuration) {
          const selectedLabel =
            TIME_OPTIONS.find(
              (option) =>
                option.minutes === docProfileDetails.consultationDuration
            )?.label || "30m";

          setTimeSelected(selectedLabel);
        }
      }
    }, [docProfileDetails]);

    useEffect(() => {
      if (docProfileDetails?.timeSlot?.length) {
        const newAvailability = WeekData.reduce((acc, day) => {
          acc[day] = {
            isAvailable: false,
            hasBreak: false,
            startTime: null,
            endTime: null,
            breakStartTime: null,
            breakEndTime: null,
          };

          const daySlot = docProfileDetails.timeSlot.find(
            (slot) => slot.day === day
          );

          if (daySlot) {
            const startTime = daySlot.startTime
              ? moment(daySlot.startTime)
              : null;
            const endTime = daySlot.endTime ? moment(daySlot.endTime) : null;

            const hasBreak = daySlot.addBreak && daySlot.addBreak.length > 0;
            let breakStartTime = null;
            let breakEndTime = null;

            if (hasBreak && daySlot.addBreak[0]) {
              breakStartTime = moment(daySlot.addBreak[0].startTime);
              breakEndTime = moment(daySlot.addBreak[0].endTime);
            }

            acc[day] = {
              isAvailable: true,
              hasBreak,
              startTime,
              endTime,
              breakStartTime,
              breakEndTime,
            };
          }

          return acc;
        }, {});

        setAvailability(newAvailability);

        if (docProfileDetails.timeSlot.length > 0) {
          const firstSlot = docProfileDetails.timeSlot[0];
          if (firstSlot.startTime && firstSlot.endTime) {
            const start = moment(firstSlot.startTime);
            const end = moment(firstSlot.endTime);
            let duration = end.diff(start, "minutes");

            if (firstSlot.addBreak && firstSlot.addBreak.length > 0) {
              const breakStart = moment(firstSlot.addBreak[0].startTime);
              const breakEnd = moment(firstSlot.addBreak[0].endTime);
              const breakDuration = breakEnd.diff(breakStart, "minutes");
              duration -= breakDuration;
            }

            if (duration >= 115 && duration <= 125) {
              setTimeSelected("2hr");
            } else if (duration >= 55 && duration <= 65) {
              setTimeSelected("1hr");
            } else if (duration >= 40 && duration <= 50) {
              setTimeSelected("45m");
            }
          }
        }
      }
    }, [docProfileDetails]);
    return (
      <NameWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <CommonSchibstedFontTextBold
            fontSize={"23px"}
            className="Info-Heading-Style"
          >
            {currentPage === 1
              ? "Basic Information"
              : currentPage === 2
              ? "Education & Experience"
              : currentPage === 3
              ? "Services & Specialties"
              : currentPage === 4
              ? "Availability"
              : currentPage === 5
              ? "Bank Details"
              : currentPage === 6
              ? "Pricing"
              : ""}
          </CommonSchibstedFontTextBold>
        </div>
        <div>
          {currentPage === 1 ? (
            <div className="Page-Content-Wrapper">
              <BasicInformation
                form={form}
                payload={payload}
                setPayload={setPayload}
                onFinish={onFinish}
                oauthData={oauthData}
                docProfileDetails={docProfileDetails}
                setIsDetailUpdated={setIsDetailUpdated}
                loading={loading}
              />
            </div>
          ) : currentPage === 2 ? (
            <div className="Page-Content-Wrapper">
              <EducationExperience
                docProfileDetails={docProfileDetails}
                setIsDetailUpdated={setIsDetailUpdated}
                loading={loading}
                setServicePayload={setServicePayload}
                servicePayload={servicePayload}
              />
            </div>
          ) : currentPage === 3 ? (
            <div className="Page-Content-Wrapper">
              <ServicesAndSpeciality
                docProfileDetails={docProfileDetails}
                setIsDetailUpdated={setIsDetailUpdated}
                servicePayload={servicePayload}
                setServicePayload={setServicePayload}
                loading={loading}
              />
            </div>
          ) : currentPage === 4 ? (
            <div className="Page-Content-Wrapper">
              <Availability
                docProfileDetails={docProfileDetails}
                setIsDetailUpdated={setIsDetailUpdated}
                setAvailability={setAvailability}
                availability={availability}
                setTimeSelected={setTimeSelected}
                timeSelected={timeSelected}
                loading={loading}
              />
            </div>
          ) : currentPage === 5 ? (
            <div className="Page-Content-Wrapper">
              <BankDetails
                docProfileDetails={docProfileDetails}
                setIsDetailUpdated={setIsDetailUpdated}
                ref={ref}
                setCurrentPage={setCurrentPage}
                loading={loading}
              />
            </div>
          ) : currentPage === 6 ? (
            <div className="Password-Field-Wrapper">
              <PricingDetail
                docProfileDetails={docProfileDetails}
                pricePayload={pricePayload}
                setPricePayload={setPricePayload}
                loading={loading}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </NameWrapper>
    );
  }
);

export default Name;
