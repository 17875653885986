import React from "react";
import styled from "styled-components";

const ButtonWrapper = styled.button`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background || "#f4f7fc"};
  gap: 15px;
  cursor: pointer;
  border-color: transparent;
`;
const BackButton = ({
  width,
  height,
  stroke,
  onClick,
  className,
  backgroundColor,
}) => {
  return (
    <ButtonWrapper
      onClick={onClick}
      className={className}
      background={backgroundColor}
    >
      <svg
        width={width ? width : "11"}
        height={height ? height : "20"}
        viewBox="0 0 11 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.66667 18L2 10L8.66667 2"
          stroke={stroke ? stroke : "#373F6A"}
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </ButtonWrapper>
  );
};

export default BackButton;
