import React from "react";
import { ListText } from "../../UpdateProfileDoctor/styledComponents";
import { CommonSchibstedFontTextRegular } from "../../../utils/styledUtils";
import { StyledListWrapper } from "../styledComponents";

const HealthListCard = ({
  data,
  selectedValues,
  setSelectedValues,
  question,
  questionIndex,
}) => {
  console.log(selectedValues, "selectedValues");
  const handleFilterOption = (item) => {
    const itemId = item?._id;

    setSelectedValues((prevWellnessProfile) => {
      const updatedProfile = [...prevWellnessProfile];

      if (!updatedProfile[questionIndex]) {
        updatedProfile[questionIndex] = {
          question: question,
          answer: [],
        };
      }

      const currentAnswers = updatedProfile[questionIndex].answer;

      const newAnswers = currentAnswers.includes(itemId)
        ? currentAnswers.filter((id) => id !== itemId)
        : [...currentAnswers, itemId];

      updatedProfile[questionIndex] = {
        ...updatedProfile[questionIndex],
        answer: newAnswers,
      };

      return updatedProfile;
    });
  };

  return (
    <StyledListWrapper>
      {data?.length > 0 ? (
        data.map((item) => {
          const itemId = item?._id;
          return (
            <ListText
              key={itemId}
              onClick={() => handleFilterOption(item)}
              className={`Health-List-Button-Style ${
                selectedValues?.[questionIndex]?.answer?.includes(itemId)
                  ? "selected"
                  : ""
              }`}
            >
              {item.text}
            </ListText>
          );
        })
      ) : (
        <CommonSchibstedFontTextRegular className="no-data-message">
          No data found
        </CommonSchibstedFontTextRegular>
      )}
    </StyledListWrapper>
  );
};

export default HealthListCard;
