import { message } from "antd";
import moment from "moment";
import { Separator, Text, TextsWrapper } from "./styledUtils";
import React from "react";

export const formatDate = (date) => {
  if (!date) {
    return "";
  }
  const inputDate = moment(date);
  const today = moment();
  if (inputDate.isSame(today, "day")) {
    return `Today,${inputDate.format("D MMM")}`;
  }
  return inputDate.format("D MMMM");
};

export const formatTimeRange = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return "";
  }

  const formatTime = (time) => {
    const momentTime = moment(time);
    const minutes = momentTime.minutes();

    return minutes === 0 ? momentTime.format("hA") : momentTime.format("h:mmA");
  };

  return `${formatTime(startTime)}-${formatTime(endTime)}`;
};

export function getStatusChipColor(tag) {
  const lowerCaseTag = tag?.toLowerCase();
  if (lowerCaseTag === "cancelled") {
    return "rgba(194, 70, 70, 1)";
  } else if (lowerCaseTag === "completed") {
    return "rgba(118, 188, 139, 1)";
  } else {
    return "rgba(94, 168, 219, 1)";
  }
}
export function copyToClipboard(textToCopy, textForNoId) {
  navigator.clipboard
    .writeText(textToCopy || textForNoId)
    .then(() => {
      message.info(textToCopy ? "Copied to clipboard" : textForNoId, 5);
    })
    .catch((err) => {
      message.error("Failed to copy: ", err);
    });
}
export function calculatePercentage(currentCount, totalCount) {
  if (totalCount === 0) return 0; // Avoid division by zero
  return (currentCount / totalCount) * 100;
}

export const formatStartTime = (isoString) => {
  const date = new Date(isoString);

  const formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  const formattedTime = date
    .toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
    .replace(":00", "");

  return `${formattedDate} - ${formattedTime}`;
};

export const ascArraySort = (data) => {
  return data.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
};
export const descArraySort = (data) => {
  return data.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
};

export const getMonthName = (monthIndex) => {
  return new Date(2000, monthIndex, 1).toLocaleString("default", {
    month: "long",
  });
};

export const DotSeparatedTexts = (items) => {
  return (
    <TextsWrapper>
      {items?.map((item, index) => (
        <Text key={index}>
          <span>{item}</span>
          {index < items.length - 1 && <Separator>•</Separator>}
        </Text>
      ))}
    </TextsWrapper>
  );
};
export const formatAvailableOn = (availableOn) => {
  const today = moment();
  const tomorrow = moment().add(1, "day");

  if (availableOn?.toLowerCase() === "available today") {
    return `Today, ${today.format("MMM D")}`;
  } else if (availableOn?.toLowerCase() === "available tomorrow") {
    return `Tomorrow, ${tomorrow.format("MMM D")}`;
  }

  const match = availableOn?.match(/available (\w{3}) (\w{3}) (\d{1,2})/i);
  if (match) {
    const [, , month, day] = match;
    const date = moment(`${month} ${day}`, "MMM D");
    return `${date.format("dddd")}, ${date.format("MMM D")}`;
  }

  return "Invalid Date";
};
export const checkUserLocation = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    return data.country === "IN";
  } catch (error) {
    return false;
  }
};
export const formatDateMonthYear = (isoDateString) => {
  /* ex: 11th March, 2025*/
  const date = moment(isoDateString);
  const formattedDate = date.format("Do MMMM, YYYY");
  return formattedDate;
};
export const formatShortDateMonthYear = (date) => {
  /* ex: 23 Apr 2024*/
  const options = { day: "numeric", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};
export const formatTime = (timeString) => {
  const time = moment(timeString);
  const formattedTime = time.format("h:mm A");
  return formattedTime;
};
export function capitalizeTitle(str) {
  if (!str || typeof str !== "string") {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
export const formatNumber = (num) => {
  if (num === null || num === undefined) return "";

  const parsedNum = parseFloat(num);
  if (isNaN(parsedNum)) return num;

  const formatted = parsedNum.toFixed(2);
  return formatted.endsWith(".00")
    ? parseInt(formatted)
    : parseFloat(formatted);
};
export const formatPhoneNumber = (phoneNumber, countryCode) => {
  const codeLength = countryCode?.length;
  const formattedNumber = `${phoneNumber.slice(
    0,
    codeLength
  )}-${phoneNumber.slice(codeLength)}`;
  return formattedNumber;
};
export const validatePassword = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return "Passwords do not match";
  }
  if (password.length < 8) {
    return "Password should be at least 8 characters long";
  }
  if (
    !/[A-Z]/.test(password) ||
    !/[a-z]/.test(password) ||
    !/[0-9]/.test(password)
  ) {
    return "Password must contain at least one uppercase letter, one lowercase letter, and one number";
  }
  return null; // No errors
};

export const getShortFileName = (fileName, maxLength = 10) => {
  const extensionIndex = fileName.lastIndexOf(".");
  if (extensionIndex === -1) return fileName; // Return as is if no extension

  const extension = fileName.slice(extensionIndex);
  const baseName = fileName.slice(0, extensionIndex);

  if (baseName.length <= maxLength) {
    return fileName;
  }

  return `${baseName.slice(0, maxLength)}..${extension}`;
};
export function getFormattedFileName(url) {
  try {
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    const fileName = decodeURIComponent(pathname.split("/").pop());

    const fileTypeMatch = fileName.match(/\.([a-zA-Z0-9]+)$/);
    const fileType = fileTypeMatch ? fileTypeMatch[1].toUpperCase() : "UNKNOWN";

    return {
      name: fileName,
      type: fileType,
    };
  } catch (error) {
    return null;
  }
}
export function formatDateType(dateString) {
  const date = new Date(dateString);
  console.log(formatDate("2020-08-04")); // Output: "04 Aug 2020"
  console.log(formatDate("2025-03-13T14:11:29.119Z")); // Output: "13 Mar 2025"
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
}

export function addTimeToDate(date, time) {
  if (!date || !time) return null;

  const startOfDay = time.clone().startOf("day");
  const millisecondsDiff = time.diff(startOfDay, "milliseconds");
  const newDatetime = date.clone().add(millisecondsDiff, "milliseconds");

  return newDatetime;
}

export function calculateAge(dob) {
  const birthDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
}

export const getGreeting = () => {
  const hour = new Date().getHours();
  if (hour < 12) {
    return "Good Morning!";
  } else if (hour < 18) {
    return "Good Afternoon!";
  } else {
    return "Good Evening!";
  }
};
export function isGuid(str) {
  const guidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return guidRegex.test(str);
}
export function isEncodedData(payloadData) {
  if (isGuid(payloadData)) {
    return payloadData;
  }
  try {
    const urlDecoded = decodeURIComponent(payloadData);
    const base64Decoded = atob(urlDecoded);
    const decodedData = JSON.parse(base64Decoded);
    return decodedData;
  } catch (error) {
    return payloadData;
  }
}
