import React from "react";
import { Chip, ChipText } from "./StyledComponent";

const CustomArticleChip = ({
  customChipStyles,
  customTextStyles,
  text,
  className,
  onClick,
  svg,
}) => {
  return (
    <Chip
      style={{
        ...customChipStyles,
      }}
      className={className}
      onClick={onClick}
    >
      <ChipText
        style={{
          ...customTextStyles,
        }}
      >
        {text}
      </ChipText>
      {svg && svg}
    </Chip>
  );
};

export default CustomArticleChip;
