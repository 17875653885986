import React, { useState } from "react";
import { StyledFormItem, Wrapper } from "./styledComponent";
import { Input } from "antd";
import PhoneInput from "react-phone-input-2";
import ShowPasswordIcon from "../../assets/icons/ShowPasswordIcon";
import HidePasswordIcon from "../../assets/icons/HidePasswordIcon";
import TextArea from "antd/lib/input/TextArea";
const CustomUserInputField = ({
  readOnly,
  title,
  name,
  type,
  type2,
  rules,
  disabled,
  placeholder,
  onPhoneNumberChange,
  isPhoneField,
  country,
  isPasswordField,
  className,
  onChange,
  value,
  hideIconColor,
  autoFocus,
  isTextArea,
  rows,
}) => {
  console.log(value, "valuevalue");
  const [visible, setVisible] = useState(false);
  const handleIconClick = () => {
    setVisible(!visible);
  };
  return (
    <Wrapper className={className}>
      <label>{title}</label>

      <StyledFormItem
        type={type ? type : "text"}
        name={name ? name : "name"}
        rules={rules}
        type2={type2}
      >
        {isPhoneField ? (
          <PhoneInput
            placeholder={placeholder}
            inputProps={{
              name: "phoneNumber",
              required: true,
            }}
            country={country ? country : "in"}
            onChange={onPhoneNumberChange}
            disabled={disabled}
          />
        ) : isPasswordField ? (
          <Input.Password
            onChange={onChange}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            autoFocus={autoFocus ? autoFocus : false}
            readOnly={readOnly}
            iconRender={(visible) =>
              visible ? (
                <ShowPasswordIcon
                  onClick={handleIconClick}
                  fill={hideIconColor}
                />
              ) : (
                <HidePasswordIcon
                  onClick={handleIconClick}
                  fill={hideIconColor}
                />
              )
            }
          />
        ) : isTextArea ? (
          <TextArea
            rows={rows ? rows : 5}
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            readOnly={readOnly}
            autoFocus={autoFocus ? autoFocus : false}
          />
        ) : (
          <Input
            disabled={disabled}
            placeholder={placeholder}
            autoFocus={autoFocus ? autoFocus : false}
            readOnly={readOnly}
            onChange={onChange}
            value={'saada'}
          />
        )}
      </StyledFormItem>
    </Wrapper>
  );
};

export default CustomUserInputField;
