import React from "react";
import styled from "styled-components";

const ReadTimeBadge = styled.div`
  background-color: ${(props) => props.bgColor || "#e3e9f9"};
  color: ${(props) => props.textColor || "#66779e"};
  padding: 2px 10px;
  border-radius: 24px;
  font-size: ${(props) => props.fontSize || "10px"};
  font-weight: ${(props) => props.fontWeight || "500"};
  font-family: "Schibsted Grotesk";
  cursor: default;
  width: fit-content;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
  }
`;
const CustomTimeChip = ({ time, bgColor, textColor, fontSize, fontWeight }) => {
  return (
    <ReadTimeBadge
      className="ReadTimeBadge"
      bgColor={bgColor}
      textColor={textColor}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      <p>{time}</p>
    </ReadTimeBadge>
  );
};

export default CustomTimeChip;
