import styled from "styled-components";

export const TimeZoneWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .Page-Content-Wrapper {
    width: 100%;
    position: relative;
    input {
      color: rgba(55, 63, 106, 1);
      font: 500 18px DM Sans;
      width: 100%;
      height: 64px;
      border-radius: 8px;
      border: 2px solid rgba(66, 88, 137, 1);
      padding: 16px;
      background-color: #fbfbfb;
      text-align: left;
      &:focus-visible {
        outline: none !important;
      }
    }
    .map-container {
      height: calc(100% - 50px);
    }
    svg {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
`;
