import React, { useState } from "react";
import {
  AvailablityWrapper,
  ContentFlexWrapper,
  DayOffWrapper,
  ListText,
  WeekDayWrapper,
  WeekWrapper,
} from "../styledComponents";
import {
  CommonDMFontTextRegular,
  CommonSchibstedFontTextBold,
} from "../../../utils/styledUtils";
import { message, Switch, TimePicker } from "antd";
import MenuItemWithIcon from "../../../components/MenuItemWithIcon";
import CopyIcon from "../../../assets/icons/CopyIcon";
import CirclePlusIcon from "../../../assets/icons/CirclePlus";
import { WeekData } from "..";
import moment from "moment";
import CustomArticleButton from "../../../components/CustomArticleButton";
import AvailabilitySlot from "./AvailabilitySlot";
import BreakTimePickers from "./BreakTimePickers";
import SaveCancelButtons from "../../../components/SaveCancelButtons";

export const TIME_OPTIONS = [
  { label: "30m", minutes: 30 },
  { label: "1hr", minutes: 60 },
  { label: "2hr", minutes: 120 },
];
const TimeOffData = [
  {
    title: "Add Time Off",
    subTitle: "To remove time slots from your regular timings",
    text: "Off Time",
  },
  {
    title: "Add Extra Slots",
    subTitle: "Add slots that are not part of your regular timings",
    text: "Extra Slot",
  },
];
const EMPTY_SCHEDULE = {
  isAvailable: false,
  hasBreak: false,
  startTime: null,
  endTime: null,
  breakStartTime: null,
  breakEndTime: null,
};

const Availability = ({
  setAvailability,
  availability,
  setTimeSelected,
  timeSelected,
  setSpecialTime,
  specialTime,
  offDays,
  setOffDays,
  isLoggedin,
  toggleStates,
  setToggleStates,
}) => {
  const [tempStartTime, setTempStartTime] = useState(null);
  const [tempEndTime, setTempEndTime] = useState(null);
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isEndOpen, setIsEndOpen] = useState(false);

  const getConsultationDuration = () => {
    const option = TIME_OPTIONS.find((option) => option.label === timeSelected);
    return option ? option.minutes : 0;
  };

  const calculateEndTime = (startTime, breakStartTime, breakEndTime) => {
    if (!startTime) return null;

    const consultationDuration = getConsultationDuration();
    let endTime = startTime.clone().add(consultationDuration, "minutes");

    if (breakStartTime && breakEndTime) {
      const breakStartMinutes =
        breakStartTime.hour() * 60 + breakStartTime.minute();
      const breakEndMinutes = breakEndTime.hour() * 60 + breakEndTime.minute();
      const breakDurationMinutes = breakEndMinutes - breakStartMinutes;

      endTime = endTime.add(breakDurationMinutes, "minutes");
    }

    return endTime;
  };

  const isTimeBetween = (time, startTime, endTime) => {
    if (!time || !startTime || !endTime) return false;

    const timeMinutes = time.hour() * 60 + time.minute();
    const startMinutes = startTime.hour() * 60 + startTime.minute();
    const endMinutes = endTime.hour() * 60 + endTime.minute();

    return timeMinutes >= startMinutes && timeMinutes <= endMinutes;
  };

  const handleToggleChange = (day, checked) => {
    if (!timeSelected) {
      message.error(
        "Please choose a Period of Consultation before setting availability.",
        5
      );
      return;
    }

    setAvailability((prev) => ({
      ...prev,
      [day]: checked
        ? { ...prev[day], isAvailable: true }
        : { ...EMPTY_SCHEDULE },
    }));
  };

  const handleTimeChange = (day, type, time) => {
    setAvailability((prevData) => {
      const updatedDayData = { ...prevData[day] };
      const { startTime, endTime, breakStartTime } = updatedDayData;

      switch (type) {
        case "breakStartTime":
          if (!isTimeBetween(time, startTime, endTime)) {
            message.error(
              "Break start time must be between consultation start and end times.",
              3
            );
            return prevData;
          }
          updatedDayData.breakStartTime = time;
          updatedDayData.breakEndTime = null;
          break;

        case "breakEndTime":
          if (!isTimeBetween(time, breakStartTime, endTime)) {
            message.error(
              "Break end time must be between break start time and consultation end time.",
              3
            );
            return prevData;
          }
          updatedDayData.breakEndTime = time;
          break;

        case "startTime":
          updatedDayData.startTime = time;
          updatedDayData.breakStartTime = null;
          updatedDayData.breakEndTime = null;
          updatedDayData.hasBreak = false;
          updatedDayData.endTime = calculateEndTime(time, null, null);
          break;

        case "endTime":
          updatedDayData.endTime = time;
          updatedDayData.breakStartTime = null;
          updatedDayData.breakEndTime = null;
          updatedDayData.hasBreak = false;
          break;
      }

      return { ...prevData, [day]: updatedDayData };
    });
  };

  const toggleBreak = (day) => {
    if (!availability[day].startTime) {
      message.error("Please choose a consultation time for the day", 5);
      return;
    }

    setAvailability((prevData) => {
      const updatedDayData = { ...prevData[day] };
      const hasBreak = !updatedDayData.hasBreak;

      if (!hasBreak) {
        updatedDayData.breakStartTime = null;
        updatedDayData.breakEndTime = null;
        updatedDayData.endTime = calculateEndTime(
          updatedDayData.startTime,
          null,
          null
        );
      }

      updatedDayData.hasBreak = hasBreak;
      return { ...prevData, [day]: updatedDayData };
    });
  };

  const handleTimeSelected = (selectedTime) => {
    setTimeSelected(selectedTime);

    const resetData = WeekData.reduce((acc, day) => {
      acc[day] = { ...EMPTY_SCHEDULE };
      return acc;
    }, {});

    setAvailability(resetData);
  };

  const handleCopyToAll = (day) => {
    const dayData = availability[day];

    if (!dayData.startTime) {
      message.error("Please choose a consultation time for the day first", 5);
      return;
    }

    setAvailability((prevData) => {
      const updatedAvailability = { ...prevData };
      WeekData.forEach((d) => {
        updatedAvailability[d] = {
          ...updatedAvailability[d],
          startTime: dayData.startTime ? dayData.startTime.clone() : null,
          endTime: dayData.endTime ? dayData.endTime.clone() : null,
          breakStartTime:
            dayData.hasBreak && dayData.breakStartTime
              ? dayData.breakStartTime.clone()
              : null,
          breakEndTime:
            dayData.hasBreak && dayData.breakEndTime
              ? dayData.breakEndTime.clone()
              : null,
          hasBreak: dayData.hasBreak,
          isAvailable: true,
        };
      });

      return updatedAvailability;
    });
  };

  const onToggle = (state, special, index) => {
    setToggleStates((prev) => ({
      ...prev,
      [index]: state,
    }));

    if (special) {
      specialTime.open = state;
      if (!state) {
        specialTime.slot = [
          {
            date: null,
            timeArray: [
              {
                startTime: null,
                endTime: null,
              },
            ],
          },
        ];
      }
      setSpecialTime({ ...specialTime });
    } else {
      offDays.open = state;
      if (!state) {
        offDays.slot = [
          {
            date: null,
            timeArray: [
              {
                startTime: null,
                endTime: null,
              },
            ],
          },
        ];
      }
      setOffDays({ ...offDays });
    }
  };
  const handleOffSpecialTimeChange = (isSpecial, idx, timeIdx, type, time) => {
    if (!time) {
      return;
    }

    if (isSpecial) {
      setSpecialTime((prevSpecialTime) => {
        const updatedSpecialTime = { ...prevSpecialTime };
        const updatedSlot = updatedSpecialTime.slot.map((slot, sIdx) => {
          if (sIdx === idx) {
            const updatedTimeArray = slot.timeArray.map((timeSlot, tIdx) => {
              if (tIdx === timeIdx) {
                return {
                  ...timeSlot,
                  [type]: time,
                };
              }
              return timeSlot;
            });
            return {
              ...slot,
              timeArray: updatedTimeArray,
            };
          }
          return slot;
        });

        updatedSpecialTime.slot = updatedSlot;

        return updatedSpecialTime;
      });
    } else {
      setOffDays((prevOffDays) => {
        const updatedOffDays = { ...prevOffDays };
        const updatedSlot = updatedOffDays.slot.map((slot, sIdx) => {
          if (sIdx === idx) {
            const updatedTimeArray = slot.timeArray.map((timeSlot, tIdx) => {
              if (tIdx === timeIdx) {
                return {
                  ...timeSlot,
                  [type]: time,
                };
              }
              return timeSlot;
            });
            return {
              ...slot,
              timeArray: updatedTimeArray,
            };
          }
          return slot;
        });

        updatedOffDays.slot = updatedSlot;

        return updatedOffDays;
      });
    }
  };

  const handleOffSpecialDateChange = (date, index, isSpecial) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    if (isSpecial) {
      setSpecialTime((prevSpecialTime) => {
        const updatedSpecialTime = { ...prevSpecialTime };
        updatedSpecialTime.slot[index].date = formattedDate;
        return updatedSpecialTime;
      });
    } else {
      setOffDays((prevOffDays) => {
        const updatedOffDays = { ...prevOffDays };
        updatedOffDays.slot[index].date = formattedDate;
        return updatedOffDays;
      });
    }
  };
  const handleDateRemoveClick = (index, isSpecial) => {
    if (isSpecial) {
      setSpecialTime((prevSpecialTime) => {
        const updatedSpecialTime = { ...prevSpecialTime };
        updatedSpecialTime.slot = updatedSpecialTime.slot.filter(
          (_, i) => i !== index
        );

        return {
          ...updatedSpecialTime,
          slot:
            updatedSpecialTime.slot.length > 0 ? updatedSpecialTime.slot : [],
        };
      });
    } else {
      setOffDays((prevOffDays) => {
        const updatedOffDays = { ...prevOffDays };
        updatedOffDays.slot = updatedOffDays.slot.filter((_, i) => i !== index);

        return {
          ...updatedOffDays,
          slot: updatedOffDays.slot.length > 0 ? updatedOffDays.slot : [],
        };
      });
    }
  };
  const handleTimeRemoveClick = (isSpecial, idx, timeIdx) => {
    if (isSpecial) {
      setSpecialTime((prevSpecialTime) => {
        const updatedSpecialTime = { ...prevSpecialTime };
        const updatedSlot = updatedSpecialTime.slot.map((slot, sIdx) => {
          if (sIdx === idx) {
            const updatedTimeArray = slot.timeArray.filter(
              (_, tIdx) => tIdx !== timeIdx
            );
            return {
              ...slot,
              timeArray: updatedTimeArray.length > 0 ? updatedTimeArray : [],
            };
          }
          return slot;
        });

        return {
          ...updatedSpecialTime,
          slot: updatedSlot,
        };
      });
    } else {
      setOffDays((prevOffDays) => {
        const updatedOffDays = { ...prevOffDays };
        const updatedSlot = updatedOffDays.slot.map((slot, sIdx) => {
          if (sIdx === idx) {
            const updatedTimeArray = slot.timeArray.filter(
              (_, tIdx) => tIdx !== timeIdx
            );
            return {
              ...slot,
              timeArray: updatedTimeArray.length > 0 ? updatedTimeArray : [],
            };
          }
          return slot;
        });

        return {
          ...updatedOffDays,
          slot: updatedSlot,
        };
      });
    }
  };
  const handleAddDay = (special) => {
    const slots = special ? specialTime.slot : offDays.slot;

    const allFieldsFilled = slots.every((slot) => {
      const hasValidDate = slot.date;
      const hasValidTimes = slot.timeArray.every(
        (timeSlot) => timeSlot.startTime && timeSlot.endTime
      );

      return hasValidDate && hasValidTimes;
    });

    if (!allFieldsFilled) {
      message.error("Please enter date and time", 3);
      return;
    }
    if (special) {
      setSpecialTime((prevState) => ({
        ...prevState,
        slot: [
          ...prevState.slot,
          { date: null, timeArray: [{ startTime: null, endTime: null }] },
        ],
      }));
    } else {
      setOffDays((prevState) => ({
        ...prevState,
        slot: [
          ...prevState.slot,
          { date: null, timeArray: [{ startTime: null, endTime: null }] },
        ],
      }));
    }
  };
  const handleAddTime = (special, idx) => {
    const slot = special ? specialTime.slot[idx] : offDays.slot[idx];

    const hasValidTimes = slot.timeArray.every(
      (timeSlot) => timeSlot.startTime && timeSlot.endTime
    );
    const hasValidDate = slot.date;

    if (!hasValidTimes && !hasValidDate) {
      message.error("Please enter date and time", 3);
      return;
    } else if (!hasValidDate) {
      message.error("Please enter date", 3);
      return;
    } else if (!hasValidTimes) {
      message.error("Please enter start time and end time", 3);
      return;
    }
    if (special) {
      setSpecialTime((prevState) => ({
        ...prevState,
        slot: prevState.slot.map((item, index) =>
          index === idx
            ? {
                ...item,
                timeArray: [
                  ...item.timeArray,
                  { startTime: null, endTime: null },
                ],
              }
            : item
        ),
      }));
    } else {
      setOffDays((prevState) => ({
        ...prevState,
        slot: prevState.slot.map((item, index) =>
          index === idx
            ? {
                ...item,
                timeArray: [
                  ...item.timeArray,
                  { startTime: null, endTime: null },
                ],
              }
            : item
        ),
      }));
    }
  };

  const handleStartCancel = () => {
    setTempStartTime(null);
    setIsStartOpen(false);
  };

  const handleEndCancel = () => {
    setTempEndTime(null);
    setIsEndOpen(false);
  };

  const handleStartConfirm = (day) => {
    if (tempStartTime) {
      handleTimeChange(day, "startTime", tempStartTime);
      setIsStartOpen(false);
    }
  };

  const handleEndConfirm = (day) => {
    if (tempEndTime) {
      handleTimeChange(day, "endTime", tempEndTime);
      setIsEndOpen(false);
    }
  };

  return (
    <AvailablityWrapper className="AvailablityWrapper">
      {isLoggedin && (
        <WeekWrapper className="WeekWrapper">
          {TimeOffData.map((item, index) => (
            <DayOffWrapper key={index}>
              <CommonSchibstedFontTextBold
                fontSize="16px"
                textColor="#848484"
                className="AvailabilityTitleStyle"
              >
                {item.title}
              </CommonSchibstedFontTextBold>
              <CommonDMFontTextRegular
                fontWeight={500}
                textColor="#666666"
                className="AvailabilitySubTitleStyle"
              >
                {item.subTitle}
              </CommonDMFontTextRegular>
              <ContentFlexWrapper className="Day-Wrapper AvailablityContentFlexWrapper">
                <CommonDMFontTextRegular fontWeight={500} textColor="#666666">
                  {item.text}
                </CommonDMFontTextRegular>
                <Switch
                  checked={
                    item?.text === "Extra Slot"
                      ? specialTime?.open
                      : offDays?.open
                  }
                  onChange={(state) =>
                    onToggle(state, item?.text === "Extra Slot", index)
                  }
                />
                {toggleStates[index] && (
                  <>
                    {item?.text === "Extra Slot" ? (
                      <>
                        {specialTime?.slot?.map((item, idx) => (
                          <AvailabilitySlot
                            key={`special-${idx}`}
                            item={item}
                            idx={idx}
                            isSpecial={true}
                            handleDateChange={handleOffSpecialDateChange}
                            handleTimeChange={handleOffSpecialTimeChange}
                            handleAddTime={handleAddTime}
                            onDateRemoveClick={handleDateRemoveClick}
                            onTimeRemoveClick={handleTimeRemoveClick}
                          />
                        ))}
                        <CustomArticleButton
                          buttonText="Add Day +"
                          closeBtn
                          borderColor="#425889"
                          className="Add-Btn-Style"
                          onClick={() => handleAddDay(true)}
                        />
                      </>
                    ) : (
                      <>
                        {offDays?.slot?.map((item, idx) => (
                          <AvailabilitySlot
                            key={`offDay-${idx}`}
                            item={item}
                            idx={idx}
                            isSpecial={false}
                            handleDateChange={handleOffSpecialDateChange}
                            handleTimeChange={handleOffSpecialTimeChange}
                            handleAddTime={handleAddTime}
                            onDateRemoveClick={handleDateRemoveClick}
                            onTimeRemoveClick={handleTimeRemoveClick}
                          />
                        ))}
                        <CustomArticleButton
                          buttonText="Add Day +"
                          closeBtn
                          borderColor="#425889"
                          className="Add-Btn-Style"
                          onClick={() => handleAddDay(false)}
                        />
                      </>
                    )}
                  </>
                )}
              </ContentFlexWrapper>
            </DayOffWrapper>
          ))}
        </WeekWrapper>
      )}
      <div style={{ marginBottom: "32px" }} className="PeriodWrapper">
        <CommonSchibstedFontTextBold
          fontSize="16px"
          textColor="#848484"
          className="AvailabilityTitleStyle"
        >
          Period of Consultation*
        </CommonSchibstedFontTextBold>
        <ContentFlexWrapper className="Availability-Content-Wrapper">
          {TIME_OPTIONS.map(({ label, minutes }) => (
            <ListText
              key={label}
              onClick={() => handleTimeSelected(label)}
              className={`Availability-Button-Style ${
                timeSelected === label ? "selected" : ""
              }`}
            >
              {label}
            </ListText>
          ))}
        </ContentFlexWrapper>
      </div>

      <div style={{ marginBottom: "4px" }} className="AvailableDayWrapper">
        <CommonSchibstedFontTextBold
          fontSize="16px"
          textColor="#848484"
          className="AvailabilityTitleStyle"
        >
          Availability
        </CommonSchibstedFontTextBold>
        <WeekWrapper className="WeekWrapper">
          {WeekData.map((day) => {
            const dayData = availability[day];
            return (
              <WeekDayWrapper
                key={day}
                style={{ display: "flex", alignItems: "center" }}
                className="WeekDayWrapper"
              >
                <ContentFlexWrapper className="Day-Wrapper">
                  <CommonDMFontTextRegular fontWeight={500} textColor="#666666">
                    {day}
                  </CommonDMFontTextRegular>
                  <Switch
                    checked={dayData.isAvailable}
                    onChange={(checked) => handleToggleChange(day, checked)}
                  />
                </ContentFlexWrapper>

                {dayData.isAvailable && (
                  <>
                    <ContentFlexWrapper className="Time-Wrapper">
                      <TimePicker
                        format="hh:mm A"
                        minuteStep={5}
                        use12Hours
                        inputReadOnly
                        value={dayData.startTime}
                        placeholder="Start Time"
                        onSelect={(time) => setTempStartTime(time)}
                        open={isStartOpen}
                        onOpenChange={setIsStartOpen}
                        popupClassName="custom-time-picker-dropdown"
                        panelRender={(originPanel) => (
                          <div style={{ width: "100%" }}>
                            {originPanel}
                            <SaveCancelButtons
                              className={"SaveCancelButtons"}
                              saveButtonTitle="Confirm"
                              onCancelClick={handleStartCancel}
                              onSaveClick={() => handleStartConfirm(day)}
                              isDisabled={!dayData.startTime && !tempStartTime}
                            />
                          </div>
                        )}
                      />
                      <span style={{ color: "#CCCCCC" }}>-</span>
                      <TimePicker
                        format="hh:mm A"
                        minuteStep={5}
                        use12Hours
                        inputReadOnly
                        value={dayData.endTime}
                        placeholder="End Time"
                        onSelect={(time) => setTempEndTime(time)}
                        disabled={!dayData.startTime}
                        open={isEndOpen}
                        onOpenChange={setIsEndOpen}
                        popupClassName="custom-time-picker-dropdown"
                        panelRender={(originPanel) => (
                          <div style={{ width: "100%" }}>
                            {originPanel}
                            <SaveCancelButtons
                              className={"SaveCancelButtons"}
                              saveButtonTitle="Confirm"
                              onCancelClick={handleEndCancel}
                              onSaveClick={() => handleEndConfirm(day)}
                              isDisabled={!dayData.endTime && !tempEndTime}
                            />
                          </div>
                        )}
                      />
                    </ContentFlexWrapper>

                    <ContentFlexWrapper className="Time-Wrapper">
                      {dayData.hasBreak ? (
                        <BreakTimePickers
                          day={day}
                          handleTimeChange={handleTimeChange}
                          toggleBreak={toggleBreak}
                          isTimeBetween={isTimeBetween}
                          availability={availability}
                        />
                      ) : (
                        <div className="FullWidth-Style">
                          <MenuItemWithIcon
                            className="CopyBreakWrapper"
                            onMenuClick={() => toggleBreak(day)}
                            buttonText="Add break"
                            svg={<CirclePlusIcon />}
                            textColor="#425889"
                          />
                        </div>
                      )}
                      <div className="FullWidth-Style">
                        <MenuItemWithIcon
                          className="CopyBreakWrapper"
                          onMenuClick={() => handleCopyToAll(day)}
                          buttonText="Copy to all"
                          svg={<CopyIcon />}
                          textColor="#425889"
                        />
                      </div>
                    </ContentFlexWrapper>
                  </>
                )}
              </WeekDayWrapper>
            );
          })}
        </WeekWrapper>
      </div>
    </AvailablityWrapper>
  );
};

export default Availability;
