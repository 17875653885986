import React from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { CustomButton } from "../../styledComponent";
import { Form } from "antd";
import styled from "styled-components";
import { useTherapistsApi } from "../../../../../hooks/useTherapistsApi";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

const StyledForm = styled(Form)`
  background-color: white;
  width: 500px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 100px;
  padding: 30px;
  border-radius: 10px;
`;

const StripePaymentGateway = ({ transactionId, onCancel }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { id, data: payloadData } = useParams();
  const history = useHistory();
  const dedcodedData = payloadData
    ? JSON.parse(atob(decodeURIComponent(payloadData)))
    : null;
  const { getPaymentStatus } = useTherapistsApi();
  console.log(transactionId, "transactionId");
  const handlePayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (result.error) {
      // Error
    } else {
      // verifyPayment(transactionId);
      getPaymentStatus(transactionId, (res) => {
        if (res) {
          const payload = {
            scheduleId: dedcodedData.scheduleId,
            startTime: dedcodedData.startTime,
            subscriptionPlanId: dedcodedData?.subscription?.id,
            paymentStatus: res,
            treatmentId: dedcodedData?.selectedSpeciality?._id,
          };
          const encodedData = encodeURIComponent(btoa(JSON.stringify(payload)));
          history.push(
            `/user/base/home/therapists/${id}/${res.message}/${encodedData}`
          );
        }
      });
    }
  };

  return (
    <StyledForm>
      <PaymentElement />
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <CustomButton
          onClick={onCancel}
          cancel={true}
          style={{ margin: 0, marginRight: "20px" }}
        >
          Cancel
        </CustomButton>
        <CustomButton onClick={handlePayment}>Submit</CustomButton>
      </div>
    </StyledForm>
  );
};
export default StripePaymentGateway;
