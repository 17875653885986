import React, { useEffect, useState } from "react";
import { message } from "antd";
import moment from "moment";
import {
  UpdateContentWrapper,
  UpdateCardContent,
  ConfirmedImageWrapper,
  StyledLoginSignupContainer,
} from "./styledComponents";
import { useHistory } from "react-router-dom";
import ApiCalls from "../../apis/ApiCalls.js";
import jwt from "jwt-decode";
import Name from "./ProfileInfo/Name.jsx";
import { emailValidation } from "../../utils/validation.js";
import BackButtonWithTitle from "../../components/BackButtonWithTitle/index.jsx";
import {
  CommonDMFontTextRegular,
  CommonSchibstedFontTextBold,
} from "../../utils/styledUtils.js";
import CustomArticleButton from "../../components/CustomArticleButton/index.jsx";
import TimeZoneFetcher from "../../components/TimeZoneFetcher/index.jsx";
import CustomModal from "../../components/CustomModal/index.jsx";
import CloseIcon from "../../assets/icons/CloseIcon.js";
import useLocationAndTimezone from "../../hooks/useLocationAndTimezone.jsx";
import { validatePassword } from "../../utils/commonFunctions.js";
import Lottie from "lottie-react";
import animationData from "../../assets/lottie/Blue-Confirm.json";
import { useMyAccountApi } from "../../hooks/useMyAccountApi.jsx";
import { useTherapistsApi } from "../../hooks/useTherapistsApi";
import { useParams } from "react-router";

const UpdateProfilePatient = () => {
  const { fetchLocationAndTimezone } = useLocationAndTimezone();
  const history = useHistory();
  const { action } = useParams();
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [location, setLocation] = useState({});
  const [savedTimezoneLocation, setSavedTimezoneLocation] = useState({});
  const [skipEmail, setSkipEmail] = useState(false);
  const [payload, setPayload] = useState({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const { updatePatientProfile, loading: isLoading } = useMyAccountApi();
  const { getPrebookingQuestions, prebookingQuestions } = useTherapistsApi();
  const [wellnessProfile, setWellnessProfile] = useState([]);
  let oauthData;

  const onContinue = async () => {
    try {
      if (page < 7) {
        switch (page) {
          case 1:
            await validateAndUpdateName();
            break;
          case 2:
            await validateAndUpdateGender();
            break;
          case 3:
            await validateAndUpdateAge();
            break;
          case 4:
            await validateAndUpdateTimeZone();
            break;
          case 5:
            await validateAndUpdateEmail();
            break;
          case 6:
            await validateAndUpdatePassword();
            break;
          default:
            message.error("Invalid page");
        }
      } else if (page >= 7 && page < 7 + prebookingQuestions.length) {
        const questionIndex = page - 7;
        console.log(questionIndex, "questionIndex");
        if (questionIndex + 1 === prebookingQuestions.length) {
          console.log(wellnessProfile, "wellnessProfile");
          if (wellnessProfile.length > 0) {
            console.log(wellnessProfile, "wellnessProfile1");
            updatePatientProfile({ wellnessProfile: wellnessProfile }, () => {
              history.push("/user/patient/update-profile/completed");
            });
          } else {
            history.push("/user/patient/update-profile/completed");
          }
        } else {
          setPage(page + 1);
        }
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const validateAndUpdateName = async () => {
    if (payload.name.trim().length < 2) {
      message.error("Please provide a valid name (at least 2 characters)");
    } else {
      await updateProfile({ name: payload.name.trim() });
      setPage(2);
    }
  };

  const validateAndUpdateGender = async () => {
    if (!["male", "female", "other"].includes(payload.gender.toLowerCase())) {
      message.error("Please select a valid gender");
    } else {
      await updateProfile({ gender: payload.gender.toLowerCase() });
      setPage(3);
    }
  };
  const validateAndUpdateAge = async () => {
    if (!payload.dob) {
      message.error("Please select a valid birth date");
      return;
    }

    const today = moment();
    const dobMoment = moment(payload.dob);

    if (!dobMoment.isValid()) {
      message.error("Invalid date format");
      return;
    }

    const age = today.diff(dobMoment, "years");

    if (age < 18 || age > 120) {
      message.error("Age must be between 18 and 120 years");
    } else {
      try {
        let formattedDob = payload.dob.toISOString();
        await updateProfile({ dob: formattedDob });
        localStorage.getItem("oauthData") &&
          history.push("/user/base/home/dashboard") &&
          window.fbq("track", "CompleteRegistration");
        !localStorage.getItem("oauthData") && setPage(4);
      } catch (error) {
        message.error("Failed to update profile");
      }
    }
  };
  const validateAndUpdateTimeZone = async () => {
    if (!payload.globalTimeZone) {
      message.error("Please select a valid Time Zone");
      return;
    }
    await updateProfile({}, payload.globalTimeZone);
    setPage(5);
  };

  const validateAndUpdateEmail = async () => {
    let isValidEmail = emailValidation(payload.email);
    if (isValidEmail === "Invalid email") {
      message.error("Please enter a valid email address");
    } else {
      await updateProfile({ email: payload.email.trim() });
      setPage(6);
    }
  };

  const validateAndUpdatePassword = async () => {
    const errorMessage = validatePassword(
      payload.password,
      payload.confirmPassword
    );
    if (errorMessage) {
      message.error(errorMessage);
      return;
    }
    await updateProfile({ password: payload.password });
    setPage(7);
    window.fbq("track", "CompleteRegistration");
  };

  const updateProfile = async (data, timeZone) => {
    const response = await ApiCalls.updatePatientProfile(data, timeZone);
    if (response.data.status !== 1) {
      message.error(response.data.message || "Failed to update profile");
    }
  };

  useEffect(() => {
    if (skipEmail === true) {
      setPage(page + 1);
      setSkipEmail(false);
    }
  }, [skipEmail]);
  useEffect(() => {
    try {
      const auth = jwt(localStorage.getItem("authToken"));
      if (auth.exp * 1000 < Date.now()) {
        history.push("/user/patient/signup");
      }
    } catch (e) {
      history.push("/user/patient/signup");
    }
  }, []);

  useEffect(() => {
    let page = localStorage.getItem("pageInfo");
    let pageInt = parseInt(page);
    if (pageInt) {
      if (action !== "completed") {
        setPage(pageInt + 1);
      }
    }

    oauthData = localStorage.getItem("oauthData");
    if (oauthData) {
      oauthData = JSON.parse(oauthData);
      setPayload((prevData) => ({
        ...prevData,
        name: oauthData.name,
        email: oauthData.email,
      }));
    }
    getPrebookingQuestions("wellness_profile");
  }, []);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLocation(newLocation);
          setLoading(false);
          if (newLocation) {
            fetchLocationAndTimezone(
              newLocation.lat,
              newLocation.lng,
              (data) => {
                setSavedTimezoneLocation((prevData) => ({
                  ...prevData,
                  timeZone: data.timezone,
                  location: data.location,
                  lat: newLocation.lat,
                  lng: newLocation.lng,
                  globalTimeZone: data.globalTimeZone,
                }));
                setPayload((prevData) => ({
                  ...prevData,
                  timeZone: data.timezone,
                  location: data.location,
                  lat: newLocation.lat,
                  lng: newLocation.lng,
                  globalTimeZone: data.globalTimeZone,
                }));
              }
            );
          }
        },
        () => {
          setLoading(false);
          fetchLocationAndTimezone(location.lat, location.lng, (data) => {
            setSavedTimezoneLocation((prevData) => ({
              ...prevData,
              timeZone: data.timezone,
              location: data.location,
              lat: location.lat,
              lng: location.lng,
              globalTimeZone: data.globalTimeZone,
            }));
            setPayload((prevData) => ({
              ...prevData,
              timeZone: data.timezone,
              location: data.location,
              lat: location.lat,
              lng: location.lng,
              globalTimeZone: data.globalTimeZone,
            }));
          });
        }
      );
    } else {
      setLoading(false);
      fetchLocationAndTimezone(location.lat, location.lng, (data) => {
        setSavedTimezoneLocation((prevData) => ({
          ...prevData,
          timeZone: data.timezone,
          location: data.location,
          lat: location.lat,
          lng: location.lng,
          globalTimeZone: data.globalTimeZone,
        }));
        setPayload((prevData) => ({
          ...prevData,
          timeZone: data.timezone,
          location: data.location,
          lat: location.lat,
          lng: location.lng,
          globalTimeZone: data.globalTimeZone,
        }));
      });
    }
  }, []);
  return (
    <>
      <StyledLoginSignupContainer>
        <BackButtonWithTitle
          title={"Basic Details"}
          isButtonRequired
          onClick={() => {
            if (page !== 1) {
              setPage(page - 1);
            } else if (action === "completed") {
              window.history.back();
              const totalPages = 6;
              const additionalPages =
                (prebookingQuestions && prebookingQuestions?.length) || 0;
              setPage(totalPages + additionalPages);
            } else {
              window.history.back();
            }
          }}
        />

        <UpdateContentWrapper
          className={`${
            action === "completed"
              ? "Updated-Wrapper-Style"
              : prebookingQuestions &&
                page >= 7 &&
                page < 7 + prebookingQuestions.length
              ? "Update-Long-Content-Style"
              : "Update-Content-Wrapper"
          } pageInfo`}
        >
          {action === "completed" && (
            <ConfirmedImageWrapper>
              <Lottie animationData={animationData} loop={true} />
            </ConfirmedImageWrapper>
          )}
          <CommonSchibstedFontTextBold
            fontSize={action === "completed" ? "28px" : "32px"}
            className="Title-Style"
            style={{ textAlign: "center" }}
          >
            {prebookingQuestions &&
            page >= 7 &&
            page < 7 + prebookingQuestions.length
              ? "Your Wellness Profile"
              : action === "completed"
              ? "You’re All Set!"
              : "Let's get to know you properly"}
          </CommonSchibstedFontTextBold>
          <CommonDMFontTextRegular
            className="Sub-Title-Style"
            fontWeight={action === "completed" ? 300 : 500}
            style={{ textAlign: "center" }}
          >
            {action === "completed"
              ? "Your profile has been created successfully"
              : `Answer a few quick questions so we can tailor your experience to fit
            your needs and preferences.`}
          </CommonDMFontTextRegular>
          {action === "completed" ? (
            <CustomArticleButton
              buttonText={"finish"}
              onClick={() => {
                history.push("/user/base/home/dashboard");
              }}
              className={"Dashboard-Redirect-Btn-Style"}
            />
          ) : (
            <UpdateCardContent className="UpdateCardContent">
              <div
                className="Progress-Wrapper"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexWrap: "wrap",
                  padding: " 0px 10px",
                }}
              >
                <BackButtonWithTitle
                  stroke={"rgba(255, 255, 255, 1)"}
                  backgroundColor={
                    page > 1 ? "rgba(66, 88, 137, 1)" : "rgba(204, 204, 204, 1)"
                  }
                  isButtonRequired
                  onClick={() => {
                    if (page !== 1) {
                      setPage(page - 1);
                    }
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    width: "calc(100% - 50px)",
                  }}
                >
                  {localStorage.getItem("oauthData")
                    ? [1, 2, 3].map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: "100px",
                              maxWidth: "calc(20% - 10px)",
                              height: "10px",
                              borderRadius: "100px",
                              backgroundColor:
                                page >= index + 1 ? "#74ABE8" : "#E3E3E3",
                              flexGrow: 1,
                              flexShrink: 1,
                              flexBasis: "0%",
                            }}
                          ></div>
                        );
                      })
                    : (() => {
                        const totalPages = 6;
                        const additionalPages =
                          prebookingQuestions?.length || 0;

                        return [...Array(totalPages + additionalPages)].map(
                          (_, index) => (
                            <div
                              key={index}
                              style={{
                                width: "100px",
                                maxWidth: "calc(20% - 10px)",
                                height: "6px",
                                borderRadius: "100px",
                                backgroundColor:
                                  page >= index + 1
                                    ? "rgba(66, 88, 137, 1)"
                                    : "rgba(255, 255, 255, 1)",
                                flexGrow: 1,
                                flexShrink: 1,
                                flexBasis: "0%",
                              }}
                            ></div>
                          )
                        );
                      })()}
                </div>
              </div>
              <div>
                {page && (
                  <Name
                    page={page}
                    payload={payload}
                    setPayload={setPayload}
                    setIsMapOpen={setIsMapOpen}
                    wellnessProfile={wellnessProfile}
                    setWellnessProfile={setWellnessProfile}
                    prebookingQuestions={prebookingQuestions}
                  />
                )}
              </div>
              {(page === 5 || page === 7 || page === 8 || page === 9) && (
                <CustomArticleButton
                  closeBtn
                  buttonText={"skip for now"}
                  onClick={() => {
                    if (page >= 7 && page < 7 + prebookingQuestions.length) {
                      const questionIndex = page - 7;
                      if (questionIndex + 1 === prebookingQuestions.length) {
                        console.log(wellnessProfile, "wellnessProfile");
                        if (wellnessProfile.length > 0) {
                          updatePatientProfile(
                            { wellnessProfile: wellnessProfile },
                            () => {
                              history.push(
                                "/user/patient/update-profile/completed"
                              );
                            }
                          );
                        } else {
                          history.push(
                            "/user/patient/update-profile/completed"
                          );
                        }
                      } else {
                        setPage(page + 1);
                      }
                    }
                    setSkipEmail(true);
                  }}
                  style={{ width: "100%", height: "48px" }}
                />
              )}
              <CustomArticleButton
                buttonText={"CONTINUE"}
                onClick={() => onContinue()}
                style={{ width: "100%", height: "48px" }}
              />
            </UpdateCardContent>
          )}
        </UpdateContentWrapper>
        <CustomModal
          visible={isMapOpen}
          title={"Select Location"}
          okLabel={"continue"}
          onOk={() => {
            setIsMapOpen(false);
            if (savedTimezoneLocation) {
              setPayload((prevData) => ({
                ...prevData,
                timeZone: savedTimezoneLocation.timeZone,
                globalTimeZone: savedTimezoneLocation.globalTimeZone,
              }));
            }
          }}
          closeModal={() => {
            setIsMapOpen(false);
            if (savedTimezoneLocation) {
              setPayload((prevData) => ({
                ...prevData,
                timeZone: savedTimezoneLocation.timeZone,
                globalTimeZone: savedTimezoneLocation.globalTimeZone,
              }));
            }
          }}
          showOne={true}
          closeIcon={<CloseIcon stroke={"rgba(0, 0, 0, 1)"} />}
          className={"Styled-Location-Tracker-Popup"}
          disabled={savedTimezoneLocation.timeZone === ""}
        >
          <TimeZoneFetcher
            savedTimezoneLocation={savedTimezoneLocation}
            setSavedTimezoneLocation={setSavedTimezoneLocation}
            location={location}
            setLocation={setLocation}
          />
        </CustomModal>
      </StyledLoginSignupContainer>
    </>
  );
};

export default UpdateProfilePatient;
