import React, { useState, useEffect } from "react";
import { Form, Input, message, Spin } from "antd";
import PhoneInput from "react-phone-input-2";
import { StyledRow, StyledColumn } from "../BasicProfile/styledComponents";
import { StyledFormItem } from "../../signup/styledComponents";
import {
  FormContainer,
  ButtonContainer,
  StyledButton,
  SpinContainer,
} from "./styledComponents";
import ApiCalls from "../../../apis/ApiCalls";
import UploadFile from "../BasicProfile/UploadFile";

const AddExperience = ({
  setAddNew,
  fetchWorkProfile,
  setEditItem,
  selectedItem,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [phoneNoFormat, setPhoneNoFormat] = useState(null);

  const [certificationFile, setCertificationFile] = useState(null);
  const [uploadCertificateItem, setUploadCertificateItem] = useState(null);
  const [saveCertificateFile, setSaveCertificateFile] = useState(false);
  const [fileForm, setFileForm] = useState();
  const [img, setImg] = useState();

  const onFinish = (val) => {
    const data = { experienceDetails: { ...val } };
    if (selectedItem) data.experienceDetails._id = selectedItem._id;

    setLoading(true);
    if (certificationFile || img) {
      ApiCalls.updateDoctorExtraInfo(data)
        .then((response) => {
          if (response.data.status) {
            message.success(
              `${selectedItem ? "Edited succesfully" : "Added successfully"}`
            );
            fetchWorkProfile(setLoading, setAddNew, setEditItem);
            setSaveCertificateFile(true);
          } else message.error(response.data.data.message);
        })
        .catch((error) => {
          setLoading(false);
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    } else {
      setLoading(false);
      message.error(
        "Please upload experience document",
        5
      );
    }
  };

  useEffect(() => {
    selectedItem && form.setFieldsValue(selectedItem);
    selectedItem && setImg(selectedItem?.url);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <FormContainer>
        {loading ? (
          <SpinContainer>
            <Spin spinning={true} />
          </SpinContainer>
        ) : (
          <div
            style={{ display: "flex", gap: "12px", flexDirection: "column" }}
          >
            <UploadFile
              file={certificationFile}
              setFile={setCertificationFile}
              title="Upload Certification Image"
              info="Please upload Certification image that must be readable"
              itemId={uploadCertificateItem}
              type="experienceDetails"
              fetchWorkProfile={fetchWorkProfile}
              setSelectedItem={setUploadCertificateItem}
              triggerUpload={saveCertificateFile}
              fileForm={fileForm}
              setFileForm={setFileForm}
              image={img}
            />

            <Form form={form} onFinish={onFinish}>
              <StyledRow>
                <StyledColumn>
                  <StyledFormItem
                    name="hospitalName"
                    label="Hospital/Organization Name"
                    type2={true}
                  >
                    <Input placeholder="Enter hospital name" autoFocus={true} />
                  </StyledFormItem>
                </StyledColumn>
                <StyledColumn>
                  <StyledFormItem
                    name="hospitalAddress"
                    label="Hospital/Organization Address"
                    type2={true}
                  >
                    <Input placeholder="Enter hospital address" />
                  </StyledFormItem>
                </StyledColumn>
              </StyledRow>
              <StyledRow>
                <StyledColumn>
                  <StyledFormItem
                    name="referencePerson"
                    label="Reference Person"
                    type2={true}
                  >
                    <Input placeholder="Enter name of reference person " />
                  </StyledFormItem>
                </StyledColumn>
                <StyledColumn>
                  <StyledFormItem
                    name="contactNumber"
                    label="Contact Number"
                    type2={true}
                  >
                    <PhoneInput
                      placeholder="Enter contact number"
                      inputProps={{
                        name: "contactNumber",
                        required: true,
                      }}
                      country="ae"
                      onChange={(val, val2) => setPhoneNoFormat(val2)}
                      type2={true}
                    />
                  </StyledFormItem>
                </StyledColumn>
              </StyledRow>
              <StyledRow submit={true}>
                <ButtonContainer>
                  <StyledButton
                    onClick={() =>
                      selectedItem ? setEditItem(null) : setAddNew(false)
                    }
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton htmlType={true} type="primary">
                    {selectedItem ? "Done" : "Add"}
                  </StyledButton>
                </ButtonContainer>
              </StyledRow>
            </Form>
          </div>
        )}
      </FormContainer>
    </>
  );
};

export default AddExperience;
