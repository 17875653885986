import React, { useEffect, useState } from "react";
import {
  EducationExperienceWrapper,
  FlexContent,
  InputWrapper,
} from "../styledComponents";
import { Input, message } from "antd";
import { useCommonLookupsApi } from "../../../hooks/useCommonLookupsApi";
import CustomArticleButton from "../../../components/CustomArticleButton";
import TherapistStatCard from "../../../components/TherapistStatCard";
import { capitalizeTitle } from "../../../utils/commonFunctions";
import { CommonSchibstedFontTextBold } from "../../../utils/styledUtils";
import CustomGroupedInputDropdown from "../../../components/CustomGroupedInputDropdown";
import CustomModal from "../../../components/CustomModal";
import CloseIcon from "../../../assets/icons/CloseIcon";
import { useDocOnboardingApi } from "../../../hooks/useDocOnboardingApi";

const PricingDetail = ({
  pricePayload,
  setPricePayload,
  pricingInputPlaceholder,
  SpecializationPopupClassName,
  docProfileDetails,
}) => {
  const [editId, setEditId] = useState(null);
  const { getCurrencies, currencies } = useCommonLookupsApi();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [newTreatment, setNewTreatment] = useState({
    treatmentName: "",
    treatmentPrice: 0,
    currency: "",
  });

  const {
    addDoctorSpecialization,
    getDoctorSpecializations,
    treatmentList,
    updateDoctorSpecialization,
    deleteDoctorSpecialization,
  } = useDocOnboardingApi();
  const handleAddOrUpdateTreatment = () => {
    if (!newTreatment.treatmentName || !newTreatment.treatmentPrice) {
      message.error("Please fill all fields before adding.");
      return;
    }
    if (editId) {
      const { _id, normalizedTreatmentFee, currency, ...rest } = newTreatment;
      updateDoctorSpecialization({ ...rest, treatmentId: _id }, () => {
        getDoctorSpecializations();
        setIsPopupVisible(false);
        setNewTreatment({
          treatmentName: "",
          treatmentPrice: "",
          currency: "",
        });
        setEditId(null);
      });
    } else {
      const { currency, ...rest } = newTreatment;
      addDoctorSpecialization(rest, () => {
        getDoctorSpecializations();
        setIsPopupVisible(false);
        setNewTreatment({
          treatmentName: "",
          treatmentPrice: "",
          currency: "",
        });
        setEditId(null);
      });
    }
  };

  const handleEditTreatment = (id) => {
    const treatmentToEdit = treatmentList?.treatments.find((t) => t._id === id);
    if (treatmentToEdit) {
      setNewTreatment({ ...treatmentToEdit });
      setEditId(id);
      setIsPopupVisible(true);
    }
  };

  const handleDeleteTreatment = (id) => {
    deleteDoctorSpecialization({ treatmentId: id }, () => {
      getDoctorSpecializations();
    });
  };

  const onCloseClick = () => {
    setIsPopupVisible(false);
    setNewTreatment({
      treatmentName: "",
      treatmentPrice: "",
      currency: "",
    });
    setEditId(null);
  };

  useEffect(() => {
    getCurrencies();
    getDoctorSpecializations();
  }, []);
  console.log(pricePayload, "pricePayload");
  return (
    <>
      <EducationExperienceWrapper className="PricingDetailWrapper">
        <FlexContent className="Price-Flex-Style">
          <CommonSchibstedFontTextBold fontSize="16px" textColor="#848484">
            Price per hour (General Therapy)
          </CommonSchibstedFontTextBold>
          <CustomGroupedInputDropdown
            className="CustomGroupedInputDropdown"
            options={currencies}
            onDropdownChange={(e) => {
              setPricePayload((prevData) => ({ ...prevData, currency: e }));
            }}
            onTextChange={(e) => {
              const value = e.target.value;
              if (value === "" || /^[0-9]+$/.test(value)) {
                setPricePayload((prevData) => ({
                  ...prevData,
                  consultationFee: value,
                }));
              }
            }}
            inputValue={pricePayload.consultationFee ?? ""}
            dropdownValue={pricePayload.currency}
            dropdownDisabled={true}
          />
        </FlexContent>

        <FlexContent className="Price-Flex-Style">
          <CommonSchibstedFontTextBold fontSize="16px" textColor="#848484">
            Specialities
          </CommonSchibstedFontTextBold>
          {treatmentList?.treatments?.length > 0 &&
            treatmentList.treatments.map((item) => (
              <TherapistStatCard
                className="TherapistStatCard-Style"
                key={item._id}
                item={item}
                title={capitalizeTitle(item.treatmentName)}
                subTitle={`${item.treatmentPrice} ${pricePayload.currency}/hr`}
                onEditClick={() => handleEditTreatment(item._id)}
                onCloseClick={() => handleDeleteTreatment(item._id)}
              />
            ))}
          <CustomArticleButton
            buttonText="add +"
            closeBtn
            borderColor="#425889"
            className="Add-Btn-Style"
            onClick={() => {
              setIsPopupVisible(true);
            }}
          />
        </FlexContent>
      </EducationExperienceWrapper>

      <CustomModal
        visible={isPopupVisible}
        title={`${editId ? "Edit" : "Add"} Specialization`}
        okLabel={editId ? "Update" : "Add"}
        onOk={handleAddOrUpdateTreatment}
        closeModal={onCloseClick}
        onCancel={onCloseClick}
        className={`${SpecializationPopupClassName} AddEditEducationExperiencePopup-Style AddEditSpecPopup-Style`}
        cancelLabel="Cancel"
        closeIcon={<CloseIcon stroke="#747474" width="18px" height="18px" />}
      >
        <InputWrapper>
          <Input
            placeholder="Name of the specialized therapy"
            onChange={(e) => {
              setNewTreatment((prev) => ({
                ...prev,
                treatmentName: e.target.value,
              }));
            }}
            value={newTreatment.treatmentName}
          />
        </InputWrapper>
        <CustomGroupedInputDropdown
          inputPlaceholder={
            pricingInputPlaceholder ? pricingInputPlaceholder : "Price"
          }
          options={currencies}
          onDropdownChange={(e) => {
            setNewTreatment((prev) => ({ ...prev, currency: e }));
          }}
          onTextChange={(e) => {
            const numericValue = e.target.value.replace(/\D/g, "");
            setNewTreatment((prev) => ({
              ...prev,
              treatmentPrice: numericValue,
            }));
          }}
          inputValue={
            newTreatment.treatmentPrice === 0 ? "" : newTreatment.treatmentPrice
          }
          dropdownValue={pricePayload.currency}
          dropdownDisabled={true}
        />
      </CustomModal>
    </>
  );
};

export default PricingDetail;
