import styled from "styled-components";
import { Modal } from "antd";

export const StyledModal = styled(Modal)`
  width: ${(props) =>
    props.width
      ? props.width
      : props.map
      ? "1000px !important"
      : props.availability
      ? "430px !important"
      : props.fullScreen
      ? "1300px !important"
      : ""};
  height: ${(props) => (props.map ? "90vh !important" : "")};
  padding-bottom: 0;
  .selected {
    font-size: 16px;
    font-weight: 700;
    font-family: Metropolis Bold;
    background-color: #425889;
    border-color: #425889;
    color: #ffffff;
    span {
      color: #ffffff;
    }
  }
  .CustomArticleChip {
    width: 50%;
    height: 48px;
  }
  & .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
  }

  & .ant-modal-header {
    border-bottom: none;
    padding: 30px 30px 20px 30px;
  }

  & .ant-modal-title {
    font-size: 23px;
    font-family: Metropolis Bold;
    color: #35364f;
  }

  & .ant-modal-body {
    padding: 0 30px;
  }

  & .ant-modal-footer {
    display: ${(props) => (props.hideFooter ? "none" : "flex")};
    justify-content: space-between;
    align-items: flex-start;
    border-top: none;
    padding: 30px;
  }

  & .ant-radio-group {
    width: 100%;
  }
`;
export const TimeContainer = styled.div`
  display: flex;
  gap: 8px;
  scroll-behavior: smooth;
  overflow: auto;
  cursor: pointer;
  margin-top: 48px;
`;
export const ButtonContainer = styled.div`
  width: ${(props) =>
    props.showOne ? "100%" : props.type === "NormalPatient" ? "30%" : "47%"};
`;
