import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import CloseIcon from "../../../assets/icons/CloseIcon";
import CustomUserInputField from "../../../components/CustomUserInputField";
import UploadFile from "../../../components/UploadFile";
import { Form, message } from "antd";
import { useDocOnboardingApi } from "../../../hooks/useDocOnboardingApi";
import { getFormattedFileName } from "../../../utils/commonFunctions";

const AddEditEducationExperiencePopup = ({
  visible,
  onCloseClick,
  className,
  okLabel,
  title,
  cancelLabel,
  fieldData,
  setIsDetailUpdated,
  data,
  setIsVisible,
  setAddNewExp,
  selectedItem,
  setSelectItem,
  addNewExp,
  docProfileDetails,
  setDocFile,
  docFile,
  selectedFile,
  setSelectedFile,
}) => {
  const [form] = Form.useForm();
  const { updateDocExtraInfo, imageUpload } = useDocOnboardingApi();

  const onFinish = (val) => {
    const data = addNewExp
      ? { experienceDetails: { ...val } }
      : { educationalDetails: { ...val } };

    if (selectedItem?._id) {
      addNewExp
        ? (data.experienceDetails._id = selectedItem._id)
        : (data.educationalDetails._id = selectedItem._id);
    }

    if (addNewExp) {
      if (!val.hospitalName || !val.hospitalAddress) {
        return message.error("Please enter hospital name and address", 5);
      }
    } else {
      if (!val.instituteName || !val.instituteAddress || !val.qualifiction) {
        return message.error("Please enter all the details", 5);
      }
    }
    if (!selectedItem?._id && !selectedFile) {
      return message.error("Please upload the document", 5);
    }

    if (selectedItem?._id || selectedFile) {
      const formData = new FormData();
      if (addNewExp) {
        formData.append("type", "experienceDetails");
      } else {
        formData.append("type", "educationalDetails");
      }
      formData.append("uploadFile", selectedFile.file);
      if (selectedItem?._id) {
        if (selectedFile) {
          updateDocExtraInfo(data, selectedItem, () => {
            imageUpload(formData, () => {
              setIsVisible(false);
              setAddNewExp(false);
              setIsDetailUpdated((prevData) => !prevData);
              form.resetFields();
              setSelectedFile("");
              setDocFile({});
              setSelectItem("");
            });
          });
        } else {
          updateDocExtraInfo(data, selectedItem, () => {
            setIsVisible(false);
            setAddNewExp(false);
            setIsDetailUpdated((prevData) => !prevData);
            form.resetFields();
            setSelectedFile("");
            setDocFile({});
            setSelectItem("");
          });
        }
      } else {
        updateDocExtraInfo(data, selectedItem, () => {
          imageUpload(formData, () => {
            setIsVisible(false);
            setAddNewExp(false);
            setIsDetailUpdated((prevData) => !prevData);
            form.resetFields();
            setSelectedFile("");
            setDocFile({});
            setSelectItem("");
          });
        });
      }
    }
  };
  useEffect(() => {
    const matchedData = data?.find(
      (item) => item?._id?.toString() === selectedItem?._id?.toString()
    );
    if (matchedData) {
      setDocFile((prevData) => ({
        ...prevData,
        uploadDate: matchedData?.lastModified,
        fileName: getFormattedFileName(matchedData?.url),
      }));

      form.setFieldsValue(matchedData);
    } else {
      form.resetFields();
    }
  }, [data, selectedItem, form]);
  useEffect(() => {
    if (docProfileDetails && selectedItem)
      setDocFile((prevData) => ({
        ...prevData,
        name: docProfileDetails.name,
      }));
    else {
      setDocFile({});
    }
  }, [docProfileDetails, selectedItem]);
  return (
    <CustomModal
      visible={visible}
      title={title}
      okLabel={okLabel}
      onOk={() => {
        form.submit();
      }}
      closeModal={onCloseClick}
      onCancel={onCloseClick}
      className={`${className} AddEditEducationExperiencePopup-Style`}
      cancelLabel={cancelLabel}
      closeIcon={
        <CloseIcon stroke={"#747474"} width={"18px"} height={"18px"} />
      }
    >
      <Form form={form} onFinish={onFinish}>
        {fieldData &&
          fieldData.map((item) => {
            return (
              <CustomUserInputField
                type2={true}
                name={item.name}
                key={item._id}
                placeholder={item.placeholder}
                isPhoneField={item.name === "contactNumber"}
              />
            );
          })}
      </Form>
      <div>
        <UploadFile
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          defaultData={docFile}
        />
      </div>
    </CustomModal>
  );
};

export default AddEditEducationExperiencePopup;
