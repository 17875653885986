import styled from "styled-components";

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  @media only screen and (max-width: 429px) {
    align-items: start;
  }
`;
export const PageTitle = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "28px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "700")};
  margin-bottom: 0px;
  color: #000000;
  cursor: default;
  font-family: "Schibsted Grotesk";
`;
