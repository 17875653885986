import styled from "styled-components";
import { Button } from "antd";

export const LoginContentContainer = styled.div`
  height: calc(100% - 72px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  @media only screen and (max-width: 429px) {
    .IllustrationContainer {
      .Card-Title {
        font-size: 26px;
      }
    }
  }
  @media (min-width: 430px) and (max-width: 900px) {
    .IllustrationContainer {
      .Card-Title {
        font-size: 28px;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    height: auto;
    padding-bottom: 30px;
    .IllustrationContainer {
      justify-content: center;
      padding: 0;
      width: 100%;
      :nth-child(1) {
        height: 40%;
        max-width: 353px;
      }
      :nth-child(2) {
        height: auto;
      }
      :has(.Signup-Bg-Style) {
        &:nth-child(1) {
          max-width: 300px;
        }
      }
      .IllustrationImage {
        margin: 0px;
      }
    }
    .LoginSignupCardWrapper {
      max-width: unset;
      padding: 30px 20px;
    }
    .Outside-Button-Style {
      margin: 30px 0 0;
    }
    .Signup-Otp-Wrapper .otp-container input {
      width: 45px;
      height: 45px;
    }
  }
  @media (min-width: 901px) and (max-width: 1200px) {
    .IllustrationContainer {
      padding: 16px;
      :nth-child(1) {
        width: 40%;
      }
      :nth-child(2) {
        width: 60%;
      }
      .Card-Title {
        font-size: 30px;
      }
      .IllustrationImage {
        margin-bottom: 30%;
      }
    }
  }
  @media only screen and (max-width: 1599px) {
    .IllustrationContainer {
      justify-content: center;

      .IllustrationImage {
        max-width: unset;
        max-height: unset;
        width: 100%;
        height: calc(100% - 20%);
      }
    }
  }
`;
export const IllustrationContainer = styled.div`
  display: flex;
  padding: 16px 20px;
  gap: 10px;
  height: 100%;
  width: 50%;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || "center"};
  flex-direction: ${(props) => props.flexDirection || "row"};
  .Outside-Button-Style {
    color: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    font: 700 15px Schibsted Grotesk;
  }
  .Login-Signup-Button-Wrapper {
    button {
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;

export const IllustrationImage = styled.img`
  width: 100%;
  height: 100%;
  margin-bottom: 20%;
  max-width: 647px;
  max-height: 640px;
  object-fit: contain;
  &.Doc-Bg-Img-Style {
    max-width: 730px;
    max-height: 683px;
    margin-bottom: 0;
    margin-top: 7%;
  }
`;
export const LoginSignupCardWrapper = styled.div`
  background-color: rgba(244, 247, 252, 1);
  border-radius: 16px;
  height: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  max-width: 493px;
  width: 100%;
  .Hide-Buttons-Style {
    .Cancel-Button-Style {
      display: none;
    }
  }
  .ContinueButtonStyle {
    width: 100%;
    height: 48px;
  }
  .Auth-Content-Wrapper {
    width: 100%;
    input {
      padding: 16px;
      background-color: rgba(251, 251, 251, 1);
      color: rgba(0, 0, 0, 1);
      font: 500 16px Schibsted Grotesk;
      &::placeholder {
        color: rgba(153, 153, 153, 1);
        font: 500 16px Schibsted Grotesk;
      }
    }
    .Password-Style {
      margin-top: 12px;
      width: 100%;
      input {
        padding: 16px 16px 16px 45px;
      }
      .ant-input-suffix {
        margin: 4px 16px 4px 4px;
      }
      .ant-input-password {
        padding: 0px;
      }
      .ant-row {
        margin: 0px;
        border-radius: 4px;
      }
      .ant-input-affix-wrapper {
        background-color: rgba(251, 251, 251, 1) !important;
        border-color: rgba(251, 251, 251, 1);
        &:hover,
        &:focus {
          border-color: rgba(251, 251, 251, 1);
          box-shadow: none;
          border-right-width: 1px !important;
        }
      }
    }
  }

  .Already-Login-Style {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8%;
    justify-content: space-between;
    p {
      width: calc(100% - 135px);
    }
    button {
      padding: 0px;
      display: flex;
      align-items: start;
      font: 600 16px "DM Sans";
      text-transform: capitalize;
      height: fit-content;
    }
    .Agreement-Checkbox {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .ant-checkbox + span {
        font: 500 16px "Dm Sans";
      }
      .ant-checkbox-inner {
        top: 1px;
        width: 18px;
        height: 18px;
      }
    }
    .Forgot-Button-Style {
      width: 50%;
      margin-top: 36px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .Card-Text-Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
    p {
      text-align: center;
      width: 100%;
      &:nth-child(1) {
        line-height: 40px;
      }
      &:nth-child(2) {
        line-height: 23px;
      }
    }
  }
  .Login-Signup-Button-Wrapper {
    flex-direction: column;
    width: 100%;
    button {
      max-width: unset;
      height: 56px;
    }
  }
  .Signup-Otp-Wrapper {
    .otp-container {
      input {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
  .CustomUserInputField {
    width: 100%;
    position: relative;
    .form-control {
      padding: 18px 16px;
      width: 100%;
      height: 54px;
      border-color: rgba(255, 255, 255, 1);
      color: rgba(0, 0, 0, 1);
      font: 500 16px Schibsted Grotesk;
      &::placeholder {
        color: rgba(153, 153, 153, 1);
        font: 500 16px Schibsted Grotesk;
      }
    }
    .flag-dropdown {
      border: none;
      .selected-flag {
        display: none;
      }
    }
    .Close-Icon-Style {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background: rgba(194, 70, 70, 1);
      border-radius: 100px;
      width: 21px;
      height: 21px;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .CustomPasswordField {
    position: relative;
    .Custom-Lock-Style {
      position: absolute;
      left: 17px;
      top: 20px;
      z-index: 1;
    }
  }
  .Agreement-Checkbox {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: rgba(66, 88, 137, 1);
      border-color: rgba(66, 88, 137, 1);
    }
    .Agreement-Text-Style {
      font: 500 16px Schibsted Grotesk;
      a {
        font: 400 16px Schibsted Grotesk;
        color: #2c509f;
      }
    }
  }
`;
export const StyledButton = styled(Button)`
  margin-top: ${(props) => props.marginTop || "55px"};
  font: 800 14px Schibsted Grotesk;
  color: rgba(66, 88, 137, 1);
`;
