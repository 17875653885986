import React, { useEffect, useState } from "react";
import CustomArticleButton from "../../../components/CustomArticleButton";
import {
  EducationExperienceWrapper,
  FlexContent,
  ContentFlexWrapper,
  IconWrapper,
  InputWrapper,
} from "../styledComponents";
import { CommonSchibstedFontTextBold } from "../../../utils/styledUtils";
import CloseIcon from "../../../assets/icons/CloseIcon";
import AddSpecLanguagePopup from "./AddSpecLanguagePopup";
import { useCommonLookupsApi } from "../../../hooks/useCommonLookupsApi";
import { Input } from "antd";
import { capitalizeTitle } from "../../../utils/commonFunctions";

const ServicesAndSpeciality = ({
  docProfileDetails,
  setIsDetailUpdated,
  setServicePayload,
  servicePayload,
}) => {
  const { getSpecialisationList, specialities, getLanguageList, languages } =
    useCommonLookupsApi();
  const [isPopup, setIsPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [addNewLanguage, setAddNewLanguage] = useState(false);
  const sections = [
    {
      label: "Specialities*",
      data: servicePayload.specializationFilterData,
      isLanguage: false,
      type: specialities,
    },
    {
      label: "Languages*",
      data: servicePayload.languagesFilterData,
      isLanguage: true,
      type: languages,
    },
  ];
  const onDeleteClick = (id, type) => {
    setServicePayload((prevData) => {
      if (type === languages) {
        return {
          ...prevData,
          languagesFilterData: prevData.languagesFilterData.includes(id)
            ? prevData.languagesFilterData.filter((itemId) => itemId !== id)
            : [...prevData.languagesFilterData, id],
        };
      } else {
        return {
          ...prevData,
          specializationFilterData: prevData.specializationFilterData.includes(
            id
          )
            ? prevData.specializationFilterData.filter(
                (itemId) => itemId !== id
              )
            : [...prevData.specializationFilterData, id],
        };
      }
    });
  };
  useEffect(() => {
    getSpecialisationList();
    getLanguageList();
  }, []);

  return (
    <EducationExperienceWrapper className="ServiceSpecialityWrapper">
      {sections.map((section, idx) => (
        <FlexContent key={idx}>
          <CommonSchibstedFontTextBold
            fontSize="16px"
            textColor="#848484"
            className="ContentTitleStyle"
          >
            {section.label}
          </CommonSchibstedFontTextBold>
          <div className="Flex-Info-Btn-Style">
            <ContentFlexWrapper>
              {section.data &&
                section.data.map((itemId, index) => {
                  const matchedItem = section.type.find(
                    (i) => i.language === itemId || i._id === itemId
                  );

                  return (
                    matchedItem && (
                      <CustomArticleButton
                        key={index}
                        className={"Service-Button-Style"}
                        closeBtn
                        buttonText={
                          <>
                            <p>{matchedItem.language ?? matchedItem.name}</p>
                            <IconWrapper
                              onClick={() => {
                                onDeleteClick(
                                  matchedItem.language ?? matchedItem._id,
                                  section.type
                                );
                              }}
                            >
                              <CloseIcon width={"10px"} height={"10px"} />
                            </IconWrapper>
                          </>
                        }
                      />
                    )
                  );
                })}
            </ContentFlexWrapper>
            <CustomArticleButton
              buttonText="add +"
              closeBtn
              borderColor="#425889"
              className="Add-Btn-Style"
              onClick={() => {
                setIsPopup(true);
                setAddNewLanguage(section.isLanguage);
              }}
            />
          </div>
        </FlexContent>
      ))}
      <InputWrapper className="ProfessionalInputWrapper">
        <CommonSchibstedFontTextBold
          fontSize="16px"
          textColor="#848484"
          className="ContentTitleStyle"
        >
          Professional Title*
        </CommonSchibstedFontTextBold>
        <Input
          placeholder={"Professional Title"}
          onChange={(e) => {
            setServicePayload((prevData) => ({
              ...prevData,
              professionalTitle: e.target.value,
            }));
          }}
          value={capitalizeTitle(servicePayload.professionalTitle)}
        />
      </InputWrapper>
      <AddSpecLanguagePopup
        setAddNewLanguage={setAddNewLanguage}
        setIsVisible={setIsPopup}
        visible={isPopup}
        onCloseClick={() => {
          setIsPopup(false);
          setAddNewLanguage(false);
          setSearchTerm("");
        }}
        onOkClick={() => {
          setIsPopup(false);
          setAddNewLanguage(false);
          setSearchTerm("");
        }}
        okLabel={"Add"}
        title={`Add ${addNewLanguage ? "Languages" : "Specializations"}`}
        cancelLabel={"Cancel"}
        data={addNewLanguage ? languages : specialities}
        addNewLanguage={addNewLanguage}
        servicePayload={servicePayload}
        setServicePayload={setServicePayload}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      />
    </EducationExperienceWrapper>
  );
};

export default ServicesAndSpeciality;
