import React, { useState, useEffect } from "react";
import { PageLayout } from "./styledComponents";
import ProgressTab from "./ProgressTab";
import UpdateDetails from "./UpdateDetails";
import UpdateProfileCompleted from "./UpdateProfileCompleted";
import { useLocation, useHistory } from "react-router-dom";
import jwt from "jwt-decode";

const UpdateProfileDoctor = () => {
  const location = useLocation();
  const [lastCompletedPage, setLastCompletedPage] = useState(
    location.state ? location.state.page : 0
  );
  const [currentPage, setCurrentPage] = useState(0);
  const history = useHistory();

  useEffect(() => {
    try {
      const auth = jwt(localStorage.getItem("authToken"));
      if (auth.exp * 1000 < Date.now()) {
        history.push("/user/patient/login");
      }
    } catch (e) {
      history.push("/user/patient/login");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentPage === 6) {
      window.fbq("track", "CompleteRegistration");
    }
  }, [currentPage]);

  useEffect(() => {
    let page = localStorage.getItem("pageInfo");
    let pageInt = parseInt(page);
    if (pageInt) {
      setCurrentPage(pageInt);
    }

    // oauthData = localStorage.getItem("oauthData");
    // if (oauthData) {
    //   oauthData = JSON.parse(oauthData);
    //   setName(oauthData.name);
    //   setEmail(oauthData.email);
    // }
  }, []);

  return (
    <>
      {location.search.includes("?completed=true") || currentPage === 6 ? (
        <UpdateProfileCompleted />
      ) : (
        <PageLayout>
          <ProgressTab
            lastCompletedPage={lastCompletedPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <UpdateDetails
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setLastCompletedPage={setLastCompletedPage}
          />
        </PageLayout>
      )}
    </>
  );
};

export default UpdateProfileDoctor;
