import React from "react";
import { StyledFormItem, Wrapper } from "./styledComponent";
import { Select } from "antd";

const CustomUserDropdownField = ({
  disabled,
  title,
  name,
  type2,
  rules,
  onSelect,
  onChange,
  showSearch,
  placeholder,
  children,
  filterOption,
  value,
  options,
}) => {
  return (
    <Wrapper>
      <label>{title}</label>
      <StyledFormItem name={name} rules={rules} type2={type2}>
        <Select
          showSearch={showSearch}
          placeholder={placeholder}
          onSelect={onSelect}
          onChange={onChange}
          value={value}
          disabled={disabled}
          filterOption={filterOption}
          options={options}
        >
          {children}
        </Select>
      </StyledFormItem>
    </Wrapper>
  );
};

export default CustomUserDropdownField;
