import React from "react";
import { Dropdown } from "antd";
import {
  DropdownName,
  DropdownContainer,
  DropdownContent,
} from "./styledComponents";
import { DownOutlined } from "@ant-design/icons";
const CustomDropdown = ({
  dropdownName,
  key,
  menu,
  visible,
  className,
  page,
  icon,
  wrapperClassName,
}) => {
  return (
    <>
      <DropdownContainer key={key} page={page} className={wrapperClassName}>
        <Dropdown
          overlay={menu}
          placement="bottomCenter"
          // visible={visible}
          overlayClassName={className}
          // visible={true}
        >
          <DropdownContent>
            <DropdownName>{dropdownName}</DropdownName>
            {icon ? icon : <DownOutlined />}
          </DropdownContent>
        </Dropdown>
      </DropdownContainer>
    </>
  );
};

export default CustomDropdown;
