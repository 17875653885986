import { useGoogleLogin } from "@react-oauth/google";
import React from "react";
import { message } from "antd";
import { useParams } from "react-router-dom";
import GoogleIcon from "../../assets/icons/GoogleIcon";
import { StyledIconButtonWrapper } from "./styledComponents";
import { useLoginApi } from "../../hooks/useLoginApi";

const GoogleIconSignup = ({ props }) => {
  const { userType } = useParams();
  const { googleSignup } = useLoginApi();

  const login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      handleSuccess(credentialResponse);
    },
    onError: () => {
      message.error("Google Login Failed");
    },
  });

  const handleSuccess = (credentialResponse) => {
    console.log("Credential Response:", credentialResponse);
    if (!credentialResponse || !credentialResponse.credential) {
      message.error("Google Authentication Failed");
      return;
    }
    googleSignup(userType, credentialResponse);
  };

  return (
    <StyledIconButtonWrapper
      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
      onClick={login}
    >
      <GoogleIcon />
    </StyledIconButtonWrapper>
  );
};

export default GoogleIconSignup;
