import React from "react";
import CustomArticleButton from "../CustomArticleButton";
import styled from "styled-components";

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: end;
  flex-direction: ${(props) => props.flexDirection || "row"};
  gap: 16px;
  button {
    padding: 20px 40px;
    width: 100%;
    max-width: 180px;
  }
`;

const SaveCancelButtons = ({
  onCancelClick,
  onSaveClick,
  isDisabled = false,
  className,
  cancelButtonTitle,
  saveButtonTitle,
  buttonFlexDirection,
  cancelBgColor,
  saveBgColor,
  cancelTextColor,
  saveTextColor,
}) => (
  <ButtonsWrapper className={className} flexDirection={buttonFlexDirection}>
    <CustomArticleButton
      closeBtn
      onClick={onCancelClick}
      buttonText={cancelButtonTitle ? cancelButtonTitle : "CANCEL"}
      bgColor={cancelBgColor}
      textColor={cancelTextColor}
      className={"Cancel-Button-Style"}
    />
    <CustomArticleButton
      onClick={onSaveClick}
      buttonText={saveButtonTitle ? saveButtonTitle : "SAVE DETAILS"}
      disabled={isDisabled}
      bgColor={saveBgColor}
      textColor={saveTextColor}
      className={"Save-Button-Style"}
    />
  </ButtonsWrapper>
);

export default SaveCancelButtons;
