import { useState } from "react";
import ApiCalls from "../apis/ApiCalls";
import { message } from "antd";
import { loadScript } from "../utils/commonFunctions";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";

export const useTherapistsApi = () => {
  const user = useSelector((state) => state.auth.userData);
  const [doctorsList, setDoctorsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [specializations, setSpecializations] = useState([]);
  const [doctorDetails, setDoctorDetails] = useState(null);
  const [prebookingQuestions, setPrebookingQuestions] = useState();
  const [clearCoupon, setClearCoupon] = useState(false);
  const [priceDetails, setPriceDetails] = useState({});
  const [disableCoupon, setDisableCoupon] = useState(false);
  const [allCoupons, setAllCoupons] = useState();
  const [scheduleDetails, setScheduleDetails] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [stripeModal, setStripeModal] = useState({
    show: false,
    clientsecret: null,
    transactionId: null,
  });
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [therapistData, setTherapistData] = useState([]);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const getAllDoctorList = (payload, postSuccessFunction = () => {}) => {
    setLoading(true);
    ApiCalls.getDoctorsList(payload)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setDoctorsList(response.data.data);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getSpecializationList = () => {
    setLoading(true);
    ApiCalls.getSpecialisationList()
      .then((response) => {
        if (response.data.status) {
          setSpecializations(response.data.data);
        } else {
          message.error("Can't fetch specialites");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getTherapistDetails = (id) => {
    setLoading(true);
    ApiCalls.getDoctorDetails(id)
      .then((response) => {
        if (response.data.status) {
          setDoctorDetails(response.data.data);
        } else {
          message.error(response.data.data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
        setLoading(false);
      });
  };
  const getPrebookingQuestions = (params) => {
    setLoading(true);
    ApiCalls.getPreBookingQuestions(params)
      .then((response) => {
        if (response.data.status) {
          setPrebookingQuestions(response.data.data);
        } else {
          message.error(response.data.data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
        setLoading(false);
      });
  };
  const checkScheduleAvailability = (params, postSuccessFunction) => {
    setLoading(true);
    ApiCalls.checkScheduleAvailable(params)
      .then((response) => {
        if (response.data.status) {
          postSuccessFunction(response.data.data);
          setLoading(false);
        } else {
          setLoading(false);
          message.error(response.data.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const applyCoupon = (payload, postSuccessFunction = () => {}) => {
    ApiCalls.applyCoupon(payload)
      .then((res) => {
        if (res.data.status) {
          message.success("Coupon applied");
          setDisableCoupon(true);
          setPriceDetails((priceDetails) => ({
            ...priceDetails,
            discountAmount: res.data?.data?.discountAmount,
            couponApplied: res.data?.data?.couponApplied,
            subTotal: res.data?.data?.patientPaymentAmount,
            totalAmount: res.data?.data?.totalAmount,
            feesAndTaxes: res.data?.data?.feesAndTaxes,
          }));
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const geTherapistPriceDetails = (id, treatmentId) => {
    ApiCalls.fetchDoctorPriceDetails(id, treatmentId)
      .then((response) => {
        if (response.data.status) {
          setPriceDetails(response.data.data);
          setClearCoupon(false);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getPatientAllCoupons = (id) => {
    ApiCalls.getPatientAllCoupons(id)
      .then((response) => {
        if (response.data.status) {
          setAllCoupons(response.data.data);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const UpdateScheduleDetails = (data, postSuccessFunction = () => {}) => {
    ApiCalls.updateScheduleDetails(data)
      .then((response) => {
        if (response.data.status) {
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
        }
      })
      .catch((error) =>
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        )
      );
  };
  const getScheduleInfo = (scheduleId, postSuccessFunction = () => {}) => {
    setLoading(true);
    ApiCalls.getScheduleDetails(scheduleId)
      .then((response) => {
        if (response.data) {
          setScheduleDetails(response.data.data);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction(response.data);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getZeroConsultationFees = (payload, postSuccessFunction) => {
    ApiCalls.addZeroConsultationCharges(payload)
      .then((response) => {
        if (response.data.status) {
          postSuccessFunction();
        } else {
          message.error(response.data.data.message);
        }
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const configStripePayment = async (
    payload,
    postSuccessFunction = () => {}
  ) => {
    setLoading(true);

    try {
      const response = await ApiCalls.configureSubscriptionStripePayment(
        payload
      );
      setLoading(false);

      if (response.data.status) {
        setPaymentData(response.data.data);
        if (typeof postSuccessFunction === "function") {
          postSuccessFunction();
        }
      } else {
        message.error("Payment configuration failed.");
      }
    } catch (error) {
      setLoading(false);
      message.error(
        error.response
          ? error?.response?.data?.data?.message
          : "No Response From Server",
        5
      );
    }
  };
  const configRazorPayment = async (
    payload,
    postSuccessFunction = () => {}
  ) => {
    setLoading(true);

    try {
      const response = await ApiCalls.configureSubscriptionRazorPayment(
        payload
      );
      setLoading(false);

      if (response.data.status) {
        setPaymentData(response.data.data);
        if (typeof postSuccessFunction === "function") {
          postSuccessFunction();
        }
      } else {
        message.error("Payment configuration failed.");
      }
    } catch (error) {
      setLoading(false);
      message.error(
        error.response
          ? error?.response?.data?.data?.message
          : "No Response From Server",
        5
      );
    }
  };
  const configSingleSessionStripePayment = async (
    payload,
    postSuccessFunction = () => {}
  ) => {
    setLoading(true);

    try {
      const response = await ApiCalls.configureStripePayment(payload);
      setLoading(false);

      if (response.data.status) {
        localStorage.setItem("paymentData", JSON.stringify(response.data.data));
        setPaymentData(response.data.data);
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: response.data.data.sessionId,
        });
        if (result.error) {
          console.log(result.error.message);
        }
        if (typeof postSuccessFunction === "function") {
          postSuccessFunction();
        }
      } else {
        message.error("Payment configuration failed.");
      }
    } catch (error) {
      setLoading(false);
      message.error(
        error.response
          ? error?.response?.data?.data?.message
          : "No Response From Server",
        5
      );
    }
  };
  const configSingleSessionRazorPayment = async (
    payload,
    postSuccessFunction = () => {}
  ) => {
    setLoading(true);

    try {
      const response = await ApiCalls.configurePayment(payload);
      setLoading(false);

      if (response.data.status) {
        setPaymentData(response.data.data);
        if (typeof postSuccessFunction === "function") {
          postSuccessFunction();
        }
      } else {
        message.error("Payment configuration failed.");
      }
    } catch (error) {
      setLoading(false);
      message.error(
        error.response
          ? error?.response?.data?.data?.message
          : "No Response From Server",
        5
      );
    }
  };
  const getPaymentStatus = (id, postSuccessFunction = () => {}) => {
    setLoading(true);
    setIsStatusLoading(true);
    console.log(getPaymentStatus, "getPaymentStatus");
    const paymentMethod = localStorage.getItem("paymentMethod");
    (paymentMethod && paymentMethod == "stripe"
      ? ApiCalls.verifyStripePayment(id)
      : ApiCalls.verifyRazorPayPayment(id)
    )
      .then((response) => {
        if (response.data.status) {
          if (localStorage.getItem("paymentMethod") == "stripe") {
            localStorage.removeItem("paymentMethod");
            localStorage.removeItem("paymentData");
            setStripeModal({
              show: false,
              clientsecret: null,
              transactionId: null,
            });
          }
          setIsStatusLoading(false);
          postSuccessFunction({
            paymentMethod: paymentMethod == "stripe" ? "stripe" : "razor",
            message: "payment-success",
          });
        } else {
          postSuccessFunction({
            paymentMethod: paymentMethod,
            message: "payment-failed",
          });
          message.error(response.data.data.message);
        }
        setLoading(false);
        setIsStatusLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const processStripePayment = async () => {
    localStorage.setItem("paymentMethod", "stripe");
    setStripeModal({
      show: true,
      clientsecret: paymentData?.clientSecret,
      transactionId: paymentData?.transactionId,
    });
    const stripe = await stripePromise;
    const storedPaymentData = localStorage.getItem("paymentData");
    const parsedPaymentData = JSON.parse(storedPaymentData);
    if (!parsedPaymentData?.transactionId) {
      console.error("Error: transactionId missing in paymentData.");
      return;
    }
    console.log(
      parsedPaymentData.transactionId,
      "parsedPaymentData.transactionId"
    );
    const result = await stripe.redirectToCheckout({
      sessionId: parsedPaymentData.transactionId,
    });

    if (result.error) {
      // Display error to your customer
      console.log(result.error.message);
    }
  };

  const processRazorpayPayment = async (postSuccessFunction = () => {}) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: paymentData?.amount.toString(),
      currency: paymentData?.currency,
      name: "TeleHealings",
      description: "",
      order_id: paymentData?.orderId,
      handler: async function (response) {
        if (typeof postSuccessFunction === "function") {
          postSuccessFunction();
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phoneNumber,
      },
      notes: {
        address: user.address,
      },
      theme: {
        color: "#32AFBF",
      },
      modal: {
        ondismiss: function () {
          localStorage.removeItem("rzp_checkout_anon_id");
          localStorage.removeItem("rzp_device_id");
          localStorage.removeItem("rzp_stored_checkout_id");
        },
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  const submitDoctorReview = async (
    payload,
    postSuccessFunction = () => {}
  ) => {
    try {
      const response = await ApiCalls.giveDoctorRatings(payload);
      if (response.data.status) {
        message.success("Thanks for submitting your review");
        postSuccessFunction();
        return response.data;
      }
    } catch (error) {
      message.error(
        error.response
          ? error?.response?.data?.data?.message
          : "No Response From Server",
        5
      );
      throw error;
    }
  };
  const reportAnIssue = (data, postSuccessFunction = () => {}) => {
    setLoading(true);
    ApiCalls.reportIssues(data)
      .then((response) => {
        if (response.data.status) {
          message.success("Issue raised successfully");
          postSuccessFunction();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getMyTherapistList = (currentPage, limit) => {
    setLoading(true);
    ApiCalls.getConsultedDoctors({ page: currentPage, limit: limit })
      .then((response) => {
        if (response.data.status) {
          setTherapistData(response.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getStripePaymentStatus = (id) => {
    ApiCalls.verifyStripePayment(id)
      .then((response) => {
        if (response.data.status) {
          message.success("Payment was completed successfully");
          console.log("response....", response);
        }
      })
      .catch((error) => {
        console.log("error....", error);
      });
  };
  return {
    getAllDoctorList,
    loading,
    doctorsList,
    getSpecializationList,
    specializations,
    getTherapistDetails,
    doctorDetails,
    getPrebookingQuestions,
    prebookingQuestions,
    setPrebookingQuestions,
    checkScheduleAvailability,
    applyCoupon,
    geTherapistPriceDetails,
    clearCoupon,
    disableCoupon,
    priceDetails,
    setClearCoupon,
    setDisableCoupon,
    getPatientAllCoupons,
    allCoupons,
    UpdateScheduleDetails,
    getScheduleInfo,
    scheduleDetails,
    getZeroConsultationFees,
    paymentData,
    configStripePayment,
    configRazorPayment,
    getPaymentStatus,
    processRazorpayPayment,
    processStripePayment,
    submitDoctorReview,
    reportAnIssue,
    getMyTherapistList,
    therapistData,
    configSingleSessionStripePayment,
    configSingleSessionRazorPayment,
    stripePromise,
    setStripeModal,
    getStripePaymentStatus,
    isStatusLoading,
  };
};
