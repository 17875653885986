import React from "react";
import {
  ContentWrapper,
  IconDiv,
  IconWrapper,
  Wrapper,
} from "./styledComponents";
import {
  CommonDMFontTextRegular,
  CommonDMFontTextSmall,
  CommonSchibstedFontTextBold,
} from "../../utils/styledUtils";
import CloseIcon from "../../assets/icons/CloseIcon";
import TherapistEditIcon from "../../assets/icons/TherapistEditIcon";

const TherapistStatCard = ({
  title,
  subTitle,
  address,
  onEditClick,
  onCloseClick,
  item,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <IconWrapper>
        <IconDiv onClick={onEditClick} className="Edit-Icon-Style">
          <TherapistEditIcon width={"10px"} height={"12.5px"} />
        </IconDiv>
        <IconDiv
          bgColor="#C24646"
          onClick={() => onCloseClick(item)}
          className="Close-Icon-Style"
        >
          <CloseIcon width={"14.5px"} height={"14.5px"} />
        </IconDiv>
      </IconWrapper>
      <ContentWrapper>
        <CommonSchibstedFontTextBold
          fontSize="16px"
          className="Card-Title-Style"
          fontWeight={700}
        >
          {title ? title : ""}
        </CommonSchibstedFontTextBold>
        <CommonDMFontTextRegular
          fontSize="16px"
          textColor="#848484"
          className="Card-Sub-Title-Style"
          fontWeight={600}
        >
          {subTitle ? subTitle : ""}
        </CommonDMFontTextRegular>
        {address && (
          <CommonDMFontTextSmall fontSize="14px" textColor="#848484">
            {address}
          </CommonDMFontTextSmall>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default TherapistStatCard;
