import * as React from "react";

const CopyIcon = ({ fill, width, height }) => {
  return (
    <svg
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.023 2.39999H8.22305C8.01087 2.39999 7.80739 2.48428 7.65736 2.63431C7.50733 2.78434 7.42305 2.98782 7.42305 3.19999V7.19999H3.42305C3.21087 7.19999 3.00739 7.28428 2.85736 7.43431C2.70733 7.58434 2.62305 7.78782 2.62305 7.99999V20.8C2.62305 21.0122 2.70733 21.2157 2.85736 21.3657C3.00739 21.5157 3.21087 21.6 3.42305 21.6H16.223C16.4352 21.6 16.6387 21.5157 16.7887 21.3657C16.9388 21.2157 17.023 21.0122 17.023 20.8V16.8H21.023C21.2352 16.8 21.4387 16.7157 21.5887 16.5657C21.7388 16.4157 21.823 16.2122 21.823 16V3.19999C21.823 2.98782 21.7388 2.78434 21.5887 2.63431C21.4387 2.48428 21.2352 2.39999 21.023 2.39999ZM15.423 20H4.22305V8.79999H15.423V20ZM20.223 15.2H17.023V7.99999C17.023 7.78782 16.9388 7.58434 16.7887 7.43431C16.6387 7.28428 16.4352 7.19999 16.223 7.19999H9.02305V3.99999H20.223V15.2Z"
        fill={fill ? fill : "#425889"}
      />
    </svg>
  );
};

export default CopyIcon;
