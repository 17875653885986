import styled from "styled-components";
import { StyledFormItem } from "../../doc-patient-modules/signup/styledComponents";

export const DatePickerWrapper = styled.div`
  & label {
    font-size: 16px;
    font-family: "Schibsted Grotesk";
    color: rgba(153, 153, 153, 1);
    font-weight: 700;
    letter-spacing: -0.01em;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }
`;
export const CustomStyledFormItem = styled(StyledFormItem)`
  background-color: transparent;
  margin: 4px 0 0 0;
  & .ant-picker {
    height: 54px;
    background-color: rgba(251, 251, 251, 1) !important;
    border: ${(props) => (props.type2 ? "none" : "")};
    & .anticon-calendar {
      font-size: 23px;
    }
    input {
      padding: 10px 15px;
      color: rgba(153, 153, 153, 1);
    }
  }
  &.ant-picker-focused {
    box-shadow: none !important;
  }
`;
