import styled from "styled-components";
import updateProfileBg from "../../assets/backgrounds/updateProfileBg.png";
import doctorImage from "../../assets/images/doctor1@2x.png";
import { Form } from "antd";

export const PageLayout = styled.div`
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
`;

export const ProgressTabWrapper = styled.div`
  height: 100%;
  background-image: url(${updateProfileBg});
  width: 550px;
  padding-top: 65px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 1500px) {
    width: 475px;
    padding-top: 20px;
  }
`;

export const LogoContainer = styled.div`
  position: relative;
  height: 30px;
  width: auto;
  & svg {
    height: 100%;
    width: auto;
  }
`;

export const WhiteBackground = styled.div`
  position: absolute;
  top: -5px;
  left: -4px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
`;

export const InfoTextContainer = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0;
  @media only screen and (max-width: 1500px) {
    height: 90px;
    padding: 15px 0;
  }
`;

export const InfoText = styled.div`
  font-size: ${(props) => (props.sub ? "16px" : "20px")};
  color: white;
  font-family: "Urbanist", sans-serif;
  @media only screen and (max-width: 1500px) {
    font-size: ${(props) => (props.sub ? "14px" : "18px")};
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 340px;
  width: 100%;
  padding: 10px 0;
  @media only screen and (max-width: 1500px) {
    height: 280px;
  }
`;

export const ProfileTabItem = styled.div`
  display: flex;
  align-items: center;
  height: auto;
`;

export const ProgressCircle = styled.div`
  position: relative;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${(props) => (props.completed ? "#4385C6" : null)};
  border: ${(props) =>
    props.completed ? "1px solid #4385C6" : "1px solid white"};
  @media only screen and (max-width: 1500px) {
    height: 20px;
    width: 20px;
  }
`;

export const ProfileTabItemName = styled.div`
  margin-top: 4px;
  margin-left: 20px;
  color: ${(props) => (props.selected ? "#4385C6" : "#fff")};
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  line-height: 1;
  @media only screen and (max-width: 1500px) {
    margin-top: 2px;
    font-size: 14px;
  }
`;

export const SelectedCircle = styled.div`
  position: absolute;
  height: 50%;
  width: 50%;
  top: 23%;
  left: 23%;
  border-radius: 50%;
  background-color: white;
`;

export const ConnectingLine = styled.div`
  position: absolute;
  bottom: -26px;
  left: 9.5px;
  height: 25px;
  border: 0.5px solid #fff;
  @media only screen and (max-width: 1500px) {
    bottom: -23px;
    left: 7.5px;
    height: 23px;
  }
`;

export const ImageContainer = styled.div`
  height: 365px;
  width: 100%;
  margin-left: 50px;
  background-image: url(${doctorImage});
  background-size: auto 128%;
  background-repeat: no-repeat;
  @media only screen and (max-width: 1500px) {
    height: 225px;
    background-position: 0% 0%;
  }
`;

export const UpdateDetailsWrapper = styled.div`
  height: 100%;
  width: ${(props) => (props.edit ? "100%" : "calc(100% - 550px)")};
  overflow: auto;
  @media only screen and (max-width: 1500px) {
    width: ${(props) => (props.edit ? "100%" : "calc(100% - 475px)")};
  }
`;

export const UpdateDetailsLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;
  padding: ${(props) => (props.edit ? "0 10px" : "103px 55px 73px")};
  @media only screen and (max-width: 1800px) {
    padding: ${(props) => (props.edit ? "0 10px" : "55px")};
  }
`;

export const PageUpdateDetailsContainer = styled.div`
  position: relative;
  height: auto;
  width: 100%;
`;
export const StepContainer = styled.div`
  position: absolute;
  width: 100%;

  & .ant-steps-item-icon,
  .ant-steps-item-content {
    font-family: "Urbanist", sans-serif;
  }
`;

export const PageNavigation = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NavigateButtonsWrapper = styled.div`
  height: auto;
  width: 190px;
  display: flex;
  justify-content: flex-end;
`;

export const NavigateButtonContainer = styled.div`
  height: auto;
  width: 48%;
`;

export const UpdateAreaContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

export const PageHeading = styled.h3`
  margin-bottom: 0;
  font-family: "Gilroy SemiBold";
  font-size: 25px;
  color: #35364f;
  &::after {
    content: "*";
    color: red;
    display: ${(props) => (props.mandatory ? null : "none")};
  }
`;

export const SubHeading = styled.h6`
  margin-bottom: 0;
  font-family: "Urbanist", sans-serif;
  font-size: 17px;
  padding-bottom: ${(props) => (props.type2 ? "" : "35px")};
  color: #35364f;
`;

export const CompletedMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const MailIconContainer = styled.div`
  margin-top: 70px;
`;

export const CompletedMessage = styled.p`
  max-width: 382px;
  text-align: center;
  margin-top: ${(props) => (props.sub ? "20px" : "35px")};
  margin-bottom: 0;
  font-size: ${(props) => (props.sub ? "15px" : "24px")};
  font-family: ${(props) =>
    props.sub ? "'Urbanist', sans-serif" : "Gilroy Bold"};
  color: ${(props) => (props.sub ? "#65667D" : "#35364f")};
  line-height: ${(props) => (props.sub ? "1.8" : "")};
`;

export const RedirectButtonContainer = styled.div`
  position: relative;
  height: 152px;
  width: 243px;
  & button {
    position: absolute;
    top: 30px;
    left: 0;
  }
`;
export const GenderItem = styled.button`
  padding: 13px 24px;
  border-radius: 128px;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${(props) => (props.selected ? "#425889" : "#FFFFFF")};
  cursor: pointer;
  color: ${(props) => (props.selected ? "#FFFFFF" : "#666666")};
  font: 600 17px "Schibsted Grotesk";
  gap: 10px;
  height: auto;
`;
export const SelectGenderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px 8px;
  flex-wrap: wrap;
`;
export const UpdateFieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${(props) => props.gap || "8px"};
  width: 100%;
  flex-direction: column;
  .ant-row {
    padding: 0 !important;
  }
`;
export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  .Dob-Style {
    .ant-picker-suffix {
      display: block;
      padding-right: 5px;
    }
    .ant-picker-clear {
      right: 10px;
    }
    input,
    .ant-input-affix-wrapper {
      font: 500 16px "Schibsted Grotesk";
    }
  }
  input,
  .ant-input-affix-wrapper {
    height: 54px;
    color: #999999 !important;
    border: none;
    font: 500 16px "DM Sans" !important;
    background-color: #ffffff !important;
    #password,
    #confirm {
      background-color: #ffffff !important;
      font: 500 16px "DM Sans" !important;
      color: #999999 !important;
      &::placeholder {
        font: 500 16px "DM Sans" !important;
        color: #999999 !important;
      }
    }
    #confirm {
      padding-left: 0px;
    }
  }
  .ant-picker {
    background-color: #ffffff !important;
    input {
      background-color: #ffffff !important;
      font: 500 16px "DM Sans" !important;
      color: #999999 !important;
    }
  }
  .ant-form-item-control-input-content {
    textarea {
      background-color: #ffffff !important;
      font: 500 16px "DM Sans" !important;
      color: #999999 !important;
    }
  }

  .ant-input:focus {
    box-shadow: 0 0 0 2px rgba(67, 133, 198, 0.2);
  }

  .ant-checkbox-wrapper {
    padding-top: 8px;
    .ant-checkbox:hover::after {
      visibility: collapse;
    }
    .ant-checkbox-checked::after,
    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: #425889;
      background-color: #425889;
    }
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
    }
    .ant-checkbox + span {
      font: 500 16px "DM Sans";
    }
  }
`;
export const EducationExperienceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  .CustomGroupedInputDropdown {
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector {
      background: #fbfbfb;
      cursor: default !important;
    }
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      input {
      cursor: default !important;
    }
  }
  .Add-Btn-Style {
    width: 100%;
    height: 56px;
    border-width: 2px;
  }
  .Service-Button-Style {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 9px 16px;
    box-shadow: none;
    p {
      margin: 0px;
      font: 700 14px "Schibsted Grotesk";
      text-transform: capitalize;
    }
  }
`;
export const FlexContent = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  .Flex-Info-Btn-Style {
    display: flex;
    align-items: start;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }
  &.Price-Flex-Style {
    gap: 8px;
    .TherapistStatCard-Style {
      background-color: transparent;
      border: 1px solid #f0f0f0;
      margin-bottom: 25px;
      .Card-Title-Style {
        line-height: 120%;
      }
      .Card-Sub-Title-Style {
        font: 600 16px "DM Sans";
        color: #666666;
        line-height: 140%;
      }
    }
  }
`;
export const IconWrapper = styled.div`
  width: 14px;
  height: 14px;
  background: #c24646;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ListWrapper = styled.div`
  display: flex;
  gap: 8px;
  scroll-behavior: smooth;
  overflow: auto;
  cursor: pointer;
  height: 100%;
  flex-direction: column;
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0 !important;
    border-radius: 10px;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    height: 3px;
    background: #425889 !important;
    border-radius: 10px;
  }
`;
export const ListText = styled.p`
  margin: 0px;
  text-transform: capitalize;
  font: 600 16px "Dm Sans";
  background-color: ${(props) => props.bgColor || "#F4F7FC"};
  border: none;
  color: ${(props) => props.textColor || "#425889"};
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  &.selected {
    background-color: #425889 !important;
    color: #ffffff;
  }
  &.Availability-Button-Style {
    width: calc(33.33% - 11px);
    background-color: #ffffff;
    margin-top: 24px;
    padding: 21px 20px;
  }
`;
export const ContentFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
  .Break-EndTime-Close-Wrapper {
    position: relative;
    width: calc(50% - 20px);
    .ant-picker {
      width: 100% !important;
    }
  }
  &.Day-Wrapper {
    p {
      width: calc(100% - 55px);
    }
  }
  .FullWidth-Style {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.Time-Wrapper {
    justify-content: space-between;
    .ant-picker {
      width: calc(50% - 20px);
      background: #f8f8f8;
      padding: 21px;
      border-radius: 8px;
      border: none;
      height: 64px;
      .ant-picker-input {
        input {
          font: 700 16px "DM Sans";
          text-align: center;
          color: #333333;
          &::placeholder {
            color: #333333;
          }
        }
      }
    }
    .ant-picker-focused {
      border: none;
    }
    .End-Close-Time {
      width: 100%;
    }
  }
  .CopyBreakWrapper {
    justify-content: center;
    width: auto;
    padding: 0px;
    .IconTextWrapper {
      width: auto;
      .MenuText {
        font: 800 13px "Schibsted Grotesk";
        text-transform: uppercase;
        text-decoration: underline;
      }
    }
  }
  .End-Close-Wrapper {
    position: relative;
    width: calc(50% - 20px);
  }
`;
export const InputWrapper = styled.div`
  input {
    margin-top: 8px;
    height: 54px;
    line-height: 20px;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Schibsted Grotesk";
    font-weight: 500;
    color: #999999 !important;
    padding: 10px 15px 10px 15px;
    background-color: #ffffff;
    border: none;
    @media only screen and (max-width: 900px) {
      font-size: 12px;
      height: 50px;
    }
  }
`;
export const AvailablityWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
export const WeekDayWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px 16px 24px;
  flex-direction: column;
  gap: 26px;
`;
export const WeekWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  .ant-switch {
    width: 39px;
    height: 24px;
    min-width: unset;
    .ant-switch-handle {
      height: 24px;
      width: 24px;
      top: 0px;
      left: 0px;
      border-radius: 100px;
      &::before {
        border-radius: 100px;
      }
    }
  }
  .ant-switch-checked {
    background-color: #425889;
    .ant-switch-handle {
      left: calc(100% - 22px);
    }
  }
`;
export const IconDiv = styled.div`
  width: 17px;
  height: 17px;
  background-color: ${(props) => props.bgColor || "#417FC2"};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: -8px;
  top: -9px;
`;
export const BankDetailsWrapper = styled.div`
  width: 100%;
  height: 100%;
  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .ant-select {
    .ant-select-selector {
      background-color: #ffffff !important;
      font: 500 16px "DM Sans" !important;
      color: #999999 !important;
    }
  }
`;
export const BankContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  input {
    background-color: #ffffff !important;
    font: 500 16px "Dm Sans" !important;
    color: #999999 !important;
    ::placeholder {
      font: 500 16px "Dm Sans" !important;
      color: #999999 !important;
    }
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    font: 500 16px "Dm Sans";
    color: #999999;
    opacity: unset;
  }
  .ant-select-selection-search-input {
    padding: 10px 4px 10px !important;
  }
`;
export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const UpdateDocScrollWrapper = styled.div`
  width: 100%;
  overflow: auto;
  height: calc(100% - 45px);
  padding-bottom: 50px;
  &.PendingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .PendingCardStyle {
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .ConfirmedImageWrapper {
        padding: 0px;
        > div {
          height: -webkit-fill-available;
        }
      }
      .Dashboard-Redirect-Btn-Style {
        width: 100%;
        margin-top: 37px;
        height: 48px;
      }
    }
  }
  .CustomLoader {
    height: 100%;
    min-height: 500px;
    width: 100%;
  }
  @media only screen and (max-width: 600px) {
    padding-bottom: 10px;
    .Info-Heading-Style {
      font-size: 28px;
      text-align: center;
    }
    .UpdateCardContent {
      max-width: 430px;
      .SelectGenderDiv {
        justify-content: flex-start;
      }
    }
    .Service-Button-Style {
      padding-left: 0px;
    }
  }
  .Time-Wrapper {
    gap: 8px;
    .ant-picker {
      width: calc(50% - 12px);
      padding: 21px 10px;
      input {
        font: 700 14px "DM Sans" !important;
        &::placeholder {
          font: 700 14px "DM Sans" !important;
        }
      }
    }
  }
`;
export const DayOffWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
  .Add-Btn-Style {
    width: 100%;
    height: 48px;
  }
  .FullWidth-Style {
    padding-top: 18px;
  }
  .Time-Pair {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 17px;
  }
`;
export const AvailabilityDateWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  .ant-picker-focused {
    outline: none !important;
  }
`;
export const MappedAvailablityWrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid #f0f0f0;
  .DatePicker {
    .ant-picker {
      width: calc(100% - 28px);
      height: 100%;
      border-radius: 4px;
      background-color: #f0f0f0;
      border: 1px solid #f0f0f0;
      color: #333333;
      input {
        &::placeholder {
          color: #999999 !important;
        }
      }
    }
    .IconDiv {
      width: 28px;
      position: unset;
      height: 28px;
    }
  }
  .Time-Wrapper {
    margin-top: 24px;
    width: calc(100% - 90px);
    margin-left: 50px;
    @media only screen and (max-width: 1600px) {
      width: 100%;
      margin-left: 0px;
    }
    .ant-picker-suffix {
      display: none !important;
    }
    .Availalibity-EndTime-Close-Wrapper {
      position: relative;
      width: calc(50% - 20px);
      .ant-picker {
        width: 100%;
      }
      .IconDiv {
        position: absolute;
      }
    }
  }

  &:first-of-type {
    border-top: none !important;
  }
`;
