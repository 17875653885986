import React, { useState, useEffect } from "react";
import { AutoComplete, Layout, Typography } from "antd";
import {
  Link,
  Route,
  Switch as RouterSwitch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwt from "jwt-decode";
import {
  StyleHeaderBottomBorder,
  StyledHeader,
  RightContainer,
  SearchContainer,
  StyledInput,
  StyledOption,
} from "./styledComponents";
import Home from "../Home";
import SocketActions from "../../sockets/SocketActions";
import Notifications from "../Notifications";
import EmptyComponent from "../../components/EmptyComponent";
import { LoadingComponentContainer } from "../styledComponents";
import { LoadingOutlined } from "@ant-design/icons";
import { LogoContainer } from "../signup/styledComponents";
import TextLogo from "../../assets/icons/Logo/TextLogo";
import TelehealingsLogo from "../../assets/images/telehealings_logo.png";

import storage from "../../storage";
import reduxActions from "../../redux-store/actions";
import ApiCalls from "../../apis/ApiCalls";
import SubscriptionPlan from "../Dashboard/CorporateDashboard/SubscriptionPlan";
import WalletCard from "../Dashboard/CorporateDashboard/WalletCard";
import DoctorSelectionList from "../Dashboard/CorporateDashboard/DoctorList";
import EditDoctor from "../Dashboard/CorporateDashboard/EditDoctor";
import DoctorAllList from "../Dashboard/CorporateDashboard/AllDoctorList";
import CompanyDoctorList from "../Dashboard/CorporatePatient/DoctorList";
import QuestionNairs from "../SurveyForm/Questionnairs";
import { SearchOutlined } from "@ant-design/icons";
import { Divider } from "../ViewDoctors/styledComponents";
import ImageNotAvailable from "../../assets/images/CorporateModule/Image_not_available.png";

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: transparent !important; /* Override with !important if necessary */
  }
`;

const { Text } = Typography;

const Base = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.userData);
  const location = useLocation();

  const [searchValue, setSearchValue] = useState(
    location.state?.searchValue || ""
  );
  const [doctorSuggestion, setDoctorSuggestion] = useState([]);

  if (location.pathname !== "/user/base/home/view-doctors") {
    localStorage.removeItem("categoryId");
  }
  useEffect(() => {
    let searchTimeout = null;
    if (searchValue.length > 0) {
      searchTimeout = setTimeout(() => {
        getDoctorSuggestions();
      }, 500);
    } else {
      setDoctorSuggestion([]);
    }
    return () => {
      clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line
  }, [searchValue]);

  const getDoctorSuggestions = () => {
    ApiCalls.getDoctorsList({ page: 0, limit: 10, text: searchValue.trim() })
      .then((response) => {
        if (response.data.status) {
          const doctors = response.data.data.doctors;
          const options = doctors.map((item) => ({
            value: item.id,
            label: (
              <StyledOption>
                <div
                  onClick={() => {
                    handleSelect(item);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={item.profilePic}
                      alt=""
                      onError={(e) => {
                        if (e.target.src !== ImageNotAvailable) {
                          e.target.src = ImageNotAvailable;
                        }
                      }}
                      style={{
                        borderRadius: "5px",
                        marginRight: 10,
                        border: "1px solid",
                        height: "40px",
                        width: "40px",
                      }}
                    />
                    <div>
                      <div
                        style={{
                          color: "#000000",
                          fontFamily: "Metropolis Regular",
                          fontSize: "16px",
                          fontWeight: "700px",
                        }}
                      >
                        {item.name}
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Text
                      type="secondary"
                      style={{
                        color: "#848484",
                        fontFamily: "Metropolis Regular",
                        fontSize: "14px",
                        fontWeight: "700px",
                      }}
                    >
                      {item.totalWorkExperiance} years of Experience
                    </Text>
                  </div>
                </div>
                <Divider style={{ width: "100%", height: "2px" }} />
              </StyledOption>
            ),
          }));

          setDoctorSuggestion(options);
        }
      })
      .catch((err) => {
        console.error("Error fetching doctor suggestions:", err);
      });
  };

  const handleSelect = (value) => {
    history.push(`/user/base/home/doctor-profile/${value._id}`);
    setSearchValue("");
  };

  // const logout = async () => {
  //   SocketActions.disconnect();
  //   storage.authToken.clear();
  //   storage.user.clear();
  //   dispatch(reduxActions.authActions.removeAuthToken());
  //   dispatch(reduxActions.authActions.removeUserData());
  //   history.push('/user/patient/login');
  // };

  useEffect(() => {
    try {
      const decodedToken = jwt(storage.authToken.getItem());
      if (decodedToken.exp * 1000 < Date.now()) {
        history.push("/user/patient/login");
      }
    } catch (e) {
      history.push("/user/patient/login");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // setLoading(true);
    if (storage.user.getItem() === null) {
      ApiCalls.getUserProfile().then(async (res) => {
        if (res.data.status) {
          let userData = res.data.data[0];
          storage.user.setItem(userData);
          dispatch(reduxActions.authActions.setUserData(userData));
          setLoading(false);
        }
      });
    } else {
      dispatch(reduxActions.authActions.setUserData(storage.user.getItem()));
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user.userType) {
      SocketActions.connect(user.userType);
    }
  }, [user.userType]);

  // const menu = (
  //   <StyledHeaderMenu>
  //     <StyledHeaderMenu.Item>
  //       <ProfileIcon />
  //       <Link to="/user/base/home/my-account">My Account</Link>
  //     </StyledHeaderMenu.Item>
  //     <StyledHeaderMenu.Item onClick={logout}>
  //       <LogoutIcon />
  //       <a href={() => false}>Logout</a>
  //     </StyledHeaderMenu.Item>
  //   </StyledHeaderMenu>
  // );

  const pathList = [
    "/user/base/home/corporate/Subscription-plans",
    "/user/base/home/corporate/dashboard/add-doctor",
    "/user/base/home/corporate/dashboard/view-doctors",
    "/user/base/home/corporate/dashboard/edit-doctors",
    "/user/base/home/corporate/view-selected-doctors",
    "/user/base/home/corporate/dashboard/wallet-credit",
  ];

  return loading ? (
    <>
      <LoadingComponentContainer>
        <LoadingOutlined />
      </LoadingComponentContainer>
    </>
  ) : (
    <Layout>
      <StyleHeaderBottomBorder>
        <StyledHeader reverse={!pathList.includes(location.pathname)}>
          <Link
            to={
              localStorage.getItem("userType") === "company"
                ? "/user/base/home/corporate/dashboard"
                : "/user/base/home/dashboard"
            }
          >
            <LogoContainer>
              <img src={TelehealingsLogo} height={"56px"} />
            </LogoContainer>
          </Link>
          <GlobalStyle />
          {user.userType == "patient" && (
            <SearchContainer>
              <AutoComplete
                title="Search therapist by name"
                placeholder="Search therapist by name"
                options={doctorSuggestion}
                onSearch={(val) => setSearchValue(val)}
                onSelect={handleSelect}
                value={searchValue}
                dropdownRender={(menu) => <div>{menu}</div>}
                style={{ width: "100%" }}
              >
                <StyledInput
                  suffix={<SearchOutlined />}
                  style={{
                    width: "100%",
                    background: "#E3E3E34D",
                    border: "none",
                  }}
                />
              </AutoComplete>
            </SearchContainer>
          )}
          {pathList.includes(location.pathname) ? null : (
            <RightContainer>
              <Notifications />
              {/* <StyledDropdown
                    overlay={menu}
                    placement="bottomLeft"
                    trigger={["click"]}
                    overlayClassName="app-action-dropdown"
                  >
                    <AppUserInfo>
                      <ProfilePicContainer>
                        <img src={user.profilePic} alt="" />
                      </ProfilePicContainer>
                      <UserName>{`${user?.firstName} ${user?.lastName}`}</UserName>
                    </AppUserInfo>
                  </StyledDropdown> */}
            </RightContainer>
          )}
        </StyledHeader>
      </StyleHeaderBottomBorder>
      <RouterSwitch>
        <Route
          path={`/user/base/home/corporate/Subscription-plans`}
          render={() => <SubscriptionPlan />}
        />
        <Route
          exact
          path={`/user/base/home/corporate/dashboard/view-doctors`}
          render={() => <DoctorSelectionList />}
        />
        <Route
          exact
          path={`/user/base/home/corporate/dashboard/add-doctor`}
          render={() => <DoctorAllList />}
        />
        <Route
          exact
          path={`/user/base/home/corporate/dashboard/edit-doctors`}
          render={() => <EditDoctor />}
        />
        <Route
          exact
          path={`/user/base/home/corporate/view-selected-doctors`}
          render={() => <CompanyDoctorList />}
        />
        <Route
          path={`/user/base/home/corporate/dashboard/wallet-credit`}
          render={() => <WalletCard />}
        />
        <Route
          path={`/user/base/home/dashboard/survay-form/give-survey/:id`}
          render={() => <QuestionNairs />}
        />
        <Route path={`/user/base/home`} render={() => <Home />} />

        <Route
          path="*"
          exact={true}
          render={() => (
            <EmptyComponent message="404,Page not found" minHeight="100vh" />
          )}
        />
      </RouterSwitch>
    </Layout>
  );
};

export default Base;
