import HomeIcon from "../../assets/images/patientModuleIcons/dashboard.svg";
import AppointmentsIcon from "../../assets/images/patientModuleIcons/calendarMonth.svg";
import MessagesIcon from "../../assets/images/patientModuleIcons/sms.svg";
import StethoscopeIcon from "../../assets/images/patientModuleIcons/userDoctor.svg";
import UsersIcon from "../../assets/images/patientModuleIcons/escalatorWarning.svg";
import MedicationIcon from "../../assets/images/patientModuleIcons/vaccines.svg";
import FolderIcon from "../../assets/images/patientModuleIcons/description.svg";
import Checklist from "../../assets/images/patientModuleIcons/checklist_rtl.svg";

const patientLinks = [
  {
    key: "1",
    name: "Dashboard",
    path: "/user/base/home/dashboard",
    icon: HomeIcon,
  },
  {
    key: "2",
    name: "Upcoming Appointments",
    path: "/user/base/home/appointments",
    icon: AppointmentsIcon,
  },
  {
    key: "3",
    name: "Messages",
    path: "/user/base/home/messages",
    icon: MessagesIcon,
  },
  {
    key: "4",
    name: "Medication",
    path: "/user/base/home/medication",
    icon: MedicationIcon,
  },
  {
    key: "5",
    name: "Health Records",
    path: "/user/base/home/health-record",
    icon: FolderIcon,
  },
  {
    key: "6",
    name: "Family Members",
    path: "/user/base/home/family-members",
    icon: UsersIcon,
  },
  {
    key: "7",
    name: "My Therapists",
    path: "/user/base/home/my-doctors",
    icon: StethoscopeIcon,
  },
  {
    key: "8",
    name: "Article & Blogs",
    path: "/user/base/home/articles-blogs",
    icon: Checklist,
  },
];

export default patientLinks;
