import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import { withRouter, useParams } from "react-router-dom";
import { compose } from "redux";
import { formatPhoneNumber } from "../../utils/commonFunctions";
import { useLoginApi } from "../../hooks/useLoginApi";
import { useLocation } from "react-router-dom";
import LoginSignUpContent from "../../components/LoginSignUpContent";
import GoogleIconSignup from "../SignUpV2/GoogleIconSignup";
import AppleIconSignup from "../SignUpV2/AppleIconSignup";
import { IconWrapper } from "../SignUpV2/styledComponents";
import WhatsAppIcon from "../../assets/icons/WhatsAppIcon";
import { StyledLoginSignupContainer } from "./styledComponents";
import { useHistory } from "react-router-dom";
import BackButtonWithTitle from "../../components/BackButtonWithTitle";
import SignInWithGoogle from "../signup/Buttons/SignInWithGoogle";
import storage from "../../storage";
import jwt from "jwt-decode";
import patientSignupBg from "../../assets/backgrounds/SignupBg.svg";
import patientLoginIllustration from "../../assets/images/login-illustration.png";
import docLoginBg from "../../assets/backgrounds/therapist-login.svg";

const LoginV2 = (props) => {
  const history = useHistory();
  const { userType, step, channel } = useParams();
  const location = useLocation();
  const [formData, setFormData] = useState(() => {
    const savedData = sessionStorage.getItem("userLoginSignUpData");
    return savedData ? JSON.parse(savedData) : {};
  });
  const { sendLoginOtp, onPasswordLogin, loading, verifyLoginOtp } =
    useLoginApi();
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [notRegistered, setNotRegistered] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const onResendOtp = (channel, postSuccessFunction, postErrorFunction) => {
    if (!formData?.phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }
    let formattedPhoneNumber = formatPhoneNumber(
      formData?.phoneNumber,
      formData?.phoneNumberFormat
    );

    let data = {
      phoneNumber: formattedPhoneNumber,
      channel: channel,
      userType: userType,
    };

    sendLoginOtp(data, postSuccessFunction, postErrorFunction);
  };
  const onPasswordLoginClick = () => {
    if (!formData?.phoneNumber) {
      return message.error("Please enter a valid Phone Number");
    }

    if (!password) {
      return message.error("Please enter the Password");
    }

    let values = {};
    let formattedPhoneNumber = formatPhoneNumber(
      formData?.phoneNumber,
      formData?.phoneNumberFormat
    );

    values.login = formattedPhoneNumber;
    values.password = password;
    onPasswordLogin(values, userType, (res) => {
      setNotRegistered(res.email);
      setIsInvalidPassword(res.password);
    });
  };
  const handleOtpSubmitClick = () => {
    if (otp.length < 6) {
      message.error("6 digit otp required", 5);
      return;
    }
    let formattedPhoneNumber = formatPhoneNumber(
      formData?.phoneNumber,
      formData?.phoneNumberFormat
    );
    let data = {
      login: formattedPhoneNumber,
      otp: otp,
    };
    verifyLoginOtp(data, userType);
  };
  useEffect(() => {
    if (location.pathname.startsWith(`/user/${userType}/login`)) {
      sessionStorage.setItem("userLoginSignUpData", JSON.stringify(formData));
    } else {
      sessionStorage.removeItem("userLoginSignUpData");
      setFormData({});
    }
  }, [formData, location.pathname]);
  useEffect(() => {
    if (userType === "patient") {
      if (otp.length === 6) {
        let formattedPhoneNumber = formatPhoneNumber(
          formData?.phoneNumber,
          formData?.phoneNumberFormat
        );
        let data = {
          login: formattedPhoneNumber,
          otp: otp,
        };
        verifyLoginOtp(data, userType);
      }
    }
  }, [otp]);
  useEffect(() => {
    try {
      const decodedToken = jwt(storage.authToken.getItem());
      const accountStatus = storage.accountStatus.getItem();
      if (decodedToken.exp * 1000 >= Date.now()) {
        if (accountStatus === "active")
          props.history.push("/user/base/home/dashboard");
      } else {
        localStorage.clear();
        if (userType) {
          props.history.push(`/user/${userType}/login`);
        } else {
          props.history.push("/user/patient/login");
        }
      }
    } catch (e) {
      if (userType) {
        props.history.push(`/user/${userType}/login`);
      } else {
        props.history.push("/user/patient/login");
      }
    }
  }, []);
  return (
    <>
      <StyledLoginSignupContainer>
        {" "}
        <BackButtonWithTitle
          isButtonRequired
          onClick={() => {
            if (!step && !channel) {
              history.push(`/user/${userType}/signup`);
            } else if (step === "otp" && !channel) {
              history.push(`/user/${userType}/signup`);
            } else {
              window.history.back();
            }
          }}
        />
        <LoginSignUpContent
          bgImage={
            userType === "patient"
              ? step
                ? patientSignupBg
                : patientLoginIllustration
              : docLoginBg
          }
          userType={userType}
          isInvalidPassword={isInvalidPassword}
          alreadyExists={notRegistered}
          onResendOtp={onResendOtp}
          formData={formData}
          setFormData={setFormData}
          otp={otp}
          setOtp={setOtp}
          action={"login"}
          title={channel ? "Enter OTP Code 🔐" : "Welcome Back! ✌🏼"}
          subTitle={
            channel
              ? `Check your messages for a message from Telehealings. Enter the One-Time-Password (OTP) you received below.`
              : "Sign in to continue your wellness journey"
          }
          cancelButtonTitle={
            step === "password" ? (
              "Sign in using OTP"
            ) : (
              <>
                <WhatsAppIcon />
                <span>Send Codes to Whatsapp</span>
              </>
            )
          }
          saveButtonTitle={step === "password" ? "Sign in" : "Send OTP via SMS"}
          buttonFlexDirection={
            step === "password" ? "column-reverse !important" : ""
          }
          buttonOutsideCard={
            !channel && (
              <>
                <IconWrapper>
                  <SignInWithGoogle props={props} />
                  <AppleIconSignup props={props} />
                </IconWrapper>
              </>
            )
          }
          onButtonOutsideClick={() => {
            if (!channel) {
            } else {
              return;
            }
          }}
          cancelBgColor={"rgba(7, 94, 84, 1)"}
          cancelTextColor={"rgba(255, 255, 255, 1)"}
          className={step === "password" ? "Password-Style-Wrapper" : ""}
          onCancelClick={() => {
            if (step === "password") {
              history.push(`/user/${userType}/login/otp`);
            } else {
              onResendOtp(
                "whatsapp",
                () => {
                  setFormData((prev) => ({
                    ...prev,
                    channel: "whatsapp",
                  }));
                  history.push(`/user/${userType}/login/otp/whatsapp`);
                },
                (res) => {
                  setNotRegistered(res.email);
                }
              );
            }
          }}
          onSaveClick={() => {
            if (step === "password") {
              onPasswordLoginClick();
            } else {
              onResendOtp(
                "sms",
                () => {
                  setFormData((prev) => ({
                    ...prev,
                    channel: "sms",
                  }));
                  history.push(`/user/${userType}/login/otp/sms`);
                },
                (res) => {
                  setNotRegistered(res.email);
                }
              );
            }
          }}
          password={password}
          setPassword={setPassword}
          onForgotPasswordClick={() =>
            history.push(`/user/${userType}/forgot-password`)
          }
          onOtpSubmitClick={() => {
            handleOtpSubmitClick();
          }}
        />
      </StyledLoginSignupContainer>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    cities: store.lookups.cities,
    credentials: store.lookups.credentials,
  };
};

export default compose(connect(mapStateToProps), withRouter)(LoginV2);
