import HomeIcon from "../../assets/images/patientModuleIcons/dashboard.svg";
import AppointmentsIcon from "../../assets/images/patientModuleIcons/calendarMonth.svg";
import MessagesIcon from "../../assets/images/patientModuleIcons/sms.svg";
import StethoscopeIcon from "../../assets/images/patientModuleIcons/userDoctor.svg";
import UsersIcon from "../../assets/images/patientModuleIcons/escalatorWarning.svg";
import MedicationIcon from "../../assets/images/patientModuleIcons/vaccines.svg";
import FolderIcon from "../../assets/images/patientModuleIcons/description.svg";
import Checklist from "../../assets/images/patientModuleIcons/checklist_rtl.svg";
import SubscriptionIcon from "../../assets/images/patientModuleIcons/subscriptionIcon.svg";
import FindTherapistIcon from "../../assets/images/patientModuleIcons/findTherapistIcon.svg";
import ChatIcon from "../../assets/icons/ChatIcon.svg";

const patientLinks = [
  {
    key: "1",
    name: "Dashboard",
    path: "/user/base/home/dashboard",
    icon: HomeIcon,
  },
  {
    key: "2",
    name: "Therapists",
    path: "/user/base/home/therapists",
    icon: FindTherapistIcon,
  },
  {
    key: "3",
    name: "Upcoming Appointments",
    path: "/user/base/home/appointments",
    icon: AppointmentsIcon,
  },
  // {
  //   key: "4",
  //   name: "Medication",
  //   path: "/user/base/home/medication",
  //   icon: MedicationIcon,
  // },
  // {
  //   key: "4",
  //   name: "Health Records",
  //   path: "/user/base/home/health-record",
  //   icon: FolderIcon,
  // },
  {
    key: "4",
    name: "Subscriptions",
    path: "/user/base/home/subscriptions",
    icon: SubscriptionIcon,
  },
  {
    key: "5",
    name: "Chats",
    path: "/user/base/home/messages",
    icon: ChatIcon,
  },
  {
    key: "6",
    name: "Family Members",
    path: "/user/base/home/family-members",
    icon: UsersIcon,
  },
  {
    key: "7",
    name: "My Therapists",
    path: "/user/base/home/my-doctors",
    icon: StethoscopeIcon,
  },
  {
    key: "8",
    name: "Article & Blogs",
    path: "/user/base/home/articles-blogs",
    icon: Checklist,
  },
];

export default patientLinks;
