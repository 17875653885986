import React from "react";
import {
  IllustrationContainer,
  IllustrationImage,
  LoginContentContainer,
  StyledButton,
} from "./styledComponents";
import LoginSignupCard from "../../components/LoginSignUpContent/loginSignupCard";
import { useParams } from "react-router-dom";

const LoginSignUpContent = ({
  userType,
  formData,
  setFormData,
  action,
  setOtp,
  otp,
  onResendOtp,
  title,
  subTitle,
  cancelButtonTitle,
  saveButtonTitle,
  onCancelClick,
  onSaveClick,
  cancelBgColor,
  buttonFlexDirection,
  cancelTextColor,
  buttonOutsideCard,
  onButtonOutsideClick,
  isOtpInvalid,
  alreadyExists,
  className,
  password,
  setPassword,
  onForgotPasswordClick,
  confirmPassword,
  setConfirmPassword,
  isInvalidPassword,
  onOtpSubmitClick,
  bgImage,
}) => {
  const { step } = useParams();
  return (
    <>
      {(action === "signup" ||
        action === "login" ||
        action === "forgot-password") && (
        <LoginContentContainer>
          <IllustrationContainer
            justifyContent={"flex-end"}
            className="IllustrationContainer"
          >
            <IllustrationImage
              className={`${userType === "doctor" ? "Doc-Bg-Img-Style" : ""} ${
                action === "signup" && step ? "Signup-Bg-Style" : ""
              } IllustrationImage`}
              src={bgImage}
            />
          </IllustrationContainer>
          <IllustrationContainer
            flexDirection={"column"}
            className="IllustrationContainer"
          >
            <LoginSignupCard
              userType={userType}
              isOtpInvalid={isOtpInvalid}
              action={action}
              formData={formData}
              setFormData={setFormData}
              onResendOtp={onResendOtp}
              otp={otp}
              setOtp={setOtp}
              title={title}
              subTitle={subTitle}
              cancelButtonTitle={cancelButtonTitle}
              saveButtonTitle={saveButtonTitle}
              onCancelClick={onCancelClick}
              onSaveClick={onSaveClick}
              cancelBgColor={cancelBgColor}
              buttonFlexDirection={buttonFlexDirection}
              cancelTextColor={cancelTextColor}
              alreadyExists={alreadyExists}
              className={className}
              password={password}
              setPassword={setPassword}
              onForgotPasswordClick={onForgotPasswordClick}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              isInvalidPassword={isInvalidPassword}
              onOtpSubmitClick={onOtpSubmitClick}
            />
            {buttonOutsideCard && (
              <StyledButton
                type="link"
                onClick={onButtonOutsideClick}
                className={"Outside-Button-Style"}
              >
                {buttonOutsideCard}
              </StyledButton>
            )}
          </IllustrationContainer>
        </LoginContentContainer>
      )}
    </>
  );
};

export default LoginSignUpContent;
