import { useState } from "react";
import ApiCalls from "../apis/ApiCalls";
import { message } from "antd";

export const useDocOnboardingApi = () => {
  const [docProfileDetails, setDocProfileDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [treatmentList, setTreatmentList] = useState([]);

  const fetchWorkProfile = (postSuccessFunction = () => {}) => {
    setLoading(true);
    ApiCalls.getDoctorWorkProfile()
      .then((response) => {
        if (response.data.status) {
          setLoading(false);
          setDocProfileDetails(response.data.data);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
        } else {
          message.error(response.data.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const imageUpload = (formData, postSuccessFunction = () => {}) => {
    setLoading(true);
    ApiCalls.uploadDoctorFile(formData)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
        } else {
          message.error(response.data.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const updateDocInfo = (data, postSuccessFunction = () => {}) => {
    setLoading(true);
    ApiCalls.updateDoctorBasicInfo(data)
      .then((response) => {
        if (response.data.status) {
          setLoading(false);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
          message.success("Updated basic info successfully");
        } else {
          message.error(response.data.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const updateDocExtraInfo = (
    data,
    selectedItem,
    postSuccessFunction = () => {}
  ) => {
    setLoading(true);
    ApiCalls.updateDoctorExtraInfo(data)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          message.success(
            `${selectedItem ? "Edited succesfully" : "Added successfully"}`
          );
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
        } else message.error(response.data.data.message);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const deleteTherapistInfo = (data, postSuccessFunction = () => {}) => {
    ApiCalls.updateDoctorExtraInfo(data)
      .then((response) => {
        if (response.data.status) {
          message.success("Succesfully deleted data ");
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
        } else message.error("Failed to delete");
      })
      .catch((error) =>
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        )
      );
  };
  const updateDoctorWorkProfileInfo = (
    data,
    postSuccessFunction = () => {}
  ) => {
    ApiCalls.updateDoctorWorkProfileInfo(data)
      .then((response) => {
        if (response.data.status) {
          message.success(response.data.data.message, 5);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
        } else {
          message.error(response.data.data.message);
        }
      })
      .catch((error) =>
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        )
      );
  };
  const addDoctorSpecialization = (data, postSuccessFunction = () => {}) => {
    setLoading(true);
    ApiCalls.addTreatments(data)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          message.success("Added treatment successfully", 5);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
        } else {
          message.error(response.data.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const getDoctorSpecializations = () => {
    setLoading(true);
    ApiCalls.getTreatments()
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setTreatmentList(response?.data?.data);
        } else {
          message.error(response.data.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const updateDoctorSpecialization = (data, postSuccessFunction = () => {}) => {
    setLoading(true);
    ApiCalls.updateTreatment(data)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          message.success(response.data.data.message, 5);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
        } else {
          message.error(response.data.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  const deleteDoctorSpecialization = (data, postSuccessFunction = () => {}) => {
    setLoading(true);
    ApiCalls.deleteTreatment(data)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          message.success("Deleted treatment successfully", 5);
          if (typeof postSuccessFunction === "function") {
            postSuccessFunction();
          }
        } else {
          message.error(response.data.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.response
            ? error?.response?.data?.data?.message
            : "No Response From Server",
          5
        );
      });
  };
  return {
    loading,
    fetchWorkProfile,
    docProfileDetails,
    imageUpload,
    updateDocInfo,
    updateDocExtraInfo,
    deleteTherapistInfo,
    updateDoctorWorkProfileInfo,
    addDoctorSpecialization,
    getDoctorSpecializations,
    treatmentList,
    updateDoctorSpecialization,
    deleteDoctorSpecialization,
  };
};
