import * as React from "react";

const MaleIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width ? width : "21"}
      height={height ? height : "20"}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.2476 0.249421C20.4216 0.422514 20.5 0.669845 20.4573 0.9116L19.2855 7.55222C19.2135 7.96013 18.8244 8.23246 18.4165 8.16047C18.0086 8.08849 17.7363 7.69946 17.8083 7.29155L18.5696 2.97767L13.2999 8.22227C14.3137 9.46227 14.9219 11.0468 14.9219 12.7734C14.9219 16.7473 11.7004 19.9688 7.72656 19.9688C3.7527 19.9688 0.53125 16.7473 0.53125 12.7734C0.53125 8.79958 3.7527 5.57813 7.72656 5.57813C9.43351 5.57813 11.0016 6.17252 12.2353 7.16561L17.4725 1.95324L13.2127 2.73003C12.8052 2.80434 12.4146 2.53424 12.3403 2.12674C12.266 1.71925 12.5361 1.32867 12.9436 1.25437L19.5842 0.0434277C19.8257 -0.000612017 20.0735 0.0763288 20.2476 0.249421ZM7.72656 7.07813C4.58113 7.07813 2.03125 9.62801 2.03125 12.7734C2.03125 15.9189 4.58113 18.4688 7.72656 18.4688C10.872 18.4688 13.4219 15.9189 13.4219 12.7734C13.4219 9.62801 10.872 7.07813 7.72656 7.07813Z"
        fill={fill ? fill : "#425889"}
      />
    </svg>
  );
};

export default MaleIcon;
