import React, { useState } from "react";
import CustomArticleButton from "../../../components/CustomArticleButton";
import {
  EducationExperienceWrapper,
  FlexContent,
  InputWrapper,
} from "../styledComponents";
import TherapistStatCard from "../../../components/TherapistStatCard";
import { CommonSchibstedFontTextBold } from "../../../utils/styledUtils";
import AddEditEducationExperiencePopup from "./AddEditEducationExperiencepopup";
import { capitalizeTitle } from "../../../utils/commonFunctions";
import CustomLoader from "../../../components/CustomLoader";
import { useDocOnboardingApi } from "../../../hooks/useDocOnboardingApi";
import { Input } from "antd";

const eduData = [
  {
    id: 1,
    name: "instituteName",
    placeholder: "Institution Name*",
  },
  {
    id: 2,
    name: "instituteAddress",
    placeholder: "Institute Address*",
  },
  {
    id: 3,
    name: "qualifiction",
    placeholder: "Qualification*",
  },
];
const expData = [
  {
    id: 1,
    name: "hospitalName",
    placeholder: "Hospital/Organization Name*",
  },
  {
    id: 2,
    name: "hospitalAddress",
    placeholder: "Address*",
  },
  {
    id: 3,
    name: "referencePerson",
    placeholder: "Reference Person*",
  },
  {
    id: 3,
    name: "contactNumber",
    placeholder: "Phone Number*",
  },
];
const EducationExperience = ({
  docProfileDetails,
  setIsDetailUpdated,
  loading,
  setServicePayload,
  servicePayload,
}) => {
  const [isPopup, setIsPopup] = useState(false);
  const [addNewExp, setAddNewExp] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectItem] = useState("");
  const [docFile, setDocFile] = useState({});
  const [selectedFile, setSelectedFile] = useState("");
  const { deleteTherapistInfo } = useDocOnboardingApi();
  const sections = [
    {
      label: "Education*",
      data: docProfileDetails.educationalDetails,
      type: "educationalDetails",
      isExperience: false,
    },
    {
      label: "Experience*",
      data: docProfileDetails.experienceDetails,
      type: "experienceDetails",
      isExperience: true,
    },
  ];
  const handlePopupOpenClick = (item, isExperience, editMode = false) => {
    setIsPopup(true);
    setIsEdit(editMode);
    setAddNewExp(isExperience);
    setSelectItem(item || null);
  };
  const handlePopupCloseClick = () => {
    setIsPopup(false);
    setIsEdit(false);
    setAddNewExp(false);
    setSelectedFile("");
    setDocFile({});
    setSelectItem("");
  };
  const onDeleteClick = (item, type) => {
    let data = {};
    const { lastModified, fileName, url, ...rest } = item;
    if (type === "educationalDetails") {
      data.educationalDetails = { ...rest, status: "delete" };
    } else {
      data.experienceDetails = { ...rest, status: "delete" };
    }
    deleteTherapistInfo(data, () => {
      setIsDetailUpdated((prevData) => !prevData);
    });
  };
  return (
    <EducationExperienceWrapper className="EducationExperienceWrapper">
      {loading ? (
        <CustomLoader className={"CustomLoader"} />
      ) : (
        <>
          {sections.map((section, idx) => (
            <FlexContent key={idx}>
              <CommonSchibstedFontTextBold
                fontSize="16px"
                textColor="#848484"
                className="ContentTitleStyle"
              >
                {section.label}
              </CommonSchibstedFontTextBold>
              {section.data &&
                section.data.map((item, index) => (
                  <TherapistStatCard
                    className={"TherapistStatCard"}
                    key={item.id || index}
                    item={item}
                    title={capitalizeTitle(
                      section.isExperience
                        ? item.referencePerson
                        : item.qualifiction
                    )}
                    subTitle={capitalizeTitle(
                      section.isExperience
                        ? item.hospitalName
                        : item.instituteName
                    )}
                    address={capitalizeTitle(
                      section.isExperience
                        ? item.hospitalAddress
                        : item.instituteAddress
                    )}
                    onEditClick={() =>
                      handlePopupOpenClick(item, section.isExperience, true)
                    }
                    onCloseClick={(item) => onDeleteClick(item, section.type)}
                  />
                ))}
              <CustomArticleButton
                buttonText="add +"
                closeBtn
                borderColor="#425889"
                className="Add-Btn-Style"
                onClick={() =>
                  handlePopupOpenClick(null, section.isExperience, false)
                }
              />
            </FlexContent>
          ))}
          <InputWrapper className="ProfessionalInputWrapper">
            <CommonSchibstedFontTextBold
              fontSize="16px"
              textColor="#848484"
              className="ContentTitleStyle"
            >
              Total Work Experience
            </CommonSchibstedFontTextBold>
            <Input
              placeholder={"Total work experience in years*"}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "");
                setServicePayload((prevData) => ({
                  ...prevData,
                  totalWorkExperience: value,
                }));
              }}
              value={servicePayload?.totalWorkExperience?.toString()}
            />
          </InputWrapper>
        </>
      )}
      <AddEditEducationExperiencePopup
        setAddNewExp={setAddNewExp}
        setIsVisible={setIsPopup}
        visible={isPopup}
        onCloseClick={handlePopupCloseClick}
        okLabel={isEdit ? "Update" : "Add"}
        title={`${isEdit ? "Edit" : "Add"} ${
          addNewExp ? "Hospital/Organization" : "Education"
        }`}
        cancelLabel={"Cancel"}
        fieldData={addNewExp ? expData : eduData}
        setIsDetailUpdated={setIsDetailUpdated}
        data={
          addNewExp
            ? docProfileDetails.experienceDetails
            : docProfileDetails.educationalDetails
        }
        selectedItem={selectedItem}
        setSelectItem={setSelectItem}
        addNewExp={addNewExp}
        docProfileDetails={docProfileDetails}
        setDocFile={setDocFile}
        docFile={docFile}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
      />
    </EducationExperienceWrapper>
  );
};

export default EducationExperience;
