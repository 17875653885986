import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Form } from "antd";
import CustomUserInputField from "../../../components/CustomUserInputField";
import { CommonDMFontTextRegular } from "../../../utils/styledUtils";
import { BankContentWrapper, BankDetailsWrapper } from "../styledComponents";
import { useDocOnboardingApi } from "../../../hooks/useDocOnboardingApi";
import { useCommonLookupsApi } from "../../../hooks/useCommonLookupsApi";
import CustomUserDropdownField from "../../../components/CustomUserDropdownField";

const BankDetails = forwardRef(
  (
    { setIsDetailUpdated, docProfileDetails, setCurrentPage, isLoggedin },
    ref
  ) => {
    const { updateDoctorWorkProfileInfo } = useDocOnboardingApi();
    const {
      getCountriesLookup,
      getStatesLookup,
      countries,
      states,
      getCitiesLookup,
      cities,
    } = useCommonLookupsApi();
    const [form] = Form.useForm();
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const handleValuesChange = (changedValues) => {
      if (changedValues.country) {
        const countryObj = countries.find(
          (c) => c.name === changedValues.country
        );
        setSelectedCountry(countryObj || null);
        setSelectedState(null);
        setSelectedCity(null);

        form.setFieldsValue({ state: undefined, city: undefined });
      }

      if (changedValues.state) {
        const stateObj = states?.state?.find(
          (s) => s.name === changedValues.state
        );
        setSelectedState(stateObj || null);
        setSelectedCity(null);

        form.setFieldsValue({ city: undefined });
      }

      if (changedValues.city) {
        const cityObj = cities?.city?.find(
          (c) => c.name === changedValues.city
        );
        setSelectedCity(cityObj || null);
      }
    };
    useImperativeHandle(ref, () => ({
      submitForm: () => {
        form.submit();
      },
    }));
    const onFinish = (values) => {
      let payload = {
        accountDetails: {
          bankName: values?.bankName,
          accountHolderName: values?.accountHolderName,
          accountNumber: values?.accountNumber,
          ifsc: values?.ifsc,
          branchName: values?.branchName,
          city: values?.city,
          countryCode: selectedCountry?.countryCode,
          stateCode: selectedState?.stateCode,
          state: selectedState?.name,
          country: selectedCountry?.name,
          timeZone: selectedCountry?.timezones[0].zoneName,
        },
      };
      if (isLoggedin) {
        updateDoctorWorkProfileInfo(payload, () => {
          setIsDetailUpdated((prevData) => !prevData);
        });
      } else {
        updateDoctorWorkProfileInfo(payload, () => {
          setCurrentPage((prev) => prev + 1);
          setIsDetailUpdated((prevData) => !prevData);
        });
      }
    };
    useEffect(() => {
      getCountriesLookup();
    }, []);
    useEffect(() => {
      if (selectedCountry?.countryCode)
        getStatesLookup(selectedCountry.countryCode);
    }, [selectedCountry]);
    useEffect(() => {
      if (selectedCountry?.countryCode && selectedState?.stateCode)
        getCitiesLookup(selectedCountry.countryCode, selectedState.stateCode);
    }, [selectedCountry, selectedState]);
    useEffect(() => {
      if (docProfileDetails?.accountDetails) {
        form.setFieldsValue({
          accountHolderName: docProfileDetails.accountDetails.accountHolderName,
          accountNumber: docProfileDetails.accountDetails.accountNumber,
          reAccountNumber: docProfileDetails.accountDetails.accountNumber, // Ensure reAccountNumber is set correctly
          ifsc: docProfileDetails.accountDetails.ifsc,
          bankName: docProfileDetails.accountDetails.bankName,
          branchName: docProfileDetails.accountDetails.branchName,
          country: docProfileDetails.accountDetails.country,
          state: docProfileDetails.accountDetails.state,
          city: docProfileDetails.accountDetails.city,
        });
        const countryObj = countries.find(
          (c) => c.name === docProfileDetails.accountDetails.country
        );
        setSelectedCountry(countryObj || null);
      }
    }, [docProfileDetails, form, countries]);

    useEffect(() => {
      if (docProfileDetails.accountDetails) {
        const stateObj = states?.state?.find(
          (c) => c.name === docProfileDetails.accountDetails.state
        );
        setSelectedState(stateObj || null);
      }
    }, [states]);
    return (
      <BankDetailsWrapper>
        <Form
          form={form}
          onFinish={onFinish}
          onValuesChange={handleValuesChange}
        >
          {[
            {
              id: 1,
              name: "accountHolderName",
              placeholder: "Account holder name*",
              rules: [
                {
                  required: false,
                  message: "Account holder name is required",
                },
              ],
            },
            {
              id: 2,
              name: "accountNumber",
              placeholder: "Account number*",
              rules: [
                {
                  required: false,
                  message: "Account number is required",
                },
                {
                  message: "Only number is allowed",
                  pattern: new RegExp("^[0-9]+$"),
                },
              ],
            },
            {
              id: 3,
              name: "reAccountNumber",
              placeholder: "Re-enter account number*",
              rules: [
                {
                  required: false,
                  message: "Re-enter account number",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("accountNumber") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two account numbers that you entered do not match"
                    );
                  },
                }),
              ],
            },
            {
              id: 4,
              name: "ifsc",
              placeholder: "SWIFT/IFSC code*",
              rules: [
                {
                  required: false,
                  message: "SWIFT/IFSC code is required",
                },
              ],
            },
            {
              id: 5,
              name: "bankName",
              placeholder: "Bank name*",
              rules: [
                {
                  required: false,
                  message: "Bank name is required",
                },
              ],
            },
            {
              id: 6,
              name: "branchName",
              placeholder: "Branch name*",
              rules: [
                {
                  required: false,
                  message: "Branch name is required",
                },
              ],
            },
            {
              id: 7,
              name: "country",
              placeholder: "Branch Country*",
              rules: [
                {
                  required: false,
                  message: "Branch country is required",
                },
              ],
              data: countries.map((c) => c.name),
            },
            {
              id: 8,
              name: "state",
              placeholder: "State*",
              rules: [
                {
                  required: false,
                  message: "State is required",
                },
              ],
              data: states?.state?.map((s) => s.name),
            },
            {
              id: 9,
              name: "city",
              placeholder: "City*",
              rules: [
                {
                  required: false,
                  message: "City is required",
                },
              ],
              data: cities?.city?.map((c) => c.name),
            },
          ].map((item, index) => {
            return (
              <BankContentWrapper key={index} className="BankContentWrapper">
                <CommonDMFontTextRegular fontWeight={700} textColor="#999999">
                  {item.placeholder}
                </CommonDMFontTextRegular>

                {["country", "state", "city"].includes(item.name) ? (
                  <CustomUserDropdownField
                    showSearch
                    name={item.name}
                    rules={item.rules}
                    type2={true}
                    placeholder={item.placeholder}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={
                      item?.data?.map((option) => ({
                        label: option,
                        value: option,
                      })) || []
                    }
                    disabled={!item.data || item.data.length === 0}
                  />
                ) : (
                  <CustomUserInputField
                    type2={true}
                    name={item.name}
                    key={item.id}
                    placeholder={item.placeholder}
                    rules={item.rules}
                  />
                )}
              </BankContentWrapper>
            );
          })}
        </Form>
      </BankDetailsWrapper>
    );
  }
);

export default BankDetails;
