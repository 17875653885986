import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import React from "react";
import ApiCalls from "../../../apis/ApiCalls";
import { message } from "antd";
import storage from "../../../storage";
import reduxActions from "../../../redux-store/actions";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const SignInWithGoogle = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userType } = useParams();

  const onSuccess = ({ credentialResponse }) => {
    if (userType === "patient") {
      ApiCalls.googleAuth({ token: credentialResponse.credential })
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem("userType", response.data?.data?.userType);

            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);

            response.data.data.oauthData &&
              storage.oauthData.setItem(response.data.data.oauthData);
            response.data.data.page &&
              storage.pageInfo.setItem(response.data.data.page);

            dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );

            message.success("Google OAuth Successful");

            if (response.data.data.oauthData && response.data.data.page === 3) {
              history.push("/user/base/home/dashboard");
            } else if (response.data.data.page === 4) {
              history.push("/user/base/home/dashboard");
            } else {
              history.push("/user/patient/update-profile");
            }
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    } else if (userType === "doctor") {
      ApiCalls.googleProviderAuth({ token: credentialResponse.credential })
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem("userType", response.data?.data?.userType);

            storage.authToken.setItem(response.data.data.authToken);
            storage.accountStatus.setItem(response.data.data.status);

            response.data.data.oauthData &&
              storage.oauthData.setItem(response.data.data.oauthData);
            response.data.data.page &&
              storage.pageInfo.setItem(response.data.data.page);

            dispatch(
              reduxActions.authActions.setAuthToken(
                response.data.data.authToken
              )
            );

            message.success("Google OAuth Successful");

            if (response.data.data.page === 5) {
              history.push("/user/base/home/dashboard");
            } else {
              history.push("/user/doctor/update-profile");
            }
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          message.error(
            error.response
              ? error?.response?.data?.data?.message
              : "No Response From Server",
            5
          );
        });
    }
  };

  return (
    <>
      <button
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          borderRadius: "8px",
          gap: "16px",
          backgroundColor: "#FFFFFF",
        }}
        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
      >
        <GoogleLogin
          style={{ border: "none" }}
          onSuccess={(credentialResponse) => {
            console.log(credentialResponse, "credentialResponse");
            onSuccess({ credentialResponse: credentialResponse });
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </button>
    </>
  );
};

export default SignInWithGoogle;
