import React, { useRef } from "react";
import { message } from "antd";
import CustomArticleButton from "../CustomArticleButton";
import {
  CommonDMFontTextRegular,
  CommonDMFontTextSmall,
  CommonIcon,
  CommonSchibstedFontTextBold,
} from "../../utils/styledUtils";
import fileIcon from "../../assets/icons/fileIcon.svg";
import {
  capitalizeTitle,
  formatDateType,
  formatShortDateMonthYear,
} from "../../utils/commonFunctions";
import { DetailWrapper, Wrapper } from "./styledComponents";

const UploadFile = ({
  setSelectedFile,
  selectedFile,
  onUpload,
  defaultData,
}) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size / 1024 / 1024 >= 2) {
      message.error("File must be smaller than 2MB!");
      return;
    }
    const uploadDate = formatShortDateMonthYear(new Date());
    setSelectedFile({ file, uploadDate });
    if (typeof onUpload === "function") {
      onUpload(file);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Wrapper>
      <CommonSchibstedFontTextBold fontSize="16px" textColor="#333333">
        Upload Certification Image*
      </CommonSchibstedFontTextBold>
      {selectedFile ? (
        <DetailWrapper style={{ padding: "7px 0 13px" }}>
          <CommonIcon src={fileIcon} width={"32px"} height={"40px"} />
          <div>
            <CommonDMFontTextRegular fontWeight="600">
              {capitalizeTitle(selectedFile?.file?.name)}
            </CommonDMFontTextRegular>
            <CommonDMFontTextSmall fontWeight="500">
              You: {selectedFile?.uploadDate}
            </CommonDMFontTextSmall>
          </div>
        </DetailWrapper>
      ) : defaultData && defaultData.fileName ? (
        <DetailWrapper style={{ padding: "7px 0 13px" }}>
          <CommonIcon src={fileIcon} width={"32px"} height={"40px"} />
          <div>
            <CommonDMFontTextRegular fontWeight="600">
              {capitalizeTitle(defaultData?.fileName?.name) +
                (defaultData?.fileName?.type?.toLowerCase() !== "unknown"
                  ? `.${defaultData?.fileName?.type}`
                  : "")}
            </CommonDMFontTextRegular>
            <CommonDMFontTextSmall fontWeight="500">
              You: {formatDateType(defaultData?.uploadDate)}
            </CommonDMFontTextSmall>
          </div>
        </DetailWrapper>
      ) : (
        <CommonDMFontTextSmall
          textColor="#333333"
          style={{ paddingBottom: "6px" }}
        >
          Please upload a clear certification image.
        </CommonDMFontTextSmall>
      )}
      <div sty>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <CustomArticleButton
          buttonText={
            (defaultData && defaultData.name) || selectedFile
              ? "Replace File"
              : "Upload File"
          }
          onClick={handleButtonClick}
          bgColor={"#F4F7FC"}
          borderColor={"#F4F7FC"}
          textColor={"#425889"}
          className={"Upload-Button"}
        />
      </div>
    </Wrapper>
  );
};

export default UploadFile;
